import { AbstractControl } from '@angular/forms';

export function disableFormControl(
  control: AbstractControl,
  opts?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }
) {
  if (control.enabled) {
    control.disable(opts);
  }
}

export function enableFormControl(
  control: AbstractControl,
  opts?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }
) {
  if (control.disabled) {
    control.enable(opts);
  }
}
