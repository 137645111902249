import { Injectable } from '@angular/core';
import { StateService } from '../../services/state';
import { Block, Column } from '../../block/services/block';
import { FormGroup } from '@angular/forms';
import { Style } from '../../services/style';

export interface ColumnWidth {
  percent: number;
  px: number;
  type: 'percent' | 'px';
}

export interface ColumnWidthOptions {
  label: {
    plural: string;
    singular: string;
    title: string;
  };
}

// Deprecated
export interface ColumnWidths {
  column1Width: number;
  column2Width: number;
  contentWidth: number;
}

export interface ColumnWidthRange {
  maxPercent: number;
  maxPx: number;
  minPercent: number;
  minPx: number;
}

@Injectable({
  providedIn: 'root',
})
export class ColumnWidthService {
  widthRange: ColumnWidthRange = {
    maxPercent: 100,
    maxPx: 400,
    minPercent: 5,
    minPx: 50,
  };

  constructor(
    private styleService: Style,
    private stateService: StateService
  ) {}

  get newItem(): ColumnWidth {
    return {
      percent: this.widthRange.maxPercent,
      px: this.widthRange.minPx,
      type: 'percent',
    };
  }

  get options(): ColumnWidthOptions {
    return {
      label: {
        plural: 'Columns',
        singular: 'Column',
        title: 'Column Widths',
      },
    };
  }

  /**
   * helper methods
   */

  setWidthRange(form: FormGroup): void {
    const columns = form.get(['columns'])?.value || [];
    const percentColumns = [];
    for (const column of columns) {
      if (column.width.type === 'percent') {
        percentColumns.push(column.width.percent);
      }
    }
    if (percentColumns.length) {
      this.widthRange.maxPercent = 100 - this.widthRange.minPercent * (percentColumns.length - 1);
    }
  }

  resetColumnWidths(form: FormGroup): void {
    const columns: Column[] = form.get(['columns'])?.value;
    /**
     * gather all "percent" column values into the percents array, excluding "px" columns
     */
    const percents = [];
    for (const column of columns) {
      if (column.width.type === 'percent') {
        percents.push(column.width.percent);
      }
    }
    /**
     * reset each percent column to equal widths
     * set form values
     */
    if (percents.length) {
      for (const column of columns) {
        column.width.percent = Math.floor(100 / percents.length);
      }
      for (const [index, column] of columns.entries()) {
        form.get(['columns', index, 'width', 'percent'])?.setValue(column.width.percent, { emitEvent: false });
      }
    }
  }

  /**
   * Triggered whenever any of the inputs emit a change
   * handles several conditions:
   *   1. the column is a px type width, return the px value
   *   2. mobile and breakpoints enabled, return 100% of block width
   *   3. the columns are equal, return available width divided by number of percent columns
   *   4. return each column width, available width multiplied by column percent value
   *
   * @param blockWidth
   * @param columnIndex
   * @param columns
   * @param breakpointsEnabled
   * @param columnGap
   */
  columnWidth(
    blockWidth: number,
    columnIndex: number,
    columns: Block['columns'],
    breakpointsEnabled: boolean,
    columnGap: number
  ): number {
    /**
     *  1. the column is a px type width, return the px value
     */
    if (columns[columnIndex].width.type === 'px') {
      return columns[columnIndex].width.px;
    }

    /**
     *  2. mobile and breakpoints enabled, return 100% of block width
     */
    if (blockWidth < 800 && breakpointsEnabled) {
      return blockWidth;
    }

    const numberOfGaps = columns.length - 1;
    const gapsWidth = numberOfGaps * columnGap;

    /**
     * gather all "percent" column values into the percents array, excluding "px" columns
     */
    const percents = [];
    if (percents.length === 0) {
      for (const [columnIndex, column] of columns.entries()) {
        if (column.width.type === 'percent') {
          percents.push({
            columnIndex: columnIndex,
            value: column.width.percent,
          });
        }
      }
    }

    /**
     * gather all "px" column values into the px array, excluding "percent" column values
     * also sum the "px" widths into "pxsWidth"
     */
    const pxs = [];
    let pxsWidth = 0;
    for (const [index, column] of columns.entries()) {
      if (column.width.type === 'px') {
        pxsWidth = pxsWidth + column.width.px;
        pxs.push({
          index: index,
          px: column.width.px,
        });
      }
    }

    /**
     * remove gaps and px widths to calculate available width for percent columns, then either:
     *   - multiple by each column's percent width
     *   - divide by number of equal columns
     */
    const percentsWidth = this.styleService.contentStyles(blockWidth).width.px - pxsWidth - gapsWidth;

    /**
     * Identify any scenario where all percent columns have equal widths.
     * This solves for percents that should be represented with more than 2 digits, such as 33.333 and 16.666.
     * useEqualWidths is not needed for 50, 25, and 20. These are included here for clarity.
     */
    let equalWidths = false;
    equalWidths = percents.find(percent => !percent.value) ? true : equalWidths;
    equalWidths = percents.every(percent => percent.value === 50) ? true : equalWidths;
    equalWidths = percents.every(percent => percent.value === 33) ? true : equalWidths;
    equalWidths = percents.every(percent => percent.value === 25) ? true : equalWidths;
    equalWidths = percents.every(percent => percent.value === 20) ? true : equalWidths;
    equalWidths = percents.every(percent => percent.value === 16) ? true : equalWidths;
    equalWidths = percents.every(percent => percent.value === 14) ? true : equalWidths;
    equalWidths = percents.every(percent => percent.value === 12) ? true : equalWidths;

    /**
     *  3. the columns are equal, return available width divided by number of percent columns
     */
    if (equalWidths) {
      return percentsWidth / percents.length;
    }

    /**
     *  4. return each column width, available width multiplied by column percent value
     */
    return (percentsWidth * columns[columnIndex].width.percent) / 100;
  }
}
