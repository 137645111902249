import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { combineLatest, combineLatestWith, filter, Observable, of, tap } from 'rxjs';
import { MultiblockStyle as ThisStyle, Styles } from './services/style';
import { BlockService, Options, Visibility } from './services/block';
import { FormService } from '../services/form';
import { LinkService } from '../services/link';
import { ThemeService } from '../options/theme/service';
import { Block } from './services/block';
import { ColorService } from '../services/color';
import { QuillEditorOptions } from '../fields/quill-editor/service';
import { TemplateType } from '../options/template/service';
import { DataReadService } from '../services/data-read';
import { RevisionReadService } from '../services/revision-read';
import { Border, ScreenSize, Style } from '../services/style';
import { GLOBAL_RX_STATE, GlobalState, StateService } from '../services/state';
import { FieldType } from '../services/field';
import { BlockState } from './services/state';
import { AgentSchedulerService } from '../modules/agent-scheduler/agent-scheduler.service';

@Component({
  selector: 'ava-multiblock-view-row',
  templateUrl: './view-row.html',
  styles: [':host { width: 100% }'],
  providers: [RxState, RxEffects],
})
export class MultiblockViewRowComponent implements OnInit, AfterViewInit {
  @Input() columnIndex: number | undefined;
  @Input() rowIndex: number | undefined;
  @Input() stateId: string | undefined;
  @Input() rowId: string | undefined;
  @ViewChild('QUILL_EDITOR_ACCORDION') QUILL_EDITOR_ACCORDION: TemplateRef<any> | undefined;
  @ViewChild('QUILL_EDITOR') QUILL_EDITOR: TemplateRef<any> | undefined;
  @ViewChild('VIDEO') VIDEO: TemplateRef<any> | undefined;
  @ViewChild('JAVASCRIPT') JAVASCRIPT: TemplateRef<any> | undefined;
  @ViewChild('IMAGE') IMAGE: TemplateRef<any> | undefined;
  @ViewChild('AGENT_SCHEDULER') AGENT_SCHEDULER: TemplateRef<any> | undefined;
  @ViewChild('BATTERY_QUOTER') BATTERY_QUOTER: TemplateRef<any> | undefined;
  @ViewChild('SMART_WEATHER') SMART_WEATHER: TemplateRef<any> | undefined;
  @ViewChild('BUTTON') BUTTON: TemplateRef<any> | undefined;
  @ViewChild('MULTI_BLOCK') MULTI_BLOCK: TemplateRef<any> | undefined;
  @ViewChild('PRICE_CHART_MEMBER_QUANTITY') PRICE_CHART_MEMBER_QUANTITY: TemplateRef<any> | undefined;
  @ViewChild('PRICE_CHART_CARD') PRICE_CHART_CARD: TemplateRef<any> | undefined;
  @ViewChild('JOIN_RENEW_CONFIRMATION_SUCCESS') JOIN_RENEW_CONFIRMATION_SUCCESS: TemplateRef<any> | undefined;
  @ViewChild('COMPARISON_CHART') COMPARISON_CHART: TemplateRef<any> | undefined;
  templateRef: TemplateRef<any> | null = null;

  globalState$ = this.globalState.select();
  blockState: RxState<BlockState> | undefined;
  blockState$: Observable<BlockState> | undefined;
  formGroup: FormGroup | undefined;
  FieldType = FieldType;
  ScreenSize = ScreenSize;
  rowVisible: boolean | undefined;
  highlight: { [key: string]: boolean };
  styles: Styles;
  // contentStyles: ContentStyles
  border: Border;
  options: Options;
  quillEditorOptions: QuillEditorOptions | undefined;
  agentSchedulerParams = this.agentSchedulerService.testPayload; // at this time for testing purposes only

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private thisService: BlockService,
    private thisStyle: ThisStyle,
    private colorService: ColorService,
    private dataReadService: DataReadService,
    private formService: FormService,
    private linkService: LinkService,
    private revisionReadService: RevisionReadService,
    private stateService: StateService,
    private styleService: Style,
    private themeService: ThemeService,
    private changeDetectorRef: ChangeDetectorRef,
    private rxEffects: RxEffects,
    private agentSchedulerService: AgentSchedulerService
  ) {
    this.highlight = thisService.highlight;
    this.styles = thisStyle.styles;
    // this.contentStyles = styles.content(this.blockState.get("blockWidth"))
    this.border = styleService.border;
    this.options = thisService.options;
    this.setQuillEditorOptions();
  }

  get templateRef$(): Observable<any> {
    if (this.blockState && this.rowId && this.columnIndex !== undefined) {
      return combineLatest([
        this.blockState.select('blockValue', 'rows'),
        this.blockState.select('blockValue', 'columns'),
      ]).pipe(
        filter(([rows]) => !!rows[this.rowId as string]?.fieldType),
        tap(([rows, columns]) => {
          this.templateRef = this.getTemplateRef(
            rows[this.rowId as string].fieldType as FieldType,
            columns[this.columnIndex as number].accordion
          );
        })
      );
    }
    return of(null);
  }

  get rowVisible$(): Observable<any> {
    if (this.blockState && this.rowId) {
      return this.blockState.select('blockValue', 'rows', this.rowId, 'visibility').pipe(
        combineLatestWith(this.globalState.select('screenSize')),
        tap(([rowVisibility, screenSize]) => {
          this.rowVisible = rowVisibility?.[screenSize];
        })
      );
    }
    return of(null);
  }

  get backgroundImageRows$(): Observable<any> {
    if (this.blockState) {
      return this.blockState.select('blockValue', 'template', 'type').pipe(
        combineLatestWith(
          this.blockState.select('blockValue', 'rows'),
          this.blockState.select('blockValue', 'columns')
        ),
        tap(([templateType, rows, columns]: [TemplateType | null, Block['rows'], Block['columns']]) => {
          if (templateType && this.blockState) {
            const backgroundImageRows = this.getBackgroundImageRows(templateType, rows, columns[0]?.rows || []);
            this.blockState.set('backgroundImageRows', () => backgroundImageRows);
          }
        })
      );
    }
    return of(null);
  }

  ngOnInit(): void {
    if (this.stateId) {
      this.blockState = this.stateService.states[this.stateId];
      this.formGroup = this.formService.forms[this.blockState.get('block', 'id')];
    }
    this.blockState$ = this.blockState?.select();

    this.rxEffects.register(this.backgroundImageRows$);

    this.rowVisible = true;
    if (this.blockState && this.rowId) {
      const visibility: Visibility | undefined = this.blockState.get('blockValue', 'rows', this.rowId, 'visibility');
      if (!visibility) {
        this.rowVisible = true;
      }
    }
    this.rxEffects.register(this.rowVisible$);
  }

  ngAfterViewInit(): void {
    if (this.blockState && this.rowId && this.columnIndex !== undefined) {
      this.rxEffects.register(this.templateRef$);
    }
  }

  getBackgroundImageRows(templateType: TemplateType, rows: Block['rows'], rowIds: string[]): boolean[] {
    const backgroundImageRows = [];
    switch (templateType) {
      case TemplateType.BANNER:
      case TemplateType.BANNER_LARGE:
        if (rows[rowIds[0]]?.fieldType === FieldType.IMAGE) {
          backgroundImageRows.push(true);
          if (rows[rowIds[1]]?.fieldType === FieldType.IMAGE) {
            backgroundImageRows.push(true);
            if (rows[rowIds[2]]?.fieldType === FieldType.IMAGE) {
              backgroundImageRows.push(true);
            }
          }
        }
        break;
      default:
    }
    return backgroundImageRows;
  }

  setQuillEditorOptions(): void {
    const options = this.options.quillEditor;
    if (options.toolbarOptions) {
      options.toolbarOptions.buttons = [
        'undo',
        'redo',
        'size',
        'header',
        'bold',
        'italic',
        'underline',
        // "align",
        'ordered-list',
        'bullet-list',
        'blockquote',
        'code-block',
        'subscript',
        'superscript',
        'indent',
        'link',
      ];
    }
    this.quillEditorOptions = options;
  }

  getTemplateRef(fieldType: FieldType, accordion: boolean): TemplateRef<any> {
    switch (fieldType) {
      case FieldType.QUILL_EDITOR:
        if (accordion) {
          return this[FieldType.QUILL_EDITOR_ACCORDION] as TemplateRef<any>;
        } else {
          return this[fieldType] as TemplateRef<any>;
        }
      case FieldType.BLOCK_REFERENCE:
        return this[FieldType.MULTI_BLOCK] as TemplateRef<any>;
      case FieldType.AGENT_SCHEDULER:
      case FieldType.BATTERY_QUOTER:
      case FieldType.SMART_WEATHER:
      case FieldType.BUTTON:
      case FieldType.JAVASCRIPT:
      case FieldType.IMAGE:
      case FieldType.MULTI_BLOCK:
      case FieldType.PRICE_CHART_CARD:
      case FieldType.PRICE_CHART_MEMBER_QUANTITY:
      case FieldType.VIDEO:
      case FieldType.JOIN_RENEW_CONFIRMATION_SUCCESS:
      case FieldType.COMPARISON_CHART:
        return this[fieldType] as TemplateRef<any>;
      default:
        return this[fieldType] as TemplateRef<any>;
    }
  }
}
