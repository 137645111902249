import { AfterViewInit, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { interval, tap } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { WindowRefService } from '../../../../share/services/window-ref.service';
import { CaptureContextResponse, CybersourceService, MicroformField } from '../../services/cybersource';
import { FormGroup } from '@angular/forms';
import { HOOSIER_RX_STATE, HoosierService, HoosierState, SessionDocResponseObject } from '../../hoosier.service';
import { PaymentCaptureContextService } from '../../events/payment-capture-context';

export interface CaptureContextResponseObject extends SessionDocResponseObject {
  response: string;
}

/**
 * 5 steps
 *   1. trigger PAYMENT_QUERY with captureContext method
 *   2. listen to cybersourceDoc for captureContext response
 *   3. build microforms
 *   4. using expiration month and year, create microform token
 *   5. submit token result to PAYMENT_QUERY with submitPayment method
 */

/**
 * https://developer.cybersource.com/docs/cybs/en-us/digital-accept-flex/developer/all/rest/digital-accept-flex/microform-integ/api_reference/module_flex.html
 */

interface CardIcons {
  [key: string]: string;
}

@Component({
  selector: 'ava-hoosier-payment-flex-micro-form',
  templateUrl: './payment-flex-micro-form.html',
  providers: [RxState, RxEffects],
})
export class PaymentFlexMicroFormComponent implements AfterViewInit {
  @Input() flexDirection: 'row' | 'column' = 'row';
  @ViewChild('numberElement') numberElementRef: ElementRef | undefined;
  @ViewChild('securityCodeElement') securityCodeElementRef: ElementRef | undefined;
  numberField: MicroformField | undefined;
  securityCodeField: MicroformField | undefined;
  form: FormGroup | undefined;
  fieldsLoaded: boolean = false;
  cardIcons: CardIcons = {
    '000': 'icons:000-generic-credit-card',
    '001': 'icons:001-visa',
    '002': 'icons:002-mastercard',
    '003': 'icons:003-amex',
    '004': 'icons:004-discover',
    '005': 'icons:005-diners',
  };
  cardIcon: string = this.cardIcons['000'];
  cybsCardType: string = '';

  constructor(
    private window: WindowRefService,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private hoosierService: HoosierService,
    private rxEffects: RxEffects,
    private cybersourceService: CybersourceService,
    private paymentCaptureContextService: PaymentCaptureContextService
  ) {
    rxEffects.register(this.form$);
    rxEffects.register(this.resetFlexForms$);
  }

  form$ = this.hoosierState.select('form').pipe(
    tap(form => {
      if (form && !this.form) {
        this.form = form;
      }
    })
  );

  resetFlexForms$ = interval(10 * 60 * 1000) // 10 minutes
    .pipe(
      tap(() => {
        this.paymentCaptureContextService.paymentCaptureContext();
      })
    );

  PAYMENT_CAPTURE_CONTEXT$ = this.hoosierState.select('PAYMENT_CAPTURE_CONTEXT').pipe(
    tap(PAYMENT_CAPTURE_CONTEXT => {
      if (PAYMENT_CAPTURE_CONTEXT?.response) {
        this.initializeFlexMicroForm(PAYMENT_CAPTURE_CONTEXT.response);
      }
    })
  );

  ngAfterViewInit(): void {
    if (this.cybersourceService.flexMicroForm) {
      // this.reloadFlexMicroFormFields()
    }
    this.rxEffects.register(this.PAYMENT_CAPTURE_CONTEXT$);
  }

  initializeFlexMicroForm(captureContextResponse: CaptureContextResponse): void {
    const flex = new this.window.nativeWindow.Flex(captureContextResponse);
    const myStyles = {
      input: {
        'font-size': '14px',
        'font-family': 'helvetica, tahoma, calibri, sans-serif',
        color: '#555',
      },
      ':focus': {
        color: 'blue',
      },
      ':disabled': {
        cursor: 'not-allowed',
      },
      valid: {
        color: '#3c763d',
      },
      invalid: {
        color: '#a94442',
      },
    };
    this.cybersourceService.flexMicroForm = flex.microform({ styles: myStyles });

    this.numberField = this.cybersourceService.flexMicroForm.createField('number', {
      placeholder: 'Enter card number',
    });

    this.securityCodeField = this.cybersourceService.flexMicroForm.createField('securityCode', {
      placeholder: 'CVV',
    });

    this.numberField.on('change', data => {
      let isAllowedCardType = false;
      let cardIcon = this.cardIcons['000'];
      this.cybsCardType = data.card[0]?.cybsCardType;
      switch (this.cybsCardType) {
        case '001': // VISA
        case '002': // MasterCard
        case '003': // American Express
          isAllowedCardType = true;
          cardIcon = this.cardIcons[data.card[0].cybsCardType];
          break;
        case undefined:
        case '042': // Discover - cybersource documentation shows Discover as cardType 004, but in testing we get 042
      }
      this.cardIcon = cardIcon;

      const creditCardErrors = {
        ...(data.valid ? null : { notValid: true }),
        ...(isAllowedCardType ? null : { notAllowedCartType: true }),
      };
      this.form
        ?.get(['paymentPayload', 'executionData'])
        ?.patchValue({ creditCardBrandedName: data.card[0]?.brandedName || '' });
      this.form?.get('creditCard')?.patchValue({ numberIsValid: data.valid });
      this.form?.get(['creditCard', 'numberIsValid'])?.setErrors(creditCardErrors);
    });

    this.securityCodeField.on('change', data => {
      // console.log(data)
      this.form?.get('creditCard')?.patchValue({ securityCodeIsValid: data.valid });
      this.form?.get(['creditCard', 'securityCodeIsValid'])?.setErrors(data.valid ? null : { notValid: true });
    });

    this.loadFlexMicroFormFields();
  }

  reloadFlexMicroFormFields() {
    if (this.numberField && this.securityCodeField) {
      this.numberField.unload();
      this.securityCodeField.unload();
      this.loadFlexMicroFormFields();
    }
  }

  loadFlexMicroFormFields() {
    if (this.numberField && this.securityCodeField) {
      this.numberField.load(this.numberElementRef?.nativeElement);
      this.securityCodeField.load(this.securityCodeElementRef?.nativeElement);
      this.fieldsLoaded = true;
    }
  }
}
