import { inject, Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { first, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppStore } from '../../../../store/types/store.model';
import {
  getEmailExistInClubResponseDone,
  getZipCodeInClubResponseDone,
  ValidatorsActions,
} from '@aaa/emember/store-validators';
import { getStateByZipcode } from '../../utils/get-state-by-zipcode';

export const supportedCardTypes = ['visa', 'mastercard', 'amex'];

@Injectable({ providedIn: 'root' })
export class ValidatorService {
  store = inject(Store<AppStore>);

  trimString = () => (control: AbstractControl) => {
    const controlValue = String(control.value);
    const value = controlValue.trim();
    if (value.length < controlValue.length) {
      control.setValue(value);
    }

    return null;
  };

  membershipNumberFormat = () => (control: AbstractControl) => {
    const value = String(control.value).trim();
    if (value && value.length !== 16) {
      return { invalidMembershipFormat: true };
    }

    return null;
  };

  emailExistInClub =
    () =>
    (control: AbstractControl): Observable<ValidationErrors | null> => {
      this.store.dispatch(ValidatorsActions.emailExistInClub({ email: control.value }));

      return this.store.select(getEmailExistInClubResponseDone).pipe(
        first(({ status }) => status),
        map(({ response }) => response)
      );
    };

  zipCodeIsInClub =
    () =>
    (control: AbstractControl): Observable<ValidationErrors | null> => {
      this.store.dispatch(ValidatorsActions.zipCodeInClub({ zipcode: control.value }));

      return this.store.select(getZipCodeInClubResponseDone).pipe(
        first(({ status }) => status),
        map(({ response }) => response)
      );
    };

  isTextString =
    () =>
    (control: AbstractControl): ValidationErrors | null => {
      const regex = /^[A-Za-z\s]*$/;
      const spacesOnly = /^\s*$/;

      if (!regex.test(control.value) || spacesOnly.test(control.value)) {
        return { isNotString: true };
      }

      return null;
    };

  zipCodeFormat =
    () =>
    (control: AbstractControl): ValidationErrors | null => {
      const regex = /^\d\d\d\d\d$/;
      if (!regex.test(control.value)) {
        return { invalidZipCode: true };
      }

      return null;
    };

  // we can extend this validator to support more date formats
  dateFormat =
    (format: string = 'MM/DD/YYY') =>
    (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value) {
        const result = new Date(value);
        if (isNaN(result.getDate())) return { invalidDateFormat: true };
      }

      return null;
    };

  phoneNumber = () => (control: AbstractControl) => {
    if (control.value && !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}\d*$/.test(control.value)) {
      return { invalidPhoneNumber: true };
    }

    return null;
  };

  email = () => (control: AbstractControl) => {
    if (control.value && !/^\S+@\S+\.\S+$/.test(control.value) && control.value.length) {
      return { invalidEmail: true };
    }

    return null;
  };

  minMonthValue = (monthControlName: string, yearControlName: string): ValidatorFn => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const minMonth = Validators.min(currentMonth);

    return (control: AbstractControl): ValidationErrors | null => {
      const monthControl = control.get(monthControlName);
      const yearControl = control.get(yearControlName);

      if (monthControl && yearControl) {
        const yearVal = Number(yearControl.value);
        const monthVal = Number(monthControl.value);

        if (monthVal && yearVal && yearVal === currentYear && monthVal <= currentMonth) {
          monthControl.addValidators(minMonth);
          monthControl.updateValueAndValidity({ onlySelf: true });
        } else {
          monthControl.removeValidators(minMonth);
          monthControl.updateValueAndValidity({ onlySelf: true });
        }
      }

      return null;
    };
  };

  password =
    () =>
    (control: AbstractControl): ValidationErrors | null => {
      const regex = /^(?=.*\d)(?=.*[@#\-_$%^&+=§!?])(?=.*[a-z])(?=.*[A-Z])[0-9A-Za-z@#\-_$%^&+=§!?]{8,20}$/;

      if (!regex.test(control.value)) {
        return { weakPassword: true };
      }

      return null;
    };

  zipCodeIsInRegion =
    (stateControlName: string) =>
    (zipcodeControl: AbstractControl): ValidationErrors | null => {
      const stateControl = zipcodeControl.parent?.get(stateControlName);

      if (stateControl?.value && zipcodeControl?.value?.length === 5) {
        const stateByZipcode = getStateByZipcode(zipcodeControl.value);

        if (stateByZipcode !== stateControl.value) {
          return { zipcodeIsNotInsideState: true };
        }
      }

      return null;
    };
}
