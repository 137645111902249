import { AppEnv, ClubApp, Environment } from '@aaa/emember/types';

/**
 * copy/pasted from firebase project settings
 */
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD78nZk4aHSOLMGCih1-1BNa7OKyioWmzg',
  authDomain: 'aaa-southjersey-2.firebaseapp.com',
  databaseURL: 'https://aaa-southjersey-2-default-rtdb.firebaseio.com',
  projectId: 'aaa-southjersey-2',
  storageBucket: 'aaa-southjersey-2.appspot.com',
  messagingSenderId: '947807890109',
  appId: '1:947807890109:web:9e5fe52b0ed744c8c2a5d4',
  measurementId: 'G-VKVP6QMXX4',
};

export const environment: Environment = {
  production: true,
  emulator: false,
  ngServe: false,
  appEnv: AppEnv.TEST,
  cloudFunctionsURL: 'https://test.southjersey.millisite.com',
  cloudStorageURL: `https://storage.googleapis.com/${firebaseConfig.storageBucket}`,
  clubId: ClubApp.SouthJersey,
  firebaseConfig: firebaseConfig,
  SMARTY_WEBSITE_KEY: '',
  paymentConfig: {
    cyberSourceSessionTimeout: 60 * 1000 * 10, // ten minutes
  },
  aaaRestWsUrl: 'https://ww2.aaa.com',
  baseHref: 'https://test.southjersey.millisite.com/',
  zipcode: '08033',
  smarty: {
    authId: 'us-autocomplete-pro-cloud',
    license: '13199187911746353',
  },
  shift4Key: 'pk_test_pAZibPZ33iK3idENK9mso5Hz',
};
