import { createReducer, on } from '@ngrx/store';
import { PriceOffersActions } from './price-offers.actions';
import { RequestStatus } from '../../../types/request-status';
import { MembershipsLevel, PriceOffersState } from './price-offers.model';
import { MembershipOfferItem } from './helpers/types';

export const initialStatePriceOffers: PriceOffersState = {
  query: {
    promoCode: '',
    couponCode: '',
    programCode: '',
  },
  levelOffers: null,
  rawLevelOffers: null,
  recordLevels: {},
  levelIds: [],
  requestStatus: RequestStatus.UNSENT,
  error: null,
};

export const priceOffersReducer = createReducer(
  initialStatePriceOffers,
  on(PriceOffersActions.load, (state, { query }) => {
    const newState = { ...state, query, error: null, requestStatus: RequestStatus.RUNNING };

    if (newState.query?.programCode) {
      newState.query = { ...newState.query, programCode: newState.query.programCode.trim().toUpperCase() };
    }

    return newState;
  }),
  on(PriceOffersActions.loadSucceeded, (state, { rawLevelOffers, levelOffers }) => ({
    ...state,
    rawLevelOffers,
    levelOffers,
  })),
  on(PriceOffersActions.loadSucceeded, PriceOffersActions.update, (state, { levels, levelOffers }) => {
    const levelIds = levels.map(level => level.membershipType);
    const recordLevels: Record<string, MembershipsLevel> = levels.reduce(
      (acc, level) => ({ ...acc, [level.membershipType]: level }),
      {}
    );

    return {
      ...state,
      levelIds,
      recordLevels,
      levelOffers: levelOffers ? levelOffers : state.levelOffers,
      requestStatus: RequestStatus.SUCCESS,
      error: null,
    };
  }),
  on(PriceOffersActions.loadFailed, (state, { error, levelOffers, levels }) => {
    let levelIds: string[] = state.levelIds;
    let recordLevels = state.recordLevels;
    if (levels) {
      levelIds = levels.map(l => l.membershipType);
      recordLevels = levels.reduce(
        (acc, l) => {
          acc[l.membershipType] = l;
          return acc;
        },
        {} as Record<string, MembershipsLevel>
      );
    }
    return {
      ...state,
      error,
      levelIds,
      recordLevels,
      rawLevelOffers: levelOffers ? levelOffers : state.levelOffers,
      levelOffers: levelOffers ? levelOffers : state.levelOffers,
      requestStatus: RequestStatus.FAILED,
    };
  }),
  on(PriceOffersActions.updateAndInsert, (state, { membershipType, componentOffers }) => {
    if (state.levelOffers && state.rawLevelOffers) {
      const findOffer = (offer: Partial<MembershipOfferItem>) => {
        return ({ code, offering }: Partial<MembershipOfferItem>) => {
          return code === offer.code && offering === offer.offering;
        };
      };
      const levelOffers = state.rawLevelOffers[membershipType] || [];
      const updatedLevelOffers = levelOffers.map(offer => {
        const existingOffer = componentOffers.find(findOffer(offer));

        if (existingOffer) {
          return { ...offer, ...existingOffer };
        }

        return offer;
      });
      const newLevelOffers = componentOffers.filter(offer => {
        const existingOffer = updatedLevelOffers.find(findOffer(offer));

        return !existingOffer;
      }) as MembershipOfferItem[];

      return {
        ...state,
        levelOffers: {
          ...state.rawLevelOffers,
          [membershipType]: [...updatedLevelOffers, ...newLevelOffers],
        },
      };
    }

    return state;
  }),
  on(PriceOffersActions.updateOptionals, (state, { membershipType, optionalOffers, filter }) => {
    if (state.levelOffers) {
      const levelOffers: MembershipOfferItem[] = [];
      const membershipTypeOffers = state.levelOffers[membershipType] || [];
      const onlyAssociateAndPrimary = membershipTypeOffers.filter(({ offering }) =>
        ['associate', 'primary'].includes(offering)
      );
      levelOffers.push(...onlyAssociateAndPrimary);
      levelOffers.push(...optionalOffers);

      if (filter?.addIfMissed?.length && state.rawLevelOffers) {
        const membershipTypeRawOffers = state.rawLevelOffers[membershipType];
        const addedMissedOptions = filter.addIfMissed
          .map(({ code, offering }) => {
            const exist = optionalOffers.find(o => o.code === code && o.offering === offering);

            if (exist) {
              return null;
            }

            return membershipTypeRawOffers.find(
              offer => offer.offering === offering && offer.code === code && offer.offering === offering
            );
          })
          .filter((offer): offer is MembershipOfferItem => !!offer);

        levelOffers.push(...addedMissedOptions);
      }

      return {
        ...state,
        levelOffers: {
          ...state.levelOffers,
          [membershipType]: levelOffers,
        },
      };
    }

    return state;
  }),
  on(PriceOffersActions.setOffers, (state, { levelOffers }) => ({
    ...state,
    levelOffers: levelOffers,
  }))
);
