import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MetaWindow } from '../interfaces/window';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { map } from 'rxjs/operators';

export interface AgentType {
  _type: 'agent_type';
  id: string;
  callout: string;
  description: string;
  insuranceProducts: string;
  name: string;
  officeHeading: string;
  quoteUrl: {
    url: string;
    title: string;
  };
  weight: string;
}

export interface AgentTypes {
  [key: string]: AgentType;
}

@Injectable({
  providedIn: 'root',
})
export class AgentTypeService {
  window: MetaWindow;
  private agentTypesRef: AngularFirestoreCollection<AgentType>;
  public agentTypes$: Observable<AgentType[]>;

  constructor(
    private afs: AngularFirestore,
    private httpClient: HttpClient,
    private domWindow: Window
  ) {
    this.window = domWindow as unknown as MetaWindow;
    this.agentTypesRef = this.afs
      .collection('wss-aaa-web')
      .doc(this.window.metaData.clubId)
      .collection('data')
      .doc('drupal')
      .collection('terms', ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('_type', '==', 'agentType');
        return query;
      });
    this.agentTypes$ = this.agentTypesRef.snapshotChanges().pipe(
      map((agentTypes: DocumentChangeAction<AgentType>[]) => {
        return agentTypes.map(result => {
          if (result) {
            const data = result.payload.doc.data();
            data.id = result.payload.doc.id;
            return data;
          }
          return result;
        });
      })
    );
  }

  // getOffices(): Observable<Offices> {
  // const officesUrl = this.window.metaData.origin || "" + "/data/office/all"
  // return this.httpClient.get<Offices>(officesUrl)
  // return this.officesRef.snapshotChanges().subscribe()
  // }

  /*
  handleError(error: HttpErrorResponse): ObservableInput<never> {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message)
    } else {
      console.log("An different error occurred:")
      console
        .error("Backend returned code " + error.status, "body was: " + error.error)
    }
    return throwError("Something bad happened; please try again later.")
  }
*/
}
