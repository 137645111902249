import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { PriceOffersActions } from './price-offers.actions';
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuitePricePreviewsEventPayload,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { catchError, map } from 'rxjs/operators';
import { RequestError, RequestErrorType } from '../generic-errors';
import { ConnectSuite } from '../connect-suite.type';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { getMembershipLevels, getMembershipOffering } from './helpers/parse-price-offer-connect-suite-system';
import { getSaveCacheSettings } from '../utils/get-cache-settings';
import { getClubId } from '@aaa/emember/store-membership';

@Injectable({ providedIn: 'root' })
export class PriceOffersConnectSuiteEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Hoosier, ClubApp.SouthJersey]));
  executeService = inject(ExecuteService);

  loadPricePreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceOffersActions.load),
      switchMap(action => of(action).pipe(withLatestFrom(this.store.select(getClubId)))),
      switchMap(([{ query }, clubApp]) => {
        const requestPayload: MembershipConnectSuitePricePreviewsEventPayload = {
          cacheSettings: getSaveCacheSettings(),
          method: MembershipConnectSuiteMethod.PRICE_PREVIEWS,
          postalCode: query.zipCode || '',
          couponCode: query.couponCode,
          promoCode: query.promoCode,
        };
        const priceWithoutDiscountPayload: MembershipConnectSuitePricePreviewsEventPayload = {
          cacheSettings: getSaveCacheSettings(),
          method: MembershipConnectSuiteMethod.PRICE_PREVIEWS,
          postalCode: query.zipCode || '',
          couponCode: '',
          promoCode: '',
        };

        return forkJoin({
          discount: this.executeService.membershipQuery<ConnectSuite.PricePreviewsResponseObject>(requestPayload),
          withoutDiscount:
            this.executeService.membershipQuery<ConnectSuite.PricePreviewsResponseObject>(priceWithoutDiscountPayload),
        }).pipe(
          map(({ discount, withoutDiscount }) => {
            let error: RequestError | null = null;

            if (discount.error && withoutDiscount.error) {
              return PriceOffersActions.loadFailed({ error: new RequestError(RequestErrorType.PricePreviewError) });
            }

            if (discount.error) {
              error = new RequestError(RequestErrorType.PricePreviewInvalidPromoCode);
            }

            const responseCode = discount.response?.promoNoEnrollPrices[0].error?.responseCode;

            if (responseCode) {
              switch (responseCode) {
                case '202':
                  error = new RequestError(RequestErrorType.PricePreviewReservedCouponCode);
                  break;
                case '203':
                  error = new RequestError(RequestErrorType.PricePreviewRedeemedCouponCode);
                  break;
                case '211':
                  error = new RequestError(RequestErrorType.PricePreviewInvalidCouponCode);
                  break;
                default:
                  error = new RequestError(RequestErrorType.PricePreviewInvalidPromoCode);
              }
            }

            if (error) {
              const levelOffers = getMembershipOffering(withoutDiscount),
                levels = getMembershipLevels(withoutDiscount, clubApp);

              return PriceOffersActions.loadFailed({ error, levelOffers, levels });
            } else {
              const levelOffers = getMembershipOffering(discount),
                rawLevelOffers = getMembershipOffering(withoutDiscount),
                levels = getMembershipLevels(discount, clubApp);
              return PriceOffersActions.loadSucceeded({ levelOffers, rawLevelOffers, levels });
            }
          }),
          catchError(error =>
            of(PriceOffersActions.loadFailed({ error: new RequestError(RequestErrorType.PricePreviewError, error) }))
          )
        );
      })
    )
  );
}
