import { Inject, Injectable } from '@angular/core';
import { LOCATION, SESSION_STORAGE } from '@ng-web-apis/common';
import { RxState } from '@rx-angular/state';
import { combineLatestWith, Observable, OperatorFunction, tap } from 'rxjs';
import { RxEffects } from '@rx-angular/state/effects';
import { IdTokenResultWithClaims, JwtAuthService } from './jwt-auth';
import { GLOBAL_RX_STATE, GlobalState } from './state';
import firebase from 'firebase/compat/app';

export interface UserSession {
  couponCode: string | false; // empty string if code is not present, false if code is invalid
  promoCode: string | false; // empty string if code is not present, false if code is invalid
  sessionId: string;
  zipcode: string;
}

export enum UserRole {
  'AAA_BASIC' = 'AAA_BASIC',
  'AAA_CANCELLED' = 'AAA_CANCELLED',
  'AAA_PLUS' = 'AAA_PLUS',
  'AAA_PLUS_MOTORCYCLE' = 'AAA_PLUS_MOTORCYCLE',
  'AAA_PLUS_RV' = 'AAA_PLUS_RV',
  'AAA_PREMIER' = 'AAA_PREMIER',
  'AAA_PREMIER_RV' = 'AAA_PREMIER_RV',
  'AGENT_ADMIN' = 'AGENT_ADMIN',
  'AUTOMOTIVE_ADMIN' = 'AUTOMOTIVE_ADMIN',
  'CAR_BUYING_ADMIN' = 'CAR_BUYING_ADMIN',
  'DISCOUNTS_ADMIN' = 'DISCOUNTS_ADMIN',
  'EVENT_ADMIN' = 'EVENT_ADMIN',
  'MAGAZINE_ADMIN' = 'MAGAZINE_ADMIN',
  'NEWS_ADMIN' = 'NEWS_ADMIN',
  'OFFICE_ADMIN' = 'OFFICE_ADMIN',
  'STORE_ADMIN' = 'STORE_ADMIN',
  'TRAVEL_ADMIN' = 'TRAVEL_ADMIN',

  'CONTENT_ADMIN' = 'CONTENT_ADMIN',
  'CONTENT_CREATOR' = 'CONTENT_CREATOR',
  'CONTENT_EDITOR' = 'CONTENT_EDITOR',
  'VIEW_ONLY' = 'VIEW_ONLY',

  'SITE_ADMIN' = 'SITE_ADMIN',
  'USER_ADMIN' = 'USER_ADMIN',

  'ADMINISTRATOR' = 'ADMINISTRATOR',
  'WEBMASTER' = 'WEBMASTER',

  'ANONYMOUS_USER' = 'ANONYMOUS_USER',
  'AUTHENTICATED_USER' = 'AUTHENTICATED_USER',
}

export type UsersRoles = {
  [key in UserRole]: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  usersRoles: UsersRoles = {} as UsersRoles;

  constructor(
    private jwtRequired: JwtAuthService,
    @Inject(SESSION_STORAGE)
    private sessionStorage: Storage,
    @Inject(LOCATION)
    private location: Location,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private rxEffects: RxEffects
  ) {
    rxEffects.register(this.usersRoles$);
  }

  usersRoles$ = this.globalState.select('afAuthIdTokenResult').pipe(
    combineLatestWith(this.globalState.select('userSession', 'sessionId')),
    tap(([afAuthIdTokenResult, sessionId]: [firebase.auth.IdTokenResult | null, string]) => {
      let rolesArray = undefined;
      if (afAuthIdTokenResult?.claims) {
        rolesArray = afAuthIdTokenResult.claims.roles;
      }
      if (!rolesArray) {
        this.globalState.set('adminUser', () => false);
        // this.sessionStorage.setItem("stateMode", lzString.compress("obfuscate"))
      }
      if (rolesArray) {
        const enumRoles: UsersRoles = {} as UsersRoles;
        if (rolesArray) {
          for (const role of rolesArray) {
            const enumRole = role.replace(new RegExp(' ', 'g'), '_').toUpperCase();
            enumRoles[enumRole as UserRole] = true;
          }
        }
        this.usersRoles = enumRoles;
        this.globalState.set('adminUser', () => this.userIsSuperAdmin(this.usersRoles));
        // const stateMode = this.userIsSuperAdmin(this.usersRoles) ? "debug" : "obfuscate"
        // this.sessionStorage.setItem("stateMode", lzString.compress(stateMode))
      }
    })
  );

  userHasRole(usersRoles: UsersRoles, roleQuery: UserRole | UserRole[]): boolean {
    if (typeof roleQuery === 'string') {
      return usersRoles[roleQuery];
    } else {
      return roleQuery.some(roleQ => usersRoles[roleQ]);
    }
  }

  private userIsSuperAdmin(usersRoles: UsersRoles): boolean {
    const roles = [UserRole.ADMINISTRATOR];
    return this.userHasRole(usersRoles, roles);
  }

  private userIsEditor(): boolean {
    const roles = [
      UserRole.AGENT_ADMIN,
      UserRole.AUTOMOTIVE_ADMIN,
      UserRole.CAR_BUYING_ADMIN,
      UserRole.DISCOUNTS_ADMIN,
      UserRole.EVENT_ADMIN,
      UserRole.MAGAZINE_ADMIN,
      UserRole.NEWS_ADMIN,
      UserRole.OFFICE_ADMIN,
      UserRole.STORE_ADMIN,
      UserRole.TRAVEL_ADMIN,

      UserRole.CONTENT_ADMIN,
      UserRole.CONTENT_CREATOR,
      UserRole.CONTENT_EDITOR,

      UserRole.SITE_ADMIN,
      UserRole.USER_ADMIN,

      UserRole.ADMINISTRATOR,
      UserRole.WEBMASTER,
    ];
    return this.userHasRole(this.usersRoles, roles);
  }

  /**
   * Three comparison functions: intersection(), difference(), and symmetricDifference()
   * Inspired by the "Plain Javascript" post in this stackoverflow
   * https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript
   * a1 and a2 are arrays
   *
   * records that are in both a1 and a2
   */
  intersection(a1: string[], a2: string[]): string[] {
    const a2Set = new Set(a2);
    return a1.filter(x => a2Set.has(x));
  }

  /**
   * records that are in a1 but not in a2
   */
  difference(a1: string[], a2: string[]): string[] {
    const a2Set = new Set(a2);
    return a1.filter(x => !a2Set.has(x));
  }

  /**
   * records that are not in both a1 and a2
   * aka
   * records that are in a1 but not in a2 AND ALSO records that are in a2 but not in a1
   */

  symmetricDifference(a1: string[], a2: string[]): string[] {
    return this.difference(a1, a2).concat(this.difference(a2, a1));
  }

  uniqueMerge(a1: string[], a2: string[]): string[] {
    return [...this.intersection(a1, a2), ...this.symmetricDifference(a1, a2)];
  }
}

/**
 * Todo: map groups of roles to new logical role names, this way all the roles and permissions mapping are done here.
 *
 * examples:
 * - map all the admin roles to an admin_menu boolean so that our global toolbar only needs to observe this admin_menu boolean.
 * - map administrator, Site Admin, Webmaster, and Magazine Admin to an admin_magazine boolean for the magazine edit widget.
 */

/**
 * We decided not to use role ids because the role name to role id mapping varies between clubs.
 * Role id mappings, the Role Label can be different between clubs.
 * https://www.colorado.aaa.com/admin/people/permissions/roles.
 *

 roleId,Role Label

 1,anonymous user
 2,authenticated user
 3,Travel Admin
 4,Content Editor
 5,Webmaster
 6,administrator
 7,Discounts Admin
 8,Guest
 9,AAA Classic
 10,AAA Plus
 11,AAA Plus Motorcycle
 12,AAA Plus RV
 13,AAA Premier
 14,Automotive Admin
 15,Car Buying Admin
 16,Office Admin
 17,News Admin
 18,User Admin
 19,Agent Admin
 20,Content Admin
 21,Site Admin
 22,Event Admin
 23,Store Admin
 24,Magazine Admin
 25,AAA Premier RV
 26,Content Creator
 27,View Only
 28,AAA Cancelled
 */

/**
 * aaa-basic
 * aaa-cancelled
 * aaa-plus
 * aaa-plus-motorcycle
 * aaa-plus-rv
 * aaa-premier
 * aaa-premier-rv
 *
 * agent-admin
 * automotive-admin
 * car-buying-admin
 * discounts-admin
 * event-admin
 * magazine-admin
 * news-admin
 * office-admin
 * store-admin
 * travel-admin
 *
 * content-admin
 * content-creator
 * content-editor
 * view-only
 *
 * site-admin
 * user-admin
 *
 * administrator
 * webmaster
 *
 * anonymous-user
 * authenticated-user
 */

/**
 * permissions
 */
