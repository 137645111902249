import { M } from './m.type';
import { RequestError, RequestErrorType } from './generic-errors';

export function checkMembershipErrorsMSystem(
  error: M.MembershipError,
  obj: any,
  hasCouponCode = false,
  hasPromoCode = false
) {
  const response = error?.data?.Result?.$;
  const errorText = response?.errorText || '';

  if (errorText) {
    if (errorText.startsWith('We cannot add this associate at this time')) {
      throw new RequestError(RequestErrorType.MembershipAddAssociateError, obj);
    }

    if (
      errorText.startsWith('You appear to have an existing membership') ||
      errorText.startsWith('There is an existing membership for this member')
    ) {
      throw new RequestError(RequestErrorType.MembershipAlreadyExistingError, obj);
    }

    if (errorText.startsWith('Unable to process. Duplicate membership joins')) {
      throw new RequestError(RequestErrorType.MembershipDuplicateJoinsError, obj);
    }

    if (errorText.startsWith('Unable to process. Duplicate payments have been received today')) {
      throw new RequestError(RequestErrorType.MembershipPaymentDuplicateReceivedError, obj);
    }

    if (errorText.startsWith('Cannot find this member')) {
      throw new RequestError(RequestErrorType.MembershipNotFound, obj);
    }

    if (
      errorText.startsWith('We were unable to locate the promotional code') ||
      errorText.startsWith('The promotion code you entered')
    ) {
      if (hasCouponCode) {
        throw new RequestError(RequestErrorType.MembershipInvalidCouponCode, obj);
      }

      if (hasPromoCode) {
        throw new RequestError(RequestErrorType.MembershipInvalidPromoCode, obj);
      }
    }

    if (
      errorText.startsWith(
        'Your membership is currently in the process of being auto-renewed and no changes can be made online at this time'
      )
    ) {
      throw new RequestError(RequestErrorType.MembershipEnabledAutoRenew, obj);
    }

    // it return when user it is cancelled
    // if (errorText.startsWith("You must contact our office at")) {
    //   throw new RequestError(RequestErrorType.MembershipNotFound, obj)
    // }

    throw new RequestError(RequestErrorType.MembershipError, obj);
  }

  if (error?.authError) {
    throw new RequestError(RequestErrorType.MembershipNotFound, obj);
  }

  if (error) {
    throw new RequestError(RequestErrorType.MembershipError, obj);
  }
}
