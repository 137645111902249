import { Inject, Injectable, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Block } from '../block/services/block';
import { RxEffects } from '@rx-angular/state/effects';
import { BlockForms, GLOBAL_RX_STATE, GlobalState } from './state';
import { RxState } from '@rx-angular/state';
import { tap } from 'rxjs/operators';

export interface FormArrayOptions {
  min: number;
  max: number;
}

export enum FormStatus {
  INVALID = 'INVALID',
  VALID = 'VALID',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED',
}

@Injectable({
  providedIn: 'root',
})
export class FormService {
  forms: BlockForms = {};

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private rxEffects: RxEffects
  ) {
    rxEffects.register(this.forms$);
    rxEffects.register(this.pageForm$);
  }

  forms$ = this.globalState.select('blocksArray').pipe(
    tap(blocksArray => {
      for (const block of blocksArray) {
        // console.log(block)
        if (!this.forms[block.id]) {
          const form = this.newForm(block) as FormGroup;
          if (form) {
            this.addForm(form);
          }
        }
      }
    })
  );

  pageForm$ = this.globalState.select('pageBlock').pipe(
    tap(pageBlock => {
      // for (const block of blocksArray) {
      //   console.log(pageBlock)
      if (!this.forms[pageBlock.id]) {
        const form = this.newForm(pageBlock) as FormGroup;
        if (form) {
          this.addForm(form as FormGroup);
        }
      }
      // }
    })
  );

  /**
   * helper methods
   */

  addForm(form: FormGroup): void {
    this.forms[form.getRawValue().id] = form;
  }

  resetForms(form: FormGroup): void {
    this.forms = { [form.getRawValue().id]: form };
  }

  loadToolbar(toolbarContainerRef: ViewContainerRef | undefined, stateId: string): void {
    if (toolbarContainerRef) {
      import('../block/toolbar-block/view').then(component => {
        toolbarContainerRef.clear();
        const componentRef = toolbarContainerRef.createComponent(Object.values(component)?.[0]);
        componentRef.instance['stateId'] = stateId;
        // componentRef.instance["showEditModal"] = showEditModal
      });
    }
  }

  /*
  loadMultiblockEditForm(editFormContainerRef: ViewContainerRef, blockElementRef: ElementRef, stateId: string): void {
    import("../containers/multiblock/edit").then(component => {
      editFormContainerRef.clear()
      const componentRef = editFormContainerRef.createComponent(this.cfr.resolveComponentFactory(Object.values(component)[0] as Type<unknown>))
      componentRef.instance["stateId"] = stateId
      componentRef.instance["blockElementRef"] = blockElementRef
    })
  }
*/

  /*
  createStateForm(stateId: string, block: Block): void {
    if (!this.stateService.state[stateId]?.form) {
      this.stateService.setState(stateId, { form: this.newForm(block) })
      // this.stateService.compareBlockState(stateId)
    }
  }
*/

  newForm = (item: any, options?: any): AbstractControl | undefined => {
    // if (item === undefined || item === null) {
    //   return new FormControl(null)
    // }
    if (this.isControl(item)) {
      return new FormControl(item);
    } else if (this.isGroup(item)) {
      const group: { [key: string]: any } = {};
      for (const key in item) {
        if (options?.formControlKeys?.includes(key)) {
          group[key] = new FormControl(item[key]);
        } else {
          group[key] = this.newForm(item[key], options);
        }
      }

      return new FormGroup(group);
    } else if (this.isArray(item)) {
      const array = <any>[];
      for (const key in item) {
        if (options?.formControlKeys?.includes(key)) {
          array[key] = new FormControl(item[key]);
        } else {
          array[key] = this.newForm(item[key], options);
        }
      }

      return new FormArray(array);
    }

    return;
  };

  isControl(data: any): boolean {
    return typeof data === 'boolean' || typeof data === 'number' || typeof data === 'string' || data === null;
  }

  isArray(data: any): boolean {
    return Array.isArray(data);
  }

  isGroup(data: Block): boolean {
    return !this.isControl(data) && !this.isArray(data);
  }

  getFormType(data: never): string {
    if (typeof data === 'string') {
      return 'control';
    }
    if (typeof data === 'number') {
      return 'control';
    }
    if (typeof data === 'boolean') {
      return 'control';
    }
    if (Array.isArray(data)) {
      return 'array';
    }
    return 'group';
  }

  /*
  showEditModal = (component: TemplateRef<never>, stateId: string, index?: number): void => {
    if (this.state.selectedField) {
      this.stateService.setState(stateId, { selectedField: undefined })
      return
    }
    this.stateService.setState(stateId, { modalActive: true })
    if (this.adminMode) {
      this.modalRef = this.modalService.create({
        nzContent: component,
        // nzBodyStyle: { "padding": "20px" },
        nzFooter: null,
        nzWidth: "600px",
        // nzMaskClosable: false,
        nzStyle: { position: "absolute", top: 0, right: 0 },
        nzComponentParams: {
          stateId: stateId,
          index: index,
        }
      })
      this.modalRef.afterClose.subscribe(() => {
        this.stateService.setState(stateId, { modalActive: false })
      })
    }
  }
*/
}
