import { AfterViewInit, Component, EventEmitter, forwardRef, inject, Input, OnDestroy, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { en_US, NzI18nService } from 'ng-zorro-antd/i18n';
import { differenceInCalendarDays } from 'date-fns';
import { FormBaseControlComponent } from '../base';
import { delay, Subject, takeUntil } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'ava-form-date',
  template: `
    <nz-form-item [ngClass]="{ 'ava-label-hide': !formLabel }">
      <nz-form-label class="ava-form-label" [nzFor]="controlId" [nzNoColon]="true" [nzSpan]="7">
        {{ formLabel || placeholder }}
      </nz-form-label>
      <nz-form-control [nzValidateStatus]="formControlStatus" [nzErrorTip]="errors" nzHasFeedback>
        <nz-date-picker
          [formControl]="formControl"
          [nzId]="controlId"
          [nzPlaceHolder]="placeholder"
          [nzFormat]="dateFormat"
          [nzDisabledDate]="disabledDate"
          nzShowToday="false"
          nzMode="date"
          class="ava-form-control"
        />
        <ng-template #errors let-control>
          <ng-container *ngIf="formControl?.errors">
            <ng-container *ngIf="(formControl.errors | keyvalue)[0] as error">
              <div
                class="ava-form-error"
                [innerHTML]="errorMessages && errorMessages[error.key] ? errorMessages[error.key] : error.key"
              ></div>
            </ng-container>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      @import '../../../../../styles/ava-responsive';

      :host {
        nz-date-picker {
          width: 100%;
          padding: 7.1px 11px;

          ::ng-deep {
            .ant-picker-input input {
              @include formControlInputFontStyle();

              &::placeholder {
                @include formControlPlaceholderStyle();
              }
            }
          }
        }

        .ava-label-hide nz-form-label {
          width: 0;
          overflow: hidden;
          position: absolute;
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormDateComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, NzFormModule, NzInputModule, ReactiveFormsModule, NzAutocompleteModule, NzDatePickerModule],
  standalone: true,
})
export class FormDateComponent extends FormBaseControlComponent implements AfterViewInit, OnDestroy {
  i18n = inject(NzI18nService);
  @Input() dateFormat = 'MM/dd/yyyy';
  @Output() dateChange = new EventEmitter<string>();
  today = new Date();
  alive$ = new Subject();
  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this.today) > 0;
  constructor() {
    super();
    this.i18n.setLocale(en_US);
  }

  ngAfterViewInit() {
    this.formControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.alive$), delay(0))
      .subscribe(value => this.dateChange.emit(value));
  }

  ngOnDestroy() {
    this.alive$.next(null);
    this.alive$.complete();
  }
}
