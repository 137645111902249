import { CanActivateChildFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { getAccountDetailsMembershipNumber } from '@aaa/emember/store-account';

export const authenticatedMembership: CanActivateChildFn = () => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(getAccountDetailsMembershipNumber).pipe(
    map(membershipNumber => {
      if (!membershipNumber) {
        router.navigate(['login']);

        return false;
      }

      return true;
    })
  );
};

export default authenticatedMembership;
