import { Injectable, ElementRef, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { WebEnvironment } from '../../interfaces/window';
import { RxState } from '@rx-angular/state';
import { FieldType } from '../../services/field';
import { FieldBase } from '../../block/services/block';
import { GLOBAL_RX_STATE, GlobalState } from '../../services/state';

interface BatteryQuoteResults {
  CCA: number; // 535,
  Engine: string; // "L4 2.0L "
  IsDifficultInstall: boolean; // false
  IsResetRequired: boolean; // false
  MakeName: string; // "Alfa Romeo"
  MemberPrice: number; // 259.99
  ModelName: string; // "147"
  NonMemberPrice: number; // 301.59
  Notes: string; // "66"
  OECCA: number; // 360
  OEGroupName: string; // "140R"
  Option2CCA: number; // 0
  Option2MemberPrice: number; // 0
  Option2NonMemberPrice: number; // 0
  Option2PartName: string; // "N/A"
  PartName: string; // "140RAGM-C"
  VehicleId: string; // "b05418bf-f06e-43a3-9efc-4f3bca811999"
  YearId: number; // 2007
}

interface BatteryQuoter extends FieldBase {
  // label?: string
}

export interface BatteryQuoterInput {
  buttonCopy: string;
  buttonUrl: string;
  clubNumber?: string;
  email: boolean;
  endpoint: string;
  imageUrl: string;
  labelForButton: string;
  labelForButtonDisabled: string;
  membership: boolean;
  name: boolean;
  noResultsText: string;
  phone: boolean;
  phoneNumber: string;
  userId: string;
  meta?: {
    sessionId: string;
    webEnv: WebEnvironment;
    clubCode: string;
    user: {
      memberNumber: string;
      userId: string;
    };
  };
}

export interface BatteryQuoterState {
  button: InputState;
  make: Step;
  model: Step;
  year: Step;
  engine: Step;
  steps: ['make', 'model', 'year', 'engine'];
}

export interface Step {
  label: string;
  options: string[];
  selection: string;
  status: StepStatus;
}

export type StepStatus = 'init' | 'waiting' | 'processing' | 'ready';

enum InputState {
  INIT,
  WAITING,
  PROCESSING,
  READY,
}

enum InputEvent {
  INIT,
  MAKE,
  MODEL,
  YEAR,
  ENGINE,
  BUTTON,
}

enum ComponentState {
  INIT,
  FORM,
  RESULTS,
  NO_RESULTS,
}

export type StateEvent = 'init' | 'buttonClicked' | 'dataProcessed';

interface ClubDetails {
  clubId: string;
  imageUrl: string;
  noResultsHtml: string;
  requestBatteryButtonLogiformUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class BatteryQuoterService {
  postUrl: string;
  postOptions: {
    headers: HttpHeaders;
  };

  // States
  ComponentState = ComponentState;
  componentState: ComponentState | undefined;
  componentStateNext: ComponentState | undefined;
  componentStatePrevious: ComponentState | undefined;
  InputEvent = InputEvent;
  InputState = InputState;
  inputButtonState: InputState;

  selections: string[] = [];
  steps: string[] = ['make', 'model', 'year', 'engine'];
  labels: string[] = ['Make', 'Model', 'Year', 'Engine'];
  options: string[][] = [];
  inputStates: InputState[] = [];

  buttonClicked: boolean = false;
  dataProcessed: boolean = false;

  // Results
  vehicle: string = '';
  engineSize: string = '';
  partNumber: string = '';
  priceNonMember: string = '';
  priceMember: string = '';
  requestButtonUrl: string = '';

  clubDetailsArray: ClubDetails[] = [
    {
      clubId: '006',
      imageUrl: 'https://www.colorado.aaa.com/sites/default/files/cartoon_battery_calculator_360x150.jpg',
      noResultsHtml: '<p>Sorry, but we don’t have that battery in stock at the moment.</p>',
      requestBatteryButtonLogiformUrl: 'https://www.colorado.aaa.com/roadside-now',
    },
    {
      clubId: '023',
      imageUrl: 'https://www.hoosier.aaa.com/sites/default/files/battquote-360.png',
      noResultsHtml: '<p>Sorry, no battery was found for your vehicle.</p>',
      requestBatteryButtonLogiformUrl: 'https://www.aaa.com/services/automotive/rso/rsoHome.html',
    },
    {
      clubId: '238',
      imageUrl: 'https://www.southpa.aaa.com/sites/default/files/battquote-360.png',
      noResultsHtml: '<p>Sorry, no battery was found for your vehicle.</p>',
      requestBatteryButtonLogiformUrl: 'http://www.aaa.com/drrweb?namevalidate=y',
    },
    {
      clubId: '104',
      imageUrl: 'https://www.northway.aaa.com/sites/default/files/battquote-360.png',
      noResultsHtml: '<p>Sorry, no battery was found for your vehicle.</p>',
      requestBatteryButtonLogiformUrl: 'https://www.aaa.com/services/automotive/rso/rsoHome.html',
    },
    {
      clubId: '071',
      imageUrl: 'https://www.southjersey.aaa.com/sites/default/files/battquote-360.png',
      noResultsHtml: '<p>Sorry, no battery was found for your vehicle.</p>',
      requestBatteryButtonLogiformUrl: 'https://aaa.com/rsoweb',
    },
    {
      clubId: '057',
      imageUrl: 'https://minneapolis.aaa.com/sites/default/files/battquote-360_0.png',
      noResultsHtml: '<p>Sorry, no battery was found for your vehicle.</p>',
      requestBatteryButtonLogiformUrl: 'https://www.aaa.com/service',
    },
  ];

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private http: HttpClient,
    private batteryQuoterState: RxState<BatteryQuoterState>
  ) {
    batteryQuoterState.set({
      make: {
        label: 'Make',
        options: [],
        selection: '',
        status: 'waiting',
      },
      model: {
        label: 'Model',
        options: [],
        selection: '',
        status: 'waiting',
      },
      year: {
        label: 'Year',
        options: [],
        selection: '',
        status: 'waiting',
      },
      engine: {
        label: 'Engine',
        options: [],
        selection: '',
        status: 'waiting',
      },
    });
    this.inputButtonState = this.InputState.WAITING;
    this.componentState = this.ComponentState.FORM;

    this.postUrl = environment.cloudFunctionsURL + '/batteryQuoter';
    this.postOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }

  get clubDetails(): ClubDetails | undefined {
    return (
      this.clubDetailsArray.find(
        clubDetails => clubDetails.clubId === this.globalState.get('windowMetaData', 'clubId')
      ) || this.clubDetailsArray[4]
    ); // fallback to use SJ for initial testing wssv3-1063
  }

  get newItem(): BatteryQuoter {
    return {
      fieldType: FieldType.BATTERY_QUOTER,
    };
  }

  resetMake(): void {
    this.selections[0] = this.labels[0];
    this.options[0] = [this.selections[0]];
    this.resetModel();
    this.resetYear();
    this.resetEngine();
  }

  private resetModel() {
    this.selections[1] = this.labels[1];
    this.options[1] = [this.selections[1]];
    this.inputStates[1] = this.InputState.WAITING;
  }

  private resetYear() {
    this.selections[2] = this.labels[2];
    this.options[2] = [this.selections[2]];
    this.inputStates[2] = this.InputState.WAITING;
  }

  private resetEngine() {
    this.selections[3] = this.labels[3];
    this.options[3] = [this.selections[3]];
    this.inputStates[3] = this.InputState.WAITING;
  }

  private resetButtonState() {
    this.buttonClicked = false;
    this.dataProcessed = false;
    this.componentStateNext = undefined;
    this.componentStatePrevious = undefined;
    this.inputButtonState = this.InputState.WAITING;
  }

  getMakes(): void {
    this.resetModel();
    this.resetYear();
    this.resetEngine();
    this.resetButtonState();
    this.setInputState(this.InputEvent.MAKE, this.InputState.PROCESSING);
    /**
     * Using encrypted userId for the userId to the battery quote service.
     */
    const dataObject = {
      urlPath: 'GetMakes',
      args: [],
    };
    this.http.post(this.postUrl, dataObject, this.postOptions).subscribe(data => {
      const resultsArray: string[] = data as string[];
      resultsArray.map(result => {
        this.options[0].push(result);
      });
      this.setInputState(this.InputEvent.MAKE, this.InputState.READY);
      // this.setFocus(this.makeElement)
    });
  }

  processSelection(step: string, args: string[]): void {
    if (step === 'make') {
      this.getModels(args);
    }
    if (step === 'model') {
      this.getYears(args);
    }
    if (step === 'year') {
      this.getEngines(args);
    }
    if (step === 'engine') {
      this.getBatteries(args);
    }
  }

  getModels(options: string[]): void {
    const [make] = options;
    this.resetModel();
    this.resetYear();
    this.resetEngine();
    this.resetButtonState();
    this.setInputState(this.InputEvent.MODEL, this.InputState.PROCESSING);
    this.selections[0] = make;
    this.resetResults();
    /**
     * Using encrypted userId for the userId to the battery quote service.
     */
    const dataObject = {
      urlPath: 'GetModels',
      args: ['make=' + encodeURI(make)],
    };
    this.http.post(this.postUrl, dataObject, this.postOptions).subscribe(data => {
      const resultsArray: string[] = data as string[];
      resultsArray.map(result => {
        this.options[1].push(result);
        this.setInputState(this.InputEvent.MODEL, this.InputState.READY);
      });
    });
  }

  getYears(options: string[]): void {
    const [make, model] = options;
    this.resetYear();
    this.resetEngine();
    this.resetResults();
    this.resetButtonState();
    this.setInputState(this.InputEvent.YEAR, this.InputState.PROCESSING);
    this.selections[1] = model;
    /**
     * Using encrypted userId for the userId to the battery quote service.
     */
    const dataObject = {
      urlPath: 'GetYears',
      args: ['make=' + encodeURI(make), 'model=' + encodeURI(model)],
    };
    this.http.post(this.postUrl, dataObject, this.postOptions).subscribe(data => {
      const resultsArray: string[] = data as string[];
      resultsArray.map(result => {
        this.options[2].push(result);
        this.setInputState(this.InputEvent.YEAR, this.InputState.READY);
      });
    });
  }

  getEngines(options: string[]): void {
    const [make, model, year] = options;
    this.resetResults();
    this.resetEngine();
    this.resetButtonState();
    this.setInputState(this.InputEvent.ENGINE, this.InputState.PROCESSING);
    this.selections[2] = year;
    /**
     * Using encrypted userId for the userId to the battery quote service.
     */
    const dataObject = {
      urlPath: 'GetEngines',
      args: ['make=' + encodeURI(make), 'model=' + encodeURI(model), 'year=' + encodeURI(year)],
    };
    this.http.post(this.postUrl, dataObject, this.postOptions).subscribe(data => {
      const resultsArray: string[] = data as string[];
      resultsArray.map(result => {
        this.options[3].push(result);
        this.setInputState(this.InputEvent.ENGINE, this.InputState.READY);
      });
    });
  }

  getBatteries(options: string[]): void {
    const [make, model, year, engine] = options;
    this.resetButtonState();
    this.setInputState(this.InputEvent.BUTTON, this.InputState.READY);
    this.selections[3] = engine;
    /**
     * Using encrypted userId for the userId to the battery quote service.
     */
    const dataObject = {
      urlPath: 'GetBatteries_Standard',
      args: [
        'clubId=' + this.globalState.get('windowMetaData', 'clubId') || '071', // fallback to use SJ for initial testing wssv3-1063
        'zipCode=' + '0',
        'make=' + encodeURI(make),
        'model=' + encodeURI(model),
        'year=' + encodeURI(year),
        'engine=' + encodeURI(engine),
      ],
    };
    this.http.post(this.postUrl, dataObject, this.postOptions).subscribe(data => {
      if (data) {
        const resultsArray = data as BatteryQuoteResults[];
        this.processResults(resultsArray[0]);
        this.setInputState(this.InputEvent.BUTTON, this.InputState.READY);
      }
      if (!data) {
        this.setComponentState('dataProcessed', ComponentState.NO_RESULTS);
      }
    });
  }

  processResults(results: BatteryQuoteResults): void {
    if (results['PartName'] === null) {
      this.setComponentState('dataProcessed', ComponentState.NO_RESULTS);
      return;
    }
    this.vehicle = results.YearId + ' ' + results.MakeName + ' ' + results.ModelName;
    this.engineSize = results.Engine;
    this.partNumber = results.PartName + ' ' + results.CCA + ' cold cranking amps';
    this.priceNonMember = results.NonMemberPrice.toString();
    this.priceMember = results.MemberPrice.toString();
    const requestButtonUrlArgs = [
      'club=' + this.globalState.get('windowMetaData', 'clubId') || '071', // fallback to use SJ for initial testing wssv3-1063
      'year=' + results.YearId.toString(),
      'make=' + results.MakeName,
      'model=' + results.ModelName,
      'engine=' + results.Engine,
      'batterytype=' + results.PartName,
      'memprice=' + results.MemberPrice.toString(),
      'nonmemprice=' + results.NonMemberPrice.toString(),
    ];
    this.requestButtonUrl = this.clubDetails?.requestBatteryButtonLogiformUrl + '?' + requestButtonUrlArgs.join('&');
    this.setComponentState('dataProcessed', ComponentState.RESULTS);
  }

  resetResults(): void {
    this.vehicle = '';
    this.engineSize = '';
    this.partNumber = '';
    this.priceNonMember = '';
    this.priceMember = '';
    this.requestButtonUrl = '';
  }

  setComponentState(stateEvent: StateEvent, nextState: ComponentState | undefined, event?: Event): void {
    event?.preventDefault();

    if (nextState) {
      this.componentStateNext = nextState;
    }
    if (stateEvent === 'buttonClicked') {
      this.buttonClicked = true;
    }
    if (stateEvent === 'dataProcessed') {
      this.dataProcessed = true;
    }
    if (stateEvent === 'buttonClicked' && !nextState && this.inputButtonState !== this.InputState.WAITING) {
      // this.inputButtonState = this.InputState.PROCESSING
      this.setInputState(this.InputEvent.BUTTON, this.InputState.PROCESSING);
    }
    if (this.buttonClicked && this.dataProcessed) {
      this.componentStatePrevious = this.componentState;
      this.componentState = this.componentStateNext;
      this.inputButtonState = this.InputState.INIT;
    }
  }

  private setInputState(event: InputEvent, nextState: InputState) {
    if (event === this.InputEvent.MAKE) {
      this.inputStates[0] = nextState;
    }
    if (event === this.InputEvent.MODEL) {
      this.inputStates[1] = nextState;
    }
    if (event === this.InputEvent.YEAR) {
      this.inputStates[2] = nextState;
    }
    if (event === this.InputEvent.ENGINE) {
      this.inputStates[3] = nextState;
    }
    if (event === this.InputEvent.BUTTON) {
      this.inputButtonState = nextState;
    }
  }

  private setFocus(element: ElementRef) {
    setTimeout(() => element.nativeElement?.focus(), 0);
  }

  /*
    scrollIntoView() {
      this.window.document.getElementById('battery-quoter-component').scrollIntoView({ behavior: 'smooth' });
    }
  */

  get testParams(): BatteryQuoterInput {
    return {
      imageUrl: 'https://www.colorado.aaa.com/sites/default/files/cartoon_battery_calculator_360x150.jpg',
      labelForButton: 'Get Battery Quote',
      labelForButtonDisabled: 'All fields are required',
      endpoint: 'https://camfid.clubassist.com/api/InstaQuote',
      membership: false,
      name: false,
      phone: false,
      email: false,
      buttonUrl: 'https://www.aaa.com/services/automotive/rso/rsoHome.html',
      buttonCopy: 'Request Online',
      phoneNumber: '1-800-222-4357',
      noResultsText:
        '<p>Sorry, but we don\'t have that battery in stock at the moment.</p><p><a href="/automotive/mobile-battery">Start a new battery search.</a></p>',
      userId:
        'eyJjaXBoZXJ0ZXh0IjoiWjlFcHpxMVN5M2E5XC8yKzVNckVnK3c9PSIsIml2IjoiYzZjODhkYzZkNzk5ODNiZmZlOTg0ZDhkYTJjNTcxMjAiLCJzYWx0IjoiZGRmNjRhZGJjYTg4YzUyN2I5MDg2YzljMTA1MjcyNWI2MTU2ZTk4NTkwYzAxNWNkMWNmZDMzZjE0NzQ4Y2RmMmYzNTc5ZGIyNDRjNTUzZDgxZTFiNzNlNTI5YTI5OGRkMWMxMzc0YmM2NzQ4MjZlNDZmYTc4NDliYjk5YjhjOTkyODIxMDk5YTRjOGQwYTE0NzEyNzQyY2M0NTQ3ODRkNzZlNmNhYzliNzg5M2IwZWJhZTE5N2E1Nzc2NzQ3Y2JiODllYTQxMDI0NWQxMjA4MTVhYjRiOTc4ZGViNzllMzk2MTNiZTczNjVlMWJkOTc0MzQ3NTExNTliM2U5MmQ2MmY4N2IxNTgxNTAyOTNiMTdhNjljZGEwZTFkMzAyZWVhZmNhYjk5MjA1YmJkZDIxNDM4ZDQzZGM1NzFmYjMwNDk3MjVhNzE4MTMxYzRiYWUzMzdlZjYzNzNmYTM5ZTA0YjllNTJhZjBmYWViMWQyNzc1NDEwYmRiZjM3Njc5Mzc3ODkxZTQwNGMyYWNiMDhjYzc1M2YwNWE4MzhjM2QzNDE3MmE0MjhlNDcyZTMzZWM3OTRkNzMxMTEzNzUwYmY5MjQyNWEwZDlkNTBjYWRhODZlOTIyNDY4MzJkOWM3NzlmYjRjMmNhNTljNzBmMzE2MDZkNjA3ZGVmYzZhY2Q3MWQifQ==',
    };
  }
}
