import { Mzp } from './mzp.type';
import { getMemberStatus, MembershipAssociateDetails } from '@aaa/emember/store-types';
import { MzpMembershipLevel, MzpPhoneType } from '@aaa/interface-joinRenew-membership-membershipMzp';
import { MembershipOfferItem } from './price-offers/helpers/types';

export const getMzpPrimaryCustomerInfo = (memberInfo: Mzp.MemberInfo): Mzp.MemberLookupCustomer => {
  const primaryMemberList = memberInfo.membership.memberList.find(l => l.memberTypeCd === 'P');

  return memberInfo.customers.find(c => c.customerId === primaryMemberList?.customerId) as Mzp.MemberLookupCustomer;
};

export const getMzpCustomerPhone = (customerInfo: Mzp.MemberLookupCustomer, phoneType: MzpPhoneType) => {
  return (customerInfo?.customerPhoneList || []).find(phoneList => phoneList.phoneTypeCd === phoneType)?.phone || '';
};

export const getMzpMembershipCode = (
  membership: Mzp.MemberInfo['membership']
): {
  label: string;
  level: MzpMembershipLevel;
  rv: boolean;
  membershipType: string;
  duesComponentCode: string;
} | null => {
  const membershipCode = Mzp.membershipCodes.find(code => code.membershipType === membership.coverageLevelCd);

  if (membershipCode) {
    return {
      label: membershipCode.label,
      level: membershipCode.level,
      rv: membershipCode.rv,
      membershipType: membershipCode.membershipType,
      duesComponentCode: membershipCode.duesComponentCode,
    };
  }

  return null;
};

export const getMzpPrimaryMemberListInfo = (
  memberList: Mzp.MemberLookupInfoMemberList[]
): Mzp.MemberLookupInfoMemberList =>
  (memberList || []).find(item => item.memberTypeCd === 'P') as Mzp.MemberLookupInfoMemberList;

export const getMzpMemberListInfoById = (
  memberKy: number,
  memberList: Mzp.MemberLookupInfoMemberList[]
): Mzp.MemberLookupInfoMemberList =>
  (memberList || []).find(item => item.memberKy === memberKy) as Mzp.MemberLookupInfoMemberList;

export const getMzpBalanceTotal = ({ membership, balances }: Mzp.MemberInfo) => {
  const membershipTotal = balances.find(b => b.key === membership.membershipKy)?.amount || 0;
  const memberListTotal = membership.memberList.reduce((amount, memberList) => {
    const balance = balances.find(b => b.key === memberList.membershipKy && b.type === 'Member')?.amount || 0;

    return amount + balance;
  }, 0);

  return membershipTotal + memberListTotal;
};

export const getMzpMembershipPrimary = ({ customers, membership }: Mzp.MemberInfo): MembershipAssociateDetails => {
  const result = (membership.memberList || [])
    .filter(member => member.memberTypeCd == 'P')
    .map(member => {
      const custumer = customers.find(c => c.customerId === member.customerId);

      return {
        dob: '',
        email: (custumer?.customerEmailList && custumer?.customerEmailList[0]?.email) || '',
        firstName: custumer?.firstName || '',
        lastName: custumer?.lastName || '',
        membershipNumber: '438' + '238' + member.membershipId + '' + member.associateId + '' + member.checkDigitNr,
        middleIntial: custumer?.middleName || '',
        nameSuffix: '',
        removeAssociate: false,
        // Need to implement
        offerItems: [],
        status: member.status === 'C' ? 'canceled' : 'active',
      };
    });

  return result[0] as MembershipAssociateDetails;
};

export const getMzpMembershipAssociates = ({ customers, membership }: Mzp.MemberInfo): MembershipAssociateDetails[] => {
  return (membership.memberList || [])
    .filter(member => member.memberTypeCd !== 'P')
    .map(member => {
      const custumer = customers.find(c => c.customerId === member.customerId);

      return {
        dob: '',
        email: (custumer?.customerEmailList && custumer?.customerEmailList[0]?.email) || '',
        firstName: custumer?.firstName || '',
        lastName: custumer?.lastName || '',
        membershipNumber: '438' + '238' + member.membershipId + '' + member.associateId + '' + member.checkDigitNr,
        middleIntial: custumer?.middleName || '',
        nameSuffix: '',
        removeAssociate: false,
        // Need to implement
        offerItems: [],
        status: getMemberStatus(member.status),
      } as MembershipAssociateDetails;
    });
};

export function getPrimaryMember({ membership }: Mzp.MemberInfo) {
  return membership.memberList.filter(item => item.memberTypeCd === 'P')[0];
}

export function getAssociatesMember({ membership }: Mzp.MemberInfo) {
  return membership.memberList.filter(item => item.memberTypeCd === 'A');
}

export function getOptionalOffers(
  memberList: Mzp.MemberLookupInfoMemberList[],
  memberType: 'primary' | 'associate' = 'primary'
) {
  const offers: MembershipOfferItem[] = [];
  const offeringType = memberType === 'primary' ? 'optionalPrimary' : 'associateOptional';
  const existCodeInOffers = (offering: MembershipOfferItem['offering'], code: MembershipOfferItem['code']) => {
    return !!offers.find(o => o.code === code && o.offering === offering);
  };

  memberList.forEach((member, index) => {
    // fees
    const memberFeesList = member.membershipFeesList || [];
    memberFeesList.forEach((member, index) => {
      const memberFeeCode = member.feeType;
      const memberFeeAmount = member.feeAt;

      if (memberFeeCode === 'ENRL') {
        offers.push({
          offering: offeringType,
          code: 'enroll',
          description: 'One-Time Enrollment Fee',
          amount: memberFeeAmount,
          conditions: [],
          selectedByDefault: false,
        });
      }

      const discountHistoryList = member.discountHistoryList || [];
      discountHistoryList.forEach(discount => {
        const discountCode = discount.discountCd;
        const discountDescription = discount.discountCd === 'ARMS' ? 'Auto Renew' : discount.discountCd;

        offers.push({
          offering: offeringType,
          code: discountCode,
          amount: Number(discount.amount),
          conditions:
            memberType === 'associate' ? [{ apply: 'associateCount', value: index + 1, operator: 'equal' }] : [],
          selectedByDefault: true,
          description: discountDescription,
        });
      });
    });

    // additional
    const memberRiderList = member.riderList || [];
    memberRiderList.forEach(rider => {
      const riderCode = rider.riderCompCd;
      const riderAmount = rider.duesCostAt || 0;
      const discountHistoryList = rider.discountHistoryList || [];

      if (riderCode === 'MD' && !existCodeInOffers(offeringType, riderCode)) {
        offers.push({
          offering: offeringType,
          code: riderCode,
          amount: riderAmount,
          conditions: [{ apply: 'medicalRider', value: true, operator: 'equal' }],
          selectedByDefault: false,
          description: 'Medical Plan',
        });
      }

      if (riderCode === 'ARMS') {
        offers.push({
          offering: offeringType,
          code: riderCode,
          description: 'Auto Renew',
          amount: riderAmount,
          conditions: [{ apply: 'autorenew', value: true, operator: 'equal' }],
          selectedByDefault: false,
        });
      }

      discountHistoryList.forEach(discount => {
        const discountCode = discount.discountCd;
        const discountDescription = discount.discountCd === 'ARMS' ? 'Auto Renew' : discount.discountCd;

        offers.push({
          offering: offeringType,
          code: discountCode,
          amount: Number(discount.amount),
          conditions:
            memberType === 'associate' ? [{ apply: 'associateCount', value: index + 1, operator: 'equal' }] : [],
          selectedByDefault: true,
          description: discountDescription,
        });
      });
    });
  });

  return offers;
}
