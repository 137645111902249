import { Injectable } from '@angular/core';
import { delay, first, of, tap } from 'rxjs';

// This interface is optional, showing how you can add strong typings for custom globals.
// Just use "Window" as the type if you don't have custom global stuff
export interface ICustomDocument extends Document {}

function getDocument(): any {
  return document;
}

@Injectable({ providedIn: 'root' })
export class DocumentRefService {
  get nativeDocument(): ICustomDocument {
    return getDocument() as ICustomDocument;
  }

  scrollToId(id: string) {
    of(id)
      .pipe(
        first(),
        delay(50),
        tap(() => {
          const documentRef = this.nativeDocument.getElementById(id);

          if (documentRef) {
            documentRef.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          } else {
            console.log('not found id=', id);
          }
        })
      )
      .subscribe();
  }
}
