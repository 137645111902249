import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MembershipARActions } from './membership-auto-renew.actions';
import { of, withLatestFrom } from 'rxjs';
import { Operation, OperationExecutePayload } from '@aaa/interface-joinRenew-joinRenewLib';
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteOperationExecuteEventPayload,
  MembershipConnectSuiteRecostValidateChangeAREventPayload,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { AccountDetails } from '../types/types';
import { ConnectSuite } from '../connect-suite.type';
import { getMembershipNumber } from '@aaa/emember/store-membership';
import { getPayment } from '@aaa/emember/store-payment';
import { FormGroupValue } from '../../modules/share/form.utils';
import { PaymentForm } from '@aaa/emember/share/payment-form';
import { getMembershipAutoRenewFormValues } from './membership-auto-renew.selectors';
import { MembershipAutoRenewForm } from './membership-auto-renew.models';
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from '@aaa/interface-joinRenew-payment-paymentCybersource';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { getAccountDetails } from '@aaa/emember/store-account';
import { RequestError, RequestErrorType } from '../generic-errors';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { checkOperationErrorsConnectSuiteSystem } from '../check-operation-errors-connect-suite-system';
import { AnalyticsPurchaseEvent } from '../../../types/analytics-purchase-event';
import { DataLayerService } from '../../modules/share/services/data-layer.service';
import { AppAnalyticsEvents } from '../../../types/analytics-events';
import { Cybersource } from '../cybersource.type';
import { getTransactionId } from '../utils/get-transaction-id';

@Injectable({ providedIn: 'root' })
export class MembershipAutoRenewConnectSuitSystemEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Hoosier, ClubApp.SouthJersey]));
  executeService = inject(ExecuteService);
  dataLayer = inject(DataLayerService);

  removeCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipARActions.removeCard),
      withLatestFrom(this.store.select(getMembershipNumber)),
      switchMap(([, membershipNumber]) =>
        this.removeCard(membershipNumber).pipe(
          map(accountDetails => MembershipARActions.removeCardSucceeded({ accountDetails })),
          catchError(error => of(MembershipARActions.removeCardFailed({ error })))
        )
      )
    )
  );

  updateCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipARActions.updateCard),
      withLatestFrom(
        this.store.select(getMembershipNumber),
        this.store.select(getMembershipAutoRenewFormValues),
        this.store.select(getAccountDetails),
        this.store.select(getPayment)
      ),
      switchMap(([, membershipNumber, formValues, accountDetails, payment]) =>
        this.updateCard(membershipNumber, formValues, accountDetails, payment).pipe(
          map(accountDetails => MembershipARActions.updateCardSucceeded({ accountDetails })),
          catchError(error => of(MembershipARActions.updateCardFailed({ error })))
        )
      )
    )
  );

  addCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipARActions.addCard),
      withLatestFrom(
        this.store.select(getMembershipNumber),
        this.store.select(getMembershipAutoRenewFormValues),
        this.store.select(getAccountDetails),
        this.store.select(getPayment)
      ),
      switchMap(([, membershipNumber, formValues, accountDetails, payment]) =>
        this.addCard(membershipNumber, formValues, accountDetails, payment).pipe(
          map(accountDetails => MembershipARActions.addCardSucceeded({ accountDetails })),
          catchError(error => of(MembershipARActions.addCardFailed({ error })))
        )
      )
    )
  );

  updateCard(
    memberNumber: string,
    formValues: FormGroupValue<MembershipAutoRenewForm>,
    accountDetails: AccountDetails | null,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> }
  ) {
    return this.validateCard(memberNumber, true).pipe(
      switchMap(validateObject => this.save(validateObject, formValues, accountDetails, payment))
    );
  }

  addCard(
    memberNumber: string,
    formValues: FormGroupValue<MembershipAutoRenewForm>,
    accountDetails: AccountDetails | null,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> }
  ) {
    return this.validateCard(memberNumber, true).pipe(
      switchMap(validateObject => this.save(validateObject, formValues, accountDetails, payment))
    );
  }

  removeCard(memberNumber: string) {
    return this.validateCard(memberNumber, false).pipe(
      switchMap(validateObject => this.saveRemoveCard(validateObject))
    );
  }

  validateCard(memberNumber: string, autoRenew = false) {
    const payload: MembershipConnectSuiteRecostValidateChangeAREventPayload = {
      memberNumber,
      method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_AR,
      autoRenew,
    };

    return this.executeService.membershipQuery<ConnectSuite.MemberAutoRenewValidateResponseObject>(payload).pipe(
      map(validateObject => {
        if (validateObject.meta?.isError) {
          throw new RequestError(RequestErrorType.AutoRenewMembership, validateObject);
        }

        return validateObject;
      })
    );
  }

  save(
    validateObject: ConnectSuite.MemberAutoRenewValidateResponseObject,
    formValues: FormGroupValue<MembershipAutoRenewForm>,
    accountDetails: AccountDetails | null,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> }
  ) {
    const membershipEvent: MembershipConnectSuiteOperationExecuteEventPayload = {
        cacheSettings: { cacheType: 'clear' },
        operation: Operation.UPDATE,
        method: MembershipConnectSuiteMethod.OPERATION_EXECUTE,
        executionData: validateObject.response.executionData,
      },
      paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
        executionData: {
          flexMicroFormToken: payment.token,
          billTo: {
            address1: String(formValues.billing?.billingTo?.address1),
            address2: String(formValues.billing?.billingTo?.address2),
            administrativeArea: String(formValues.billing?.billingTo?.state),
            buildingNumber: '',
            country: 'US',
            district: String(formValues.billing?.billingTo?.state),
            email: String(accountDetails?.email || 'fallback@avagate.com'),
            firstName: formValues.billing?.billingTo?.firstName || '',
            lastName: formValues.billing?.billingTo?.lastName || '',
            locality: String(formValues.billing?.billingTo?.city),
            phoneNumber: String(accountDetails?.phone.cell),
            postalCode: String(accountDetails?.address.zip),
          },
          amountDetails: {
            totalAmount: '0',
            currency: 'USD',
          },
          creditCardBrandedName: payment.formValues.card?.cardName || '',
        },
        operation: Operation.UPDATE,
        method: PaymentCybersourceMethod.OPERATION_EXECUTE,
      },
      payload: OperationExecutePayload = {
        membershipEvent,
        operation: Operation.UPDATE,
        paymentEvent,
      };

    return this.executeService
      .execute<ConnectSuite.MemberAutoRenewResponseObject, Cybersource.ExecutePaymentResponseObject>(payload)
      .pipe(
        map(({ validateObject, paymentObject, operationObject }) => {
          if ((validateObject && validateObject?.meta.isError) || !validateObject) {
            throw new RequestError(RequestErrorType.AutoRenewMembership, validateObject);
          }

          const operationError = !!operationObject?.meta?.isError;
          if (operationError) {
            checkOperationErrorsConnectSuiteSystem(operationObject.error, operationObject);
          }

          const analyticsEventParams: AnalyticsPurchaseEvent['eventParams'] = {
            currency: 'USD',
            transaction_id: getTransactionId(paymentObject),
            value: 0,
            items: [{ quantity: 1, price: 0, item_id: 'primary', item_name: AppAnalyticsEvents.AccountAutoRenewal }],
            context: 'ava-store ' + AppAnalyticsEvents.AccountAutoRenewal,
          };
          this.dataLayer.purchaseEvent(analyticsEventParams);

          const accountDetails = new ConnectSuite.AccountInfo(validateObject.response.response);

          return accountDetails;
        })
      );
  }

  saveRemoveCard(validateObject: ConnectSuite.MemberAutoRenewValidateResponseObject) {
    const membershipEvent: MembershipConnectSuiteOperationExecuteEventPayload = {
      cacheSettings: { cacheType: 'clear' },
      operation: Operation.UPDATE,
      method: MembershipConnectSuiteMethod.OPERATION_EXECUTE,
      executionData: validateObject.response.executionData,
    };
    const payload: OperationExecutePayload = {
      membershipEvent,
      operation: Operation.UPDATE,
    };

    return this.executeService.execute<ConnectSuite.MemberAutoRenewResponseObject>(payload).pipe(
      map(({ validateObject, operationObject }) => {
        if ((validateObject && validateObject?.meta.isError) || !validateObject) {
          throw new RequestError(RequestErrorType.RemoveAutoRenewMembership, validateObject);
        }

        const operationError = !!operationObject?.meta?.isError;
        if (operationError) {
          checkOperationErrorsConnectSuiteSystem(operationObject.error, operationObject);
        }

        const accountDetails = new ConnectSuite.AccountInfo(validateObject.response.response);

        return accountDetails;
      })
    );
  }
}
