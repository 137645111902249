import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SessionState } from './session.reducer';
import { SessionDocObject } from './session.models';
import { RequestStatus } from '../../../types/request-status';

export const SESSION_FEATURE_KEY = 'session';

export const getSessionState = createFeatureSelector<SessionState>(SESSION_FEATURE_KEY);
export const getSessionId = createSelector(getSessionState, ({ sessionId }) => sessionId);
export const isSessionInitialized = createSelector(
  getSessionState,
  ({ initialize }) => initialize === RequestStatus.SUCCESS
);
export const getMembershipSessionDocs = createSelector(
  getSessionState,
  ({ sessionDocs }) => sessionDocs?.responses?.membership
);
export const getPaymentSessionDocs = createSelector(
  getSessionState,
  ({ sessionDocs }) => sessionDocs?.responses?.payment
);
export const getOperationSessionDocs = createSelector(
  getSessionState,
  ({ sessionDocs }) => sessionDocs?.responses?.operation || { defaultOperation: {} }
);
export const getMembershipDocById = <T = SessionDocObject>(sessionId: string | number) =>
  createSelector(getMembershipSessionDocs, (membershipDocs): T | null => {
    if (membershipDocs && membershipDocs.connectsuite) {
      return membershipDocs.connectsuite[sessionId] as T;
    }
    if (membershipDocs && membershipDocs.mzp) {
      return membershipDocs.mzp[sessionId] as T;
    }

    if (membershipDocs && membershipDocs.m) {
      return membershipDocs.m[sessionId] as T;
    }

    return null;
  });

export const getPaymentDocById = <T = SessionDocObject>(sessionId: string | number) =>
  createSelector(getPaymentSessionDocs, (payments): T | null => {
    if (payments && payments.cybersource) {
      return payments.cybersource[sessionId] as T;
    }

    // need to implement other payments

    return null;
  });

export const getDefaultOperationDocById = <T = SessionDocObject>(sessionId: string | number) =>
  createSelector(getOperationSessionDocs, (operation): T | null => {
    if (operation && operation.defaultOperation) {
      return operation.defaultOperation[sessionId] as T;
    }

    // need to implement other operation

    return null;
  });

export const getPaymentById = <T = SessionDocObject>(sessionId: string | number) =>
  createSelector(getPaymentDocById(sessionId), getDefaultOperationDocById(sessionId), (payment, operation) => {
    if ((payment && !operation) || payment) {
      return payment as T;
    }

    if (operation && !payment) {
      return operation as T;
    }

    return null;
  });

export const getHttpInterceptHeaders = createSelector(getSessionState, ({ sessionId, idTokenResult }) => ({
  sessionId,
  idTokenResult,
}));
