import { MembershipOfferSummary } from '../price-offers/helpers/types';

export enum FeeTypes {
  Enroll = 'enroll',
  AssociateEnroll = 'associateEnroll',
  Primary = 'primary',
  AssociateAccidentMedicalPlan = 'associateAccidentMedicalPlan',
  PrimaryAccidentMedicalPlan = 'primaryAccidentMedicalPlan',
}

export enum DiscountTypes {
  AutoRenewDiscount = 'autoRenewDiscount',
  PromotionalCode = 'promotionalCode',
  Offering = 'offering',
}

export type AccountInfoSummary = {
  label: string;
  value: number;
};

export type PriceItem = {
  type: FeeTypes | DiscountTypes;
  label: string;
  code: string;
  value: number;
  active: boolean;
};

export type SummaryState = {
  offers: MembershipOfferSummary;
  priceItems: PriceItem[];
  accounts: AccountInfoSummary[];
  totalCost: number;
};
