import { Component, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { FormBaseControlComponent } from './base';

@Component({
  selector: 'ava-form-password',
  template: `
    <nz-form-item [ngClass]="{ 'ava-label-hide': !formLabel }">
      <nz-form-label class="ava-form-label" [nzFor]="controlId" [nzSpan]="7">
        {{ formLabel || placeholder }}
      </nz-form-label>
      <nz-form-control [nzValidateStatus]="formControlStatus" [nzErrorTip]="errors" nzHasFeedback>
        <nz-input-group [nzSuffix]="suffixTpl">
          <input
            [formControl]="formControl"
            [placeholder]="placeholder"
            [type]="visible ? 'text' : 'password'"
            [attr.id]="controlId"
            nz-input
            class="ava-form-control"
          />
        </nz-input-group>

        <ng-template #suffixTpl>
          Password &nbsp;
          <span
            nz-icon
            [nzType]="!visible ? 'eye-invisible' : 'eye'"
            (click)="visible = !visible"
            nz-tooltip
            [nzTooltipTitle]="visible ? 'Hide password' : 'Show password'"
          ></span>
        </ng-template>
        <ng-template #errors let-control>
          <ng-container *ngIf="formControl">
            <ng-container *ngIf="(formControl.errors | keyvalue)[0] as error">
              <div
                class="ava-form-error"
                [innerHTML]="errorMessages && errorMessages[error.key] ? errorMessages[error.key] : error.key"
              ></div>
            </ng-container>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      @import '../../../../styles/ava-responsive';

      :host {
        font-weight: 200;
        font-family: var(--ava-font-family);

        span {
          cursor: pointer;
        }

        input::placeholder,
        ::ng-deep .ant-input-suffix {
          @include formControlPlaceholderStyle();
        }

        .ava-label-hide nz-form-label {
          width: 0;
          overflow: hidden;
          position: absolute;
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormPasswordComponent),
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    ReactiveFormsModule,
    NzIconModule,
    NzToolTipModule,
  ],
  standalone: true,
})
export class FormPasswordComponent extends FormBaseControlComponent {
  visible = false;
}
