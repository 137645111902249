import checkExistMembershipLevel from '../guards/check-exist-membership-level';
import { Routes } from '@angular/router';
import { EmemberComponent } from '../emember.component';
import setPageMembershipOffer from '../guards/set-page-membership-offer';
import setPageJoin from '../guards/set-page-join';
import defaultRouting from '../guards/default-routing.northway';
import { environment } from '../../../../environments/environment';
import setPageTitle from '../guards/set-page-title';

export const routes: Routes = [
  {
    path: 'join',
    loadChildren: () => import('../../join/join.module').then(m => m.JoinModule),
    canActivate: [setPageTitle, checkExistMembershipLevel, setPageJoin('join')],
  },
  {
    path: 'gift',
    loadChildren: () => import('../../join/join.module').then(m => m.JoinModule),
    canActivate: [setPageTitle, setPageJoin('gift')],
  },
  {
    path: 'renew',
    loadChildren: () => import('../../quick-renew/quick-renew.module').then(m => m.QuickRenewModule),
    canActivate: [setPageTitle],
  },
  {
    path: 'offers',
    loadChildren: () => import('../../membership-offers/membership-offers.module').then(m => m.MembershipOffersModule),
    canActivate: [setPageTitle, setPageMembershipOffer('offers')],
  },
  {
    path: 'gift-offers',
    loadChildren: () => import('../../membership-offers/membership-offers.module').then(m => m.MembershipOffersModule),
    canActivate: [setPageTitle, setPageMembershipOffer('gift-offers')],
  },
  {
    path: 'contact',
    loadComponent: () => import('../../contact/contact.component').then(c => c.ContactComponent),
    canActivate: [setPageTitle],
  },
  {
    path: '',
    component: EmemberComponent,
    ...(!environment.ngServe ? [defaultRouting] : []),
  },
];
