import { MembershipCode } from '../../../store/types/types';
import { MMembershipCode, MMembershipType } from '@aaa/interface-joinRenew-membership-membershipM';
import { M } from '../../../store/m.type';

export const shelbyMembershipCodes: MembershipCode<
  M.MMembershipLevel,
  MMembershipType,
  MMembershipCode,
  M.MMembershipLabel
>[] = M.membershipCodes.filter(membershipCode =>
  [MMembershipType.Basic, MMembershipType.Plus, MMembershipType.PlusRv].includes(membershipCode.membershipType)
);

export const northamptonMembershipCodes: MembershipCode<
  M.MMembershipLevel,
  MMembershipType,
  MMembershipCode,
  M.MMembershipLabel
>[] = M.membershipCodes;
