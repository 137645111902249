import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MembershipARActions } from './membership-auto-renew.actions';
import { bufferCount, map, switchMap, tap } from 'rxjs/operators';
import { concat, first, of, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { getMembershipNumber, MembershipActions } from '@aaa/emember/store-membership';
import { PaymentActions } from '../payment/payment.actions';
import { MembershipAutoRenewFormStep } from './membership-auto-renew.models';
import { getMembershipAutoRenewFormActiveStep } from './membership-auto-renew.selectors';

@Injectable({ providedIn: 'root' })
export class MembershipAutoRenewEffects {
  actions$ = inject(Actions);
  store = inject(Store);
  router = inject(Router);

  validateFormStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipARActions.validateFormStep),
      withLatestFrom(this.store.select(getMembershipAutoRenewFormActiveStep)),
      switchMap(([{ validateStep }, currentActiveStep]) => {
        const validateUpdateCard = currentActiveStep === MembershipAutoRenewFormStep.UPDATE_CARD;
        const validateAddCard = currentActiveStep === MembershipAutoRenewFormStep.ADD_CARD;

        if (validateUpdateCard) {
          const validatePayment$ = of(null).pipe(
              tap(() => this.store.dispatch(PaymentActions.validate())),
              switchMap(() => this.actions$.pipe(ofType(PaymentActions.validateSucceeded), first()))
            ),
            validateGenerateToken$ = of(null).pipe(
              tap(() => this.store.dispatch(PaymentActions.generateToken())),
              switchMap(() => this.actions$.pipe(ofType(PaymentActions.generateTokenSucceeded), first()))
            ),
            validateUpdateCard$ = of(null).pipe(
              tap(() => this.store.dispatch(MembershipARActions.updateCard())),
              switchMap(() => this.actions$.pipe(ofType(MembershipARActions.updateCardSucceeded), first()))
            );
          const validators = [validatePayment$, validateGenerateToken$, validateUpdateCard$];

          return concat(...validators).pipe(
            bufferCount(validators.length),
            map(() => MembershipARActions.setFormActiveStep({ activeStep: validateStep }))
          );
        }

        if (validateAddCard) {
          const validatePayment$ = of(null).pipe(
              tap(() => this.store.dispatch(PaymentActions.validate())),
              switchMap(() => this.actions$.pipe(ofType(PaymentActions.validateSucceeded), first()))
            ),
            validateGenerateToken$ = of(null).pipe(
              tap(() => this.store.dispatch(PaymentActions.generateToken())),
              switchMap(() => this.actions$.pipe(ofType(PaymentActions.generateTokenSucceeded), first()))
            ),
            validateAddCard$ = of(null).pipe(
              tap(() => this.store.dispatch(MembershipARActions.addCard())),
              switchMap(() => this.actions$.pipe(ofType(MembershipARActions.addCardSucceeded), first()))
            ),
            validators = [validatePayment$, validateGenerateToken$, validateAddCard$];

          return concat(...validators).pipe(
            bufferCount(validators.length),
            map(() => MembershipARActions.setFormActiveStep({ activeStep: validateStep }))
          );
        }

        return of(MembershipARActions.setFormActiveStep({ activeStep: validateStep }));
      })
    )
  );

  updateMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MembershipARActions.removeCardSucceeded,
        MembershipARActions.updateCardSucceeded,
        MembershipARActions.addCardSucceeded
      ),
      switchMap(accountDetails => of(accountDetails).pipe(withLatestFrom(this.store.select(getMembershipNumber)))),
      map(([{ accountDetails }, membershipNumber]) => {
        if (accountDetails) {
          return MembershipActions.updateAccountDetails({ accountDetails });
        }

        return MembershipActions.load({ membershipNumber: String(membershipNumber) });
      })
    )
  );

  goToViewAR$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MembershipARActions.updateCardSucceeded, MembershipARActions.addCardSucceeded),
        tap(() => this.router.navigate(['account', 'membership', 'auto-renew', 'view']))
      ),
    { dispatch: false }
  );

  goToAddAR$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MembershipARActions.removeCardSucceeded),
        tap(() => this.router.navigate(['account', 'membership', 'auto-renew', 'add']))
      ),
    { dispatch: false }
  );
}
