import { Component, Input, OnInit } from '@angular/core';
import { StateMachineService } from '../../../services/state-machine.service';
import { FormService } from '../../../services/form.service';
import { environment } from '../../../../../../environments/environment';
import { AppMode, FormValues, Meeting } from '@aaa/interface-agentScheduler';

@Component({
  selector: 'ava-meeting',
  templateUrl: './meeting.component.html',
})
export class MeetingComponent implements OnInit {
  @Input() formId: string | undefined;
  formValue: FormValues | undefined;
  buttonLabel: string = 'Next';
  meetings: Meeting[] = [];
  formAgentValue: any | undefined;

  constructor(
    public sms: StateMachineService,
    public formService: FormService
  ) {}

  ngOnInit(): void {
    if (this.formId) {
      this.formValue = this.formService.formValues[this.formId];
      this.formAgentValue = this.formService.form[this.formId]?.get(['agent'])?.value;
    }
    if (this.formValue) {
      this.formValue.selectedMeetingId = '';
      if (this.formValue.appMode === AppMode.TRAVEL) {
        this.buttonLabel = 'FIND A TRAVEL AGENT';
      }
      if (this.formValue.appMode === AppMode.INSURANCE) {
        this.buttonLabel = 'FIND INSURANCE AGENT';
      }

      if (environment.ngServe) {
        // this.sms.sendEvent('NEXT')
      }

      if (this.formAgentValue) {
        /**
         * filter meetings options
         * show only meetings options that the selected agent supports.
         */
        this.meetings = this.formValue.meetings.filter(meeting =>
          this.formAgentValue.meetingIds.some((meetingId: string) => meetingId === meeting.id)
        );

        /**
         * If selectedMeeting is not populated then select the first meeting.id with meeting.default:true
         * this is kinda hacky....but
         *    look for default meeting in the filtered list of meetings,
         *    if not found, then use the first one from the filtered list.
         */
        if (!this.formValue.selectedMeetingId) {
          this.formValue.selectedMeetingId = this.meetings.find(meeting => !!meeting.default)?.id || '';
        }
        if (!this.formValue.selectedMeetingId) {
          this.formValue.selectedMeetingId = this.meetings[0].id;
        }
      }
      if (!this.formAgentValue) {
        /**
         * load all meetings options
         */
        this.meetings = this.formValue.meetings;
        /**
         * If selectedMeeting is not populated then select the first meeting.id with meeting.default:true
         */
        if (!this.formValue.selectedMeetingId) {
          this.formValue.selectedMeetingId = this.formValue.meetings.find(meeting => !!meeting.default)?.id || '';
        }
      }
    }
  }
}
