import { createReducer, on } from '@ngrx/store';
import { MembershipAssociateAddActions } from './membership-associate-add.actions';
import { MembershipAssociateAddState, MembershipAssociateAddStep } from './membership-associate-add.models';
import { RequestStatus } from '../../../types/request-status';

export const initialStateMembershipAssociateAdd: MembershipAssociateAddState = {
  formActiveStep: MembershipAssociateAddStep.ASSOCIATE,
  formValues: {
    associates: [],
    billing: {},
    promoCode: '',
  },
  validationRequestStatus: RequestStatus.UNSENT,
  validationError: null,
  executionData: null,
  executionRequestStatus: RequestStatus.UNSENT,
  executionError: null,
  accountDetails: null,
};

export const membershipAssociateAddReducer = createReducer(
  initialStateMembershipAssociateAdd,
  on(MembershipAssociateAddActions.setFormActiveStep, (state, { activeStep }) => ({
    ...state,
    formActiveStep: activeStep,
  })),
  on(MembershipAssociateAddActions.changedForm, (state, { values }) => ({
    ...state,
    formValues: {
      ...state.formValues,
      ...values,
    },
  })),
  on(MembershipAssociateAddActions.retryRecostValidate, state => ({
    ...state,
    validationRequestStatus: RequestStatus.RUNNING,
  })),
  on(MembershipAssociateAddActions.recostValidate, MembershipAssociateAddActions.validatePromoCode, state => ({
    ...state,
    validationError: null,
    validationRequestStatus: RequestStatus.RUNNING,
  })),
  on(MembershipAssociateAddActions.recostValidateFailed, (state, { error }) => ({
    ...state,
    executionData: '',
    validationError: error,
    validationRequestStatus: RequestStatus.FAILED,
  })),
  on(MembershipAssociateAddActions.recostValidateSucceeded, (state, { executionData }) => ({
    ...state,
    executionData,
    validationRequestStatus: RequestStatus.SUCCESS,
  })),

  on(MembershipAssociateAddActions.pay, state => ({
    ...state,
    executionError: null,
    executionRequestStatus: RequestStatus.RUNNING,
  })),
  on(MembershipAssociateAddActions.payFailed, (state, { error }) => ({
    ...state,
    executionError: error,
    executionRequestStatus: RequestStatus.FAILED,
  })),
  on(MembershipAssociateAddActions.paySucceeded, state => ({
    ...state,
    executionRequestStatus: RequestStatus.SUCCESS,
  })),
  on(MembershipAssociateAddActions.setAccountDetails, (state, { accountDetails }) => ({ ...state, accountDetails })),
  on(MembershipAssociateAddActions.reset, () => ({ ...initialStateMembershipAssociateAdd }))
);
