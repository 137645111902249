import { createReducer, on } from '@ngrx/store';
import { MembershipRenewState, MembershipRenewFormStep } from './membership-renew.models';
import { MembershipRenewActions } from './membership-renew.actions';
import { RequestStatus } from '../../../types/request-status';

export const initialStateMembershipRenew: MembershipRenewState = {
  formActiveStep: MembershipRenewFormStep.PAYMENT,
  formValues: {},
  validationRequestStatus: RequestStatus.UNSENT,
  validationError: null,
  executionData: null,
  executionRequestStatus: RequestStatus.UNSENT,
  executionError: null,
  accountDetails: null,
};

export const membershipRenewReducer = createReducer(
  initialStateMembershipRenew,
  on(MembershipRenewActions.recostValidate, state => ({
    ...state,
    validationRequestStatus: RequestStatus.RUNNING,
    validationError: null,
    executionData: null,
  })),
  on(MembershipRenewActions.recostValidateSucceeded, (state, { executionData }) => ({
    ...state,
    executionData,
    validationError: null,
    validationRequestStatus: RequestStatus.SUCCESS,
  })),
  on(MembershipRenewActions.recostValidateFailed, (state, { error }) => ({
    ...state,
    validationError: error,
    validationRequestStatus: RequestStatus.FAILED,
    executionData: '',
  })),
  on(MembershipRenewActions.pay, state => ({
    ...state,
    executionRequestStatus: RequestStatus.RUNNING,
    executionError: null,
  })),
  on(MembershipRenewActions.paySucceeded, state => ({
    ...state,
    executionError: null,
    executionRequestStatus: RequestStatus.SUCCESS,
  })),
  on(MembershipRenewActions.payFailed, (state, { error }) => ({
    ...state,
    executionError: error,
    executionRequestStatus: RequestStatus.FAILED,
  })),
  on(MembershipRenewActions.setAccountDetails, (state, { accountDetails }) => ({ ...state, accountDetails })),
  on(MembershipRenewActions.setFormActiveStep, (state, { activeStep }) => ({ ...state, formActiveStep: activeStep })),
  on(MembershipRenewActions.changedForm, (state, { values }) => ({ ...state, formValues: values })),
  on(MembershipRenewActions.removeError, state => ({ ...state, validationError: null, executionError: null })),
  on(MembershipRenewActions.reset, () => initialStateMembershipRenew)
);
