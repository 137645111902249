import { Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormBaseControlComponent } from './base';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';

@Component({
  selector: 'ava-form-radio',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRadioComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, NzFormModule, NzGridModule, ReactiveFormsModule, NzRadioModule, NzSelectModule],
  standalone: true,
  template: `
    <span class="ava-form-label" *ngIf="formLabel">{{ formLabel }}</span>
    <nz-radio-group [formControl]="formControl">
      <label *ngFor="let option of options" nz-radio [nzValue]="option[valueKey]" class="ava-form-radio-control">
        <span class="ava-radio-label">{{ option[displayLabel] }}</span>
      </label>
    </nz-radio-group>
    <ng-template #errors let-control>
      <ng-container *ngIf="control?.errors">
        <ng-container *ngIf="(control.errors | keyvalue)[0] as error">
          <div
            class="ava-form-error"
            [innerHTML]="errorMessages && errorMessages[error.key] ? errorMessages[error.key] : error.key"
          ></div>
        </ng-container>
      </ng-container>
    </ng-template>
  `,
  styles: [
    `
      @import '../../../../styles/ava-responsive';

      :host {
        font-weight: 200;
        font-family: var(--ava-font-family);
        display: flex;
        gap: 1rem;
        align-items: center;

        @include screenTabletDown {
          flex-direction: column;
          align-items: flex-start;
        }

        ::ng-deep {
          .ant-radio-inner {
            border-color: var(--btn-primary-color);
          }
          .ant-radio-inner::after {
            background-color: var(--btn-primary-color);
          }

          label {
            font-weight: 400;
          }

          .ant-radio-wrapper:hover .ant-radio,
          .ant-radio:hover .ant-radio-inner,
          .ant-radio-input:focus + .ant-radio-inner {
            border-color: var(--btn-primary-color);
          }
          .ant-radio-checked::after {
            border-color: var(--btn-primary-color);
          }
        }
      }
    `,
  ],
})
export class FormRadioComponent extends FormBaseControlComponent {
  @Input() displayLabel = 'label';
  @Input() valueKey = 'value';
  @Input() options = [];
}
