import { Injectable } from '@angular/core';
import { Block } from '../../block/services/block';

export interface Template {
  category: string;
  description: string;
  icon: string;
  name: string;
  type: TemplateType | null;
  weight: number;
}

/*
export interface TemplateTypeDeprecated {
  label: string
  templates: Block[]
  type?: TemplateOption
}
*/

export enum TemplateType {
  NONE = 'NONE',
  BANNER = 'BANNER',
  BANNER_LARGE = 'BANNER_LARGE',
  BLOCK_REFERENCE = 'BLOCK_REFERENCE',
  CALL_TO_ACTION = 'CALL_TO_ACTION',
  CARD = 'CARD',
  COLUMN = 'COLUMN',
  FEATURE = 'FEATURE',
  FEATURE_WITH_LOGIFORM = 'FEATURE_WITH_LOGIFORM',
  FEATURE_DESTINATION = 'FEATURE_DESTINATION',
  FEATURE_MAGAZINE_ARTICLES_REFERENCE = 'FEATURE_MAGAZINE_ARTICLES_REFERENCE',
  FOUR_COLUMN_PRODUCT = 'FOUR_COLUMN_PRODUCT',
  FOUR_COLUMN_WITH_IMAGE = 'FOUR_COLUMN_WITH_IMAGE',
  GALLERY = 'GALLERY',
  ICON_CARD = 'ICON_CARD',
  IMAGE_STACK = 'IMAGE_STACK',
  LOGIFORM = 'LOGIFORM',
  MULTI_WIDGET = 'MULTI_WIDGET',
  MULTI_WIDGET_TABS = 'MULTI_WIDGET_TABS',
  ONE_COLUMN_TEXT = 'ONE_COLUMN_TEXT',
  ONE_COLUMN_TEXT_WITH_BUTTON = 'ONE_COLUMN_TEXT_WITH_BUTTON',
  PROMOTION_OR_OFFER = 'PROMOTION_OR_OFFER',
  THREE_COLUMN_LIST_WITH_ICONS = 'THREE_COLUMN_LIST_WITH_ICONS',
  TITLE = 'TITLE',
  TWO_OR_THREE_COLUMN_TEXT = 'TWO_OR_THREE_COLUMN_TEXT',
  GIFT = 'GIFT',
  JOIN = 'JOIN',
  QUICK_RENEW = 'QUICK_RENEW',
  JOIN_BANNER = 'JOIN_BANNER',
}

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  /**
   * Subscribe to templates.
   * Always populate block and revision options from template options.
   * Sync template options into block and revision options.
   */

  get newItem(): Template {
    return {
      category: '',
      description: '',
      icon: '',
      name: '',
      type: null,
      weight: 0,
    };
  }

  templateLabel(templateType: TemplateType): string {
    return this.templateLabels.find(template => template.type === templateType)?.label || '';
  }

  get templateLabels(): { label: string; type: TemplateType }[] {
    return [
      { label: '(none)', type: TemplateType.NONE },
      { label: 'Gift', type: TemplateType.GIFT },
      { label: 'Join', type: TemplateType.JOIN },
      { label: 'Quick Renew', type: TemplateType.QUICK_RENEW },
      { label: 'Join Banner', type: TemplateType.JOIN_BANNER },
      { label: 'Banner', type: TemplateType.BANNER },
      { label: 'Large Banner', type: TemplateType.BANNER_LARGE },
      { label: 'Block Reference', type: TemplateType.BLOCK_REFERENCE },
      { label: 'Call to Action', type: TemplateType.CALL_TO_ACTION },
      { label: 'Card', type: TemplateType.CARD },
      { label: 'Column', type: TemplateType.COLUMN },
      { label: 'Feature', type: TemplateType.FEATURE },
      { label: 'Feature with Logiform', type: TemplateType.FEATURE_WITH_LOGIFORM },
      { label: 'Feature Destinations', type: TemplateType.FEATURE_DESTINATION },
      { label: 'Feature Magazine Articles Reference', type: TemplateType.FEATURE_MAGAZINE_ARTICLES_REFERENCE },
      { label: 'Four Column Product', type: TemplateType.FOUR_COLUMN_PRODUCT },
      { label: 'Four Column with Image', type: TemplateType.FOUR_COLUMN_WITH_IMAGE },
      { label: 'Gallery', type: TemplateType.GALLERY },
      { label: 'Icon Card', type: TemplateType.ICON_CARD },
      { label: 'Image Stack', type: TemplateType.IMAGE_STACK },
      { label: 'Logiforms', type: TemplateType.LOGIFORM },
      { label: 'Multi Widget', type: TemplateType.MULTI_WIDGET },
      { label: 'Multi Widget Tabs', type: TemplateType.MULTI_WIDGET_TABS },
      { label: 'One Column Text', type: TemplateType.ONE_COLUMN_TEXT },
      { label: 'One Column Text with Button', type: TemplateType.ONE_COLUMN_TEXT_WITH_BUTTON },
      { label: 'Promotion / Offer', type: TemplateType.PROMOTION_OR_OFFER },
      { label: 'Three Column List (with Icons)', type: TemplateType.THREE_COLUMN_LIST_WITH_ICONS },
      { label: 'Title', type: TemplateType.TITLE },
      { label: 'Two or Three Column Text', type: TemplateType.TWO_OR_THREE_COLUMN_TEXT },
    ];
  }

  /**
   * the order of this list also defines the order in which they appear in the Type list and the Create Panel
   */
  /*
  get templateTypes(): TemplateDeprecatedTypes {
    return {
      none: {
        label: "(none)",
        templates: [],
      },
      banner: {
        label: "Banner",
        templates: [],
      },
      bannerLarge: {
        label: "Large Banner",
        templates: [],
      },
      blockReference: {
        label: "Block Reference",
        templates: [],
      },
      callToAction: {
        label: "Call to Action",
        templates: [],
      },
      card: {
        label: "Card",
        templates: [],
      },
      column: {
        label: "Column",
        templates: [],
      },
      feature: {
        label: "Feature",
        templates: [],
      },
      featureWithLogiform: {
        label: "Feature with Logiform",
        templates: [],
      },
      featureDestination: {
        label: "Feature Destinations",
        templates: [],
      },
      featureMagazineArticlesReference: {
        label: "Feature Magazine Articles Reference",
        templates: [],
      },
      fourColumnProduct: {
        label: "Four Column Product",
        templates: [],
      },
      formColumnWithImage: {
        label: "Four Column with Image",
        templates: [],
      },
      gallery: {
        label: "Gallery",
        templates: [],
      },
      iconCard: {
        label: "Icon Card",
        templates: [],
      },
      imageStack: {
        label: "Image Stack",
        templates: [],
      },
      logiform: {
        label: "Logiforms",
        templates: [],
      },
      multiWidget: {
        label: "Multi Widget",
        templates: [],
      },
      multiWidgetTabs: {
        label: "Multi Widget Tabs",
        templates: [],
      },
      oneColumnText: {
        label: "One Column Text",
        templates: [],
      },
      oneColumnTextWithButton: {
        label: "One Column Text with Button",
        templates: [],
      },
      promotionOrOffer: {
        label: "Promotion / offer",
        templates: [],
      },
      threeColumnListWithIcons: {
        label: "Three Column List (with Icons)",
        templates: [],
      },
      title: {
        label: "Title",
        templates: [],
      },
      twoOrThreeColumnText: {
        label: "Two or Three Column Text",
        templates: [],
      },
      joinRenew: {
        label: "Join/Renew",
        templates: [],
      },
    }
  }
*/
}
