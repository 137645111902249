import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { Flow, HOOSIER_RX_STATE, HoosierService, HoosierState } from '../../hoosier.service';
import { SmartystreetsService } from '../../../../../services/smartystreets';
import { debounceTime, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RxEffects } from '@rx-angular/state/effects';
import { PaymentFormOptionsService } from '../../form-field-options/payment';
import { InputConfigSuggestion } from '../../form-field-options/options-interfaces';
import { GLOBAL_RX_STATE, GlobalState } from '../../../../../services/state';

@Component({
  selector: 'ava-hoosier-billing-info',
  templateUrl: './billing-info.html',
})
export class BillingInfoComponent implements OnInit {
  hoosierState$ = this.hoosierState.select();
  form: FormGroup | undefined;
  useMemberInfoForBillingFormControl: FormControl | undefined;
  options = this.paymentFormOptionsService.options;
  address1Length: number | undefined;
  Flow = Flow;

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private hoosierService: HoosierService,
    private paymentFormOptionsService: PaymentFormOptionsService,
    private smartystreetsService: SmartystreetsService
  ) {}

  get addressAutocomplete$(): Observable<any | undefined> {
    const address1Form = this.form?.get(['paymentPayload', 'executionData', 'billTo', 'address1']);
    if (address1Form) {
      return address1Form.valueChanges.pipe(
        debounceTime(1000),
        tap(address1 => {
          if (address1.length !== this.address1Length && address1.length > 2) {
            const websiteKey = this.globalState.get('environment', 'SMARTY_WEBSITE_KEY');
            if (websiteKey && !!this.options.address1.config.addressAutocompleteOptions) {
              this.smartystreetsService
                .usAutocompleteLookup(address1, ['IN'], [], websiteKey)
                .then(data => {
                  console.log(data.result);
                  this.options.address1.config.addressAutocompleteOptions =
                    data.result.map(result => {
                      const inputConfigSuggestion = result as InputConfigSuggestion;
                      inputConfigSuggestion.text = [
                        result.streetLine,
                        result.secondary,
                        result.city,
                        result.state,
                        result.zipcode,
                      ].join(' ');
                      return inputConfigSuggestion;
                    }) || [];
                  if (data.result.length === 1) {
                    this.form
                      ?.get(['paymentPayload', 'executionData', 'billTo', 'address2'])
                      ?.setValue(data.result[0].secondary);
                    this.form
                      ?.get(['paymentPayload', 'executionData', 'billTo', 'locality'])
                      ?.setValue(data.result[0].city);
                    this.form
                      ?.get(['paymentPayload', 'executionData', 'billTo', 'administrativeArea'])
                      ?.setValue(data.result[0].state);
                    this.form
                      ?.get(['paymentPayload', 'executionData', 'billTo', 'district'])
                      ?.setValue(data.result[0].state);
                    this.form
                      ?.get(['paymentPayload', 'executionData', 'billTo', 'postalCode'])
                      ?.setValue(data.result[0].zipcode);
                  }
                })
                .catch(error => {
                  console.log(error);
                });
            }
          }
        })
      );
    }
    return of(undefined);
  }

  ngOnInit(): void {
    this.form = this.hoosierService.form;
    this.rxEffects.register(this.addressAutocomplete$);
    this.useMemberInfoForBillingFormControl = this.form.get(['useMemberInfoForBilling']) as FormControl;
  }
}
