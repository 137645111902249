import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { AccountDetails } from '../types/types';
import { of, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MembershipAssociateAddActions } from './membership-associate-add.actions';
import {
  getMembershipAssociateAddAccountDetails,
  getMembershipAssociateExecutionData,
  getMembershipAssociateFormValues,
  MembershipAssociateAddAccount,
} from './membership-associate-add.selectors';
import { FormGroupValue } from '../../modules/share/form.utils';
import {
  LastNameSuffix,
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteOperationExecuteEventPayload,
  MembershipConnectSuiteRecostValidateChangeAssociatesEventPayload,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { parseDateToApi } from '../utils/parse-date-to-api';
import { getResponseKey } from '../utils/get-response-key';
import { MembershipAssociateAddForm } from './membership-associate-add.models';
import { getMembershipNumber } from '@aaa/emember/store-membership';
import { ConnectSuite } from '../connect-suite.type';
import { Operation, OperationExecutePayload } from '@aaa/interface-joinRenew-joinRenewLib';
import { getPayment, getPaymentAutoRenewValue } from '@aaa/emember/store-payment';
import { PaymentForm } from '@aaa/emember/share/payment-form';
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from '@aaa/interface-joinRenew-payment-paymentCybersource';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { RequestError, RequestErrorType } from '../generic-errors';
import { checkOperationErrorsConnectSuiteSystem } from '../check-operation-errors-connect-suite-system';
import { getClearCacheSettings } from '../utils/get-cache-settings';
import { AnalyticsPurchaseEvent } from '../../../types/analytics-purchase-event';
import { DataLayerService } from '../../modules/share/services/data-layer.service';
import { AppAnalyticsEvents } from '../../../types/analytics-events';
import { Cybersource } from '../cybersource.type';
import { getTransactionId } from '../utils/get-transaction-id';

@Injectable({ providedIn: 'root' })
export class MembershipAssociateAddConnectSuitSystemEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Hoosier, ClubApp.SouthJersey]));
  executeService = inject(ExecuteService);
  dataLayer = inject(DataLayerService);

  updateFeesAndDiscountsAfterValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.recostValidateSucceeded),
      map(data => {
        const response = data?.response as ConnectSuite.MembershipAssociateRecostValidationResponseObject['response'];
        const accountDetails = new ConnectSuite.AccountInfo(response?.validationData);

        // Todo: need to find a solution to set totalCost inside of accountDetails
        accountDetails.balance = Number(response?.validationData.attributes.totalCost);

        return MembershipAssociateAddActions.setAccountDetails({ accountDetails });
      })
    )
  );

  recostValidateJoin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.recostValidate, MembershipAssociateAddActions.validatePromoCode),
      withLatestFrom(
        this.store.select(getMembershipAssociateFormValues),
        this.store.select(getMembershipNumber),
        this.store.select(getPaymentAutoRenewValue)
      ),
      switchMap(([, formValues, membershipNumber, autoRenew]) =>
        this.recostValidation(formValues, membershipNumber, autoRenew).pipe(
          map(res => MembershipAssociateAddActions.recostValidateSucceeded(res)),
          catchError(error => of(MembershipAssociateAddActions.recostValidateFailed({ error })))
        )
      )
    )
  );

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.pay),
      withLatestFrom(
        this.store.select(getMembershipAssociateAddAccountDetails),
        this.store.select(getMembershipAssociateFormValues),
        this.store.select(getMembershipAssociateExecutionData),
        this.store.select(MembershipAssociateAddAccount.getBalance),
        this.store.select(getPayment)
      ),
      switchMap(([, accountDetails, formValues, executionData, totalCost, payment]) =>
        this.pay(accountDetails, formValues, executionData, totalCost, payment).pipe(
          map(() => MembershipAssociateAddActions.paySucceeded({})),
          catchError(error => of(MembershipAssociateAddActions.payFailed({ error })))
        )
      )
    )
  );

  pay(
    accountDetails: AccountDetails | null,
    formValues: Partial<FormGroupValue<MembershipAssociateAddForm>>,
    executionData: string,
    totalCost: number,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> }
  ) {
    const responseKey = getResponseKey();
    const membershipEvent: MembershipConnectSuiteOperationExecuteEventPayload = {
      responseKey,
      operation: Operation.UPDATE,
      cacheSettings: getClearCacheSettings(),
      executionData,
      method: MembershipConnectSuiteMethod.OPERATION_EXECUTE,
    };
    const paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(formValues.billing?.billingTo?.address1),
          address2: String(formValues.billing?.billingTo?.address2),
          administrativeArea: String(formValues.billing?.billingTo?.state),
          buildingNumber: '',
          country: 'US',
          district: String(formValues.billing?.billingTo?.state),
          email: String(accountDetails?.email || 'fallback@avagate.com'),
          firstName: formValues.billing?.billingTo?.firstName || '',
          lastName: formValues.billing?.billingTo?.lastName || '',
          locality: String(formValues.billing?.billingTo?.city),
          phoneNumber: String(accountDetails?.phone.cell),
          postalCode: String(accountDetails?.address.zip),
        },
        amountDetails: {
          totalAmount: String(totalCost),
          currency: 'USD',
        },
        creditCardBrandedName: payment.formValues.card?.cardName || '',
      },
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
      responseKey: responseKey,
    };
    const payload: OperationExecutePayload = {
      membershipEvent,
      paymentEvent,
      operation: Operation.UPDATE,
      responseKey,
    };

    return this.executeService
      .execute<ConnectSuite.ValidateAssociatesResponseObject, Cybersource.ExecutePaymentResponseObject>(payload)
      .pipe(
        map(({ validateObject, operationObject, paymentObject }) => {
          if (validateObject?.meta.isError) {
            throw new RequestError(RequestErrorType.AddAssociate, validateObject);
          }

          const operationError = !!operationObject?.meta?.isError;
          if (operationError) {
            checkOperationErrorsConnectSuiteSystem(operationObject.error, operationObject);
          }

          const analyticsEventParams: AnalyticsPurchaseEvent['eventParams'] = {
            currency: 'USD',
            transaction_id: getTransactionId(paymentObject),
            value: totalCost,
            items: [
              { quantity: 1, price: totalCost, item_id: 'primary', item_name: AppAnalyticsEvents.AccountAddAssociates },
            ],
            context: 'ava-store ' + AppAnalyticsEvents.AccountAddAssociates,
          };
          this.dataLayer.purchaseEvent(analyticsEventParams);

          return validateObject;
        })
      );
  }

  recostValidation(
    formValues: Partial<FormGroupValue<MembershipAssociateAddForm>>,
    membershipNumber: string | null,
    autoRenew: boolean
  ) {
    const payload: MembershipConnectSuiteRecostValidateChangeAssociatesEventPayload = {
      method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_ASSOCIATES,
      associates:
        formValues.associates?.map(associate => {
          return {
            membershipNumber: '',
            email: associate.email,
            dob: parseDateToApi(associate.birthday),
            lastName: associate.lastName || '',
            firstName: associate.firstName || '',
            removeAssociate: false,
            nameSuffix: associate.suffix as LastNameSuffix,
          };
        }) || [],
      associateCount: formValues.associates?.length || 0,
      autoRenew: autoRenew,
      promoCode: formValues.promoCode || '',
      memberNumber: membershipNumber || '',
    };

    return this.executeService
      .membershipQuery<ConnectSuite.MembershipAssociateRecostValidationResponseObject>(payload)
      .pipe(
        map(validateObject => {
          return {
            response: validateObject.response,
            executionData: validateObject.response.executionData,
          };
        })
      );
  }
}
