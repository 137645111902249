import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ValidatorsActions } from './validators.actions';
import { of, switchMap, withLatestFrom } from 'rxjs';
import { getClubId } from '@aaa/emember/store-membership';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { WindowRefService } from '../../modules/share/services/window-ref.service';
import { ClubApp } from '@aaa/emember/types';

@Injectable({ providedIn: 'root' })
export class ValidatorsEffects {
  store = inject(Store);
  actions$ = inject(Actions);
  http = inject(HttpClient);
  windowRef = inject(WindowRefService);

  zipCodeInClub$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValidatorsActions.zipCodeInClub),
      switchMap(action => of(action).pipe(withLatestFrom(this.store.select(getClubId)))),
      switchMap(([{ zipcode }, clubId]) => {
        // Todo: this is temp solution
        if (zipcode.length !== 5 || environment.ngServe) {
          if (zipcode === '11111') {
            return of(ValidatorsActions.zipCodeInClubSucceeded());
          }
        }

        let params = new HttpParams();
        params = params.set('zipCode', zipcode);

        return this.http.get(`${environment.cloudFunctionsURL}/geoPostal`, { params }).pipe(
          map((data: any) => {
            const clubs = data?.services?.geo?.postal?.clubs?.club;
            if (Array.isArray(clubs)) {
              const zipcodeIsNotInsideClub = clubs.find(club => club?.code === clubId);

              if (zipcodeIsNotInsideClub) {
                return ValidatorsActions.zipCodeInClubSucceeded();
              }
            }

            return ValidatorsActions.zipCodeInClubFailed({ zipcodeIsNotInsideClub: true });
          }),
          catchError(() => of(ValidatorsActions.zipCodeInClubFailed({ zipcodeIsNotInsideClub: true })))
        );
      })
    )
  );

  existExistInClub$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValidatorsActions.emailExistInClub),
      switchMap(action => of(action).pipe(withLatestFrom(this.store.select(getClubId)))),
      switchMap(([{ email }, clubId]) => {
        if (clubId !== ClubApp.Hoosier) {
          return of(ValidatorsActions.emailExistInClubSucceeded());
        }

        // Only for hoosier checking email
        // Todo: this is temp solution
        if (!email.length || environment.ngServe) {
          if (email === 'test@test.com') {
            return of(ValidatorsActions.emailExistInClubFailed({ emailExists: true }));
          }

          return of(ValidatorsActions.emailExistInClubSucceeded());
        }
        let headers = new HttpHeaders();
        const servicesTokenCSRF = this.windowRef.nativeWindow?.metaData?.servicesTokenCSRF || '';

        if (servicesTokenCSRF) {
          headers = headers.append('X-CSRF-TOKEN', servicesTokenCSRF);
        }

        return this.http.post('/api/v1/emember/email_exists', { email }, { responseType: 'text', headers }).pipe(
          map((data: any) => {
            const response = data.replace('---\n', '').replace('\n', '');
            if (response === 'exists: false') {
              return ValidatorsActions.emailExistInClubSucceeded();
            }
            if (response === 'exists: true') {
              return ValidatorsActions.emailExistInClubFailed({ emailExists: true });
            }

            return ValidatorsActions.emailExistInClubFailed({ unableToVerify: true });
          }),
          catchError(() => of(ValidatorsActions.emailExistInClubFailed({ lookupFailed: true })))
        );
      })
    )
  );
}
