import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { getZipcodeFormErrors } from '../../../../store/form-messages';
import { Store } from '@ngrx/store';
import { AppStore } from '@aaa/emember/store-types';
import {
  getZipCodeInClubFailed,
  getZipCodeInClubIsLoading,
  getZipCodeInClubSuccess,
  ValidatorsActions,
} from '@aaa/emember/store-validators';
import { ValidatorService } from '../../../share/form/validators/validator.service';
import { filter, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';
import { getClubId, getClubOption, getZipcodeFormDescription } from '@aaa/emember/store-membership';
import { CookiesService } from '../../../share/services/cookies.service';
import { ClubApp } from '@aaa/emember/types';

@Component({
  selector: 'ava-zipcode-form',
  template: `
    <div *ngIf="clubOption$ | async as clubOption" class="ava-flex ava-column ava-gap-2">
      <div class="ava-flex ava-row">
        <p class="ava-header-bold ava-test-color--primary">
          Welcome to AAA {{ clubOption.name }} {{ clubOption.clubId !== ClubApp.Northway ? 'County' : '' }}
        </p>
        <img
          width="40%"
          src="https://www.hoosier.aaa.com/sites/all/themes/aaav3/images/aaa-logo.png"
          alt="Welcome to AAA {{ clubOption.name }} {{ clubOption.clubId !== ClubApp.Northway ? 'County' : '' }}"
        />
      </div>

      <p class="ava-flex ava-row ava-text-bold">To enjoy customized local content, please enter your ZIP Code below.</p>

      <form [formGroup]="formGroup">
        <ng-container *ngIf="errorMessages$ | async as errorMessages">
          <div class="ava-flex ava-row ava-gap-2">
            <ava-form-input
              [errorMessages]="errorMessages.zipcode"
              beforeLabel="Zip Code"
              placeholder="zipcode"
              formControlName="zipcode"
              class="ava-flex"
            />
            <ava-button
              type="primary"
              label="Go"
              [isLoading]="isLoading$ | async"
              [disabled]="formGroup.invalid"
              (clicked)="submitForm()"
            />
          </div>
          <ng-container *ngIf="failed$ | async">
            <div class="zipcode-control__error" [innerHTML]="errorMessages.zipcode.zipcodeIsNotInsideClub"></div>
          </ng-container>
        </ng-container>
      </form>

      <ava-divider />

      <p [innerHTML]="zipcodeFormDescription$ | async" class="ava-text"></p>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        padding: 1rem;
        background: white;

        .zipcode-control {
          &__error {
            color: red;
          }
        }
      }
    `,
  ],
})
export class ZipcodeFormComponent implements OnInit, OnDestroy {
  readonly ClubApp = ClubApp;
  store = inject(Store<AppStore>);
  fb = inject(FormBuilder);
  validators = inject(ValidatorService);
  cookies = inject(CookiesService);
  alive$ = new Subject();
  formGroup = this.initFormGroup();
  clubOption$ = this.store.select(getClubOption);
  zipcodeFormDescription$ = this.store.select(getZipcodeFormDescription);
  errorMessages$ = this.store.select(getZipcodeFormErrors);
  isLoading$ = this.store.select(getZipCodeInClubIsLoading);
  success$ = this.store.select(getZipCodeInClubSuccess);
  failed$ = this.store.select(getZipCodeInClubFailed);

  ngOnInit() {
    this.success$
      .pipe(
        withLatestFrom(this.store.select(getClubId)),
        filter(() => !!this.formGroup.value.zipcode),
        tap(([, clubId]) => this.cookies.setZipCode(String(this.formGroup.value.zipcode), clubId)),
        takeUntil(this.alive$)
      )
      .subscribe();
  }

  submitForm() {
    const { zipcode = '' } = this.formGroup.value;

    if (zipcode) {
      this.store.dispatch(ValidatorsActions.zipCodeInClub({ zipcode }));
    }
  }

  initFormGroup() {
    return this.fb.group(
      {
        zipcode: new FormControl('', {
          nonNullable: true,
          validators: [this.validators.zipCodeFormat()],
        }),
      },
      { updateOn: 'blur' }
    );
  }

  ngOnDestroy() {
    this.alive$.next(null);
    this.alive$.complete();
  }
}
