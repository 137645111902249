import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { PriceOffersActions } from './price-offers.actions';
import {
  MembershipMzpMethod,
  MembershipMzpPricePreviewsEventPayload,
  MembershipMzpPricePreviewsResponse,
} from '@aaa/interface-joinRenew-membership-membershipMzp';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { MembershipLevelOffer, MembershipsLevel } from './price-offers.model';
import { catchError, map } from 'rxjs/operators';
import { RequestError, RequestErrorType } from '../generic-errors';
import { Mzp } from '../mzp.type';
import { MembershipOfferItem } from './helpers/types';
import { normalizeNameLevel } from './helpers/normalize-name-level';
import { getTowMiles } from './helpers/get-tow-miles';
import { getSaveCacheSettings } from '../utils/get-cache-settings';

@Injectable({ providedIn: 'root' })
export class PriceOffersMzpSystemEffects {
  store = inject(Store);
  executeService = inject(ExecuteService);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.MidStates]));

  loadPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceOffersActions.load),
      switchMap(({ query }) => {
        const requestPayload: MembershipMzpPricePreviewsEventPayload = {
          cacheSettings: getSaveCacheSettings(),
          method: MembershipMzpMethod.PRICE_PREVIEWS,
          zipcode: query.zipCode || '',
          promoData: {
            promoCode: query.promoCode || '',
            couponCode: query.couponCode || '',
            programCode: query.programCode || '',
          },
        };
        const priceWithoutPromoPayload: MembershipMzpPricePreviewsEventPayload = {
          cacheSettings: getSaveCacheSettings(),
          method: MembershipMzpMethod.PRICE_PREVIEWS,
          zipcode: query.zipCode || '',
          promoData: {
            promoCode: '',
            couponCode: '',
            programCode: '',
          },
        };

        return forkJoin({
          discount: this.executeService.membershipQuery<Mzp.PricePreviewsResponseObject>(requestPayload),
          withoutDiscount:
            this.executeService.membershipQuery<Mzp.PricePreviewsResponseObject>(priceWithoutPromoPayload),
        }).pipe(
          map(({ discount, withoutDiscount }) => {
            let error: RequestError | null = null;

            if (discount.error && withoutDiscount.error) {
              return PriceOffersActions.loadFailed({ error: new RequestError(RequestErrorType.PricePreviewError) });
            }

            if (discount.error?.length) {
              const errorInfo = discount.error[0];

              if (String(errorInfo.error[0]).startsWith('Unable to locate zip code')) {
                error = new RequestError(RequestErrorType.PricePreviewInvalidZipCodeError);
              }

              // Todo: check api for more types of errors
              error = new RequestError(RequestErrorType.PricePreviewError);
            }

            if (error) {
              const levelOffers = this.getLevelOffers(withoutDiscount.response),
                levels = this.getLevels(withoutDiscount.response);

              return PriceOffersActions.loadFailed({ error, levelOffers, levels });
            } else {
              const levelOffers = this.getLevelOffers(discount.response),
                rawLevelOffers = this.getLevelOffers(withoutDiscount.response),
                levels = this.getLevels(discount.response);

              return PriceOffersActions.loadSucceeded({ levelOffers, levels, rawLevelOffers });
            }
          }),
          catchError(() =>
            of(PriceOffersActions.loadFailed({ error: new RequestError(RequestErrorType.PricePreviewError) }))
          )
        );
      })
    )
  );

  getLevels(offerings: MembershipMzpPricePreviewsResponse): MembershipsLevel[] {
    return offerings.prices.map(p => {
      const name = normalizeNameLevel(p.level);

      return {
        membershipType: p.code,
        rv: p.rv,
        level: p.level,
        name: name,
        towMiles: getTowMiles(ClubApp.MidStates, p.level),
      };
    });
  }

  getLevelOffers(offerings: MembershipMzpPricePreviewsResponse): MembershipLevelOffer {
    const primaryMedicalPrice = offerings.riders?.find(item => item.memberType === 'primary');
    const associateMedicalPrice = offerings.riders?.find(item => item.memberType === 'associate');

    return offerings.prices.reduce((acc, price) => {
      // if (code) {
      const membershipOfferComponents: MembershipOfferItem[] = [];
      //   "associateFee": 39,
      if (price.primaryFee) {
        membershipOfferComponents.push({
          offering: 'primary',
          code: 'primaryFee',
          description: `${normalizeNameLevel(price.level)} Plan`,
          amount: Number(price.primaryFee),
          conditions: [],
          selectedByDefault: true,
        });
      }

      if (price.enrollmentFee) {
        membershipOfferComponents.push({
          offering: 'optionalPrimary',
          code: 'enroll',
          description: 'One-Time Enrollment Fee',
          amount: Number(price.enrollmentFee),
          conditions: [],
          selectedByDefault: false,
        });
      }

      if (price.associateFee) {
        membershipOfferComponents.push({
          offering: 'associate',
          code: 'associateFee',
          description: `Associate Members`,
          amount: Number(price.associateFee),
          conditions: [],
          selectedByDefault: false,
        });
      }
      // Set default rules
      // Rule for associate medical price
      membershipOfferComponents.push({
        offering: 'associateOptional',
        code: associateMedicalPrice?.code || 'MD',
        description: associateMedicalPrice?.decription || 'Medical Plan',
        amount: associateMedicalPrice?.price || 0,
        conditions: [{ apply: 'medicalRider', value: true, operator: 'equal' }],
        selectedByDefault: false,
      });
      // Rule for primary medical price
      membershipOfferComponents.push({
        offering: 'optionalPrimary',
        code: primaryMedicalPrice?.code || 'MD',
        description: primaryMedicalPrice?.decription || 'Medical Plan',
        amount: primaryMedicalPrice?.price || 0,
        conditions: [{ apply: 'medicalRider', value: true, operator: 'equal' }],
        selectedByDefault: false,
      });

      acc[price.code] = membershipOfferComponents;

      return acc;
    }, {} as MembershipLevelOffer);
  }
}
