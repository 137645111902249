import { Component, Inject, OnInit } from '@angular/core';
import { LOCATION } from '@ng-web-apis/common';

@Component({
  selector: 'ava-hidden-title',
  template: `
    <h1 *ngIf="title" style="display: none">{{ title }}</h1>
  `,
})
export class HiddenTitleComponent implements OnInit {
  title: string = '';
  options: {
    description: string;
    paths: string[];
    search: string;
    title: string;
  }[] = [];

  constructor(
    @Inject(LOCATION)
    private location: Location
  ) {
    this.options = [
      {
        description:
          'Search for your local AAA Insurance Agent for your auto, home & life insurance needs. Get free insurance quotes online or find an insurance agent near you.',
        paths: ['/search/agent'],
        search: '?f%5B%5D=field_agent_type%3A1475',
        title: 'AAA Insurance Agents',
      },
      {
        description:
          'AAA Travel Agents are ready to help you start the planning process for your next vacation. Get tips on when and where to book for the biggest savings.',
        paths: ['/search/agent'],
        search: '?f%5B0%5D=field_agent_type%3A1476&f%5B1%5D=field_agent_type%3A2904',
        title: 'AAA Travel Agents',
      },
      {
        description:
          'Use our custom search to find AAA locations and filter by services offered such as travel, insurance, auto repair and more. Find a AAA office near you.',
        paths: ['/locations', '/search/office'],
        search: '',
        title: 'AAA Office Locations',
      },
      {
        description: 'Search for your local AAA Car Buying Agent to help you save time and money.',
        paths: ['/search/agent'],
        search: '?f%5B0%5D=field_agent_type%3A1473',
        title: 'AAA Car Buying Agents',
      },
      {
        description:
          'Local AAA Agents are here to take care of all your insurance, travel, and automotive needs to help you save time and money.',
        paths: ['/search/agent'],
        search: '',
        title: 'AAA Agent Search',
      },
    ];
  }

  ngOnInit(): void {
    this.title =
      this.options.find(option => {
        return (
          option.paths.find(path => {
            return path === this.location.pathname.toLowerCase();
          }) && option.search === this.location.search.toLowerCase()
        );
      })?.title || '';
  }
}
