import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModule } from '@angular/common';
import { FormInputComponent } from '../form/controls/input';
import { FormSelectComponent } from '../form/controls/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { getPaymentCardMonths, getPaymentCardYears, PaymentForm, PaymentFormVm } from './payment-form.vm';
import { LetModule } from '@ngrx/component';
import { FormCheckBoxComponent } from '../form/controls/checkbox';
import { ButtonComponent } from '../ava-button/ava-button.component';
import { Store } from '@ngrx/store';
import {
  getPaymentAutoRenewValue,
  getPaymentFormValues,
  getPaymentInitializedSucceeded,
  getPaymentStatus,
  getSaveARMessage,
  PaymentActions,
  PaymentInitializeStatus,
  PaymentStatus,
  PaymentType,
} from '@aaa/emember/store-payment';
import { CardIconPipe } from '../pipes/card-icon.pipe';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ScriptsService } from '../../../services/scripts';
import { tap } from 'rxjs/operators';
import { filter, first, from, of, Subject, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { ConnectFormDirective } from '../form/directives/connect-form.directive';
import { FormGroupValue } from '../form.utils';
import { getPaymentFormErrors } from '../../../store/form-messages';
import { AvaIconComponent } from '../ava-icon/ava-icon.component';
import { setFormControlError } from '../form/validators/ser-form-control-errros';
import { AppStore } from '@aaa/emember/store-types';
import { CybersourcePaymentService } from '@aaa/emember/store-services';
import { disableFormControl, enableFormControl } from '../utils/form-control';
import { Shift4PaymentService } from '../../../store/services/shift4-payment.service';

@Component({
  selector: 'ava-payment-form',
  templateUrl: './payment-form.html',
  styleUrls: ['./payment-form.scss'],
  imports: [
    FormInputComponent,
    FormSelectComponent,
    ReactiveFormsModule,
    NzIconModule,
    CommonModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzSkeletonModule,
    NzSelectModule,
    FormsModule,
    LetModule,
    FormCheckBoxComponent,
    ButtonComponent,
    CardIconPipe,
    NzToolTipModule,
    ConnectFormDirective,
    AvaIconComponent,
  ],
  standalone: true,
})
export class PaymentFormComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges {
  store = inject(Store<AppStore>);
  paymentFormVm = inject(PaymentFormVm);
  scriptsService = inject(ScriptsService);
  cybersourcePayment = inject(CybersourcePaymentService);
  shift4Payment = inject(Shift4PaymentService);
  @Input() disableAutoRenew = false;
  @Input() autoRenewInfo = '';
  @Input() showAutoRenew = true;
  @Input() showRequireMessage = false;
  @Input() paymentType: PaymentType = '';
  @Output() autoRenewChanged = new EventEmitter<boolean>();

  alive$ = new Subject();
  formGroup = this.paymentFormVm.formGroup;
  errorMessages$ = this.store.select(getPaymentFormErrors);
  formValues$ = this.store.select(getPaymentFormValues);
  autoRenew$ = this.store.select(getPaymentAutoRenewValue);
  saveARMessage$ = this.store.select(getSaveARMessage);

  readonly months = getPaymentCardMonths();
  readonly years = getPaymentCardYears();

  get cardFormGroup() {
    return this.formGroup.controls.card;
  }

  get cardNumberForm() {
    return this.cardFormGroup.controls.number;
  }

  get cardCvvForm() {
    return this.cardFormGroup.controls.cvv;
  }

  get cardMonthForm() {
    return this.cardFormGroup.controls.month;
  }

  get showInvalidCardNumber() {
    return this.cardNumberForm.touched && this.cardNumberForm?.errors;
  }

  get showInvalidCardCvv() {
    return this.cardCvvForm.touched && this.cardCvvForm?.errors;
  }

  get showInvalidCardExpiration() {
    return this.cardCvvForm.touched && this.cardCvvForm?.errors;
  }

  get formGroupCardTouched() {
    return (
      this.cardFormGroup.invalid &&
      this.cardFormGroup.controls.number.touched &&
      this.cardFormGroup.controls.cvv.touched &&
      this.cardFormGroup.controls.month.touched &&
      this.cardFormGroup.controls.year.touched
    );
  }

  ngOnInit() {
    this.markAsUnTouched();
    this.store
      .select(getPaymentInitializedSucceeded)
      .pipe(
        filter(({ isReady }) => isReady),
        tap(({ paymentType, session, initializedStatus }) =>
          this.paymentInitialized(paymentType, session, initializedStatus)
        ),
        takeUntil(this.alive$)
      )
      .subscribe();

    this.formGroup.controls.autoRenew.valueChanges
      .pipe(
        filter(() => this.showRequireMessage),
        tap(value => this.store.dispatch(PaymentActions.changedAutoRenew({ value }))),
        takeUntil(this.alive$)
      )
      .subscribe();

    this.autoRenew$
      .pipe(
        switchMap(autoRenew => of(autoRenew).pipe(withLatestFrom(this.store.select(getPaymentStatus)))),
        filter(([, status]) => status !== PaymentStatus.EMPTY),
        tap(([value]) => this.autoRenewChanged.emit(value)),
        takeUntil(this.alive$)
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.initPaymentResource(this.paymentType)
      .pipe(
        first(),
        tap(() => this.store.dispatch(PaymentActions.initCardForm({ paymentType: this.paymentType }))),
        tap(() => this.store.dispatch(PaymentActions.changedForm({ values: this.formGroup.value }))),
        takeUntil(this.alive$)
      )
      .subscribe();
  }

  formGroupValuesChanged(values: FormGroupValue<PaymentForm>) {
    this.store.dispatch(PaymentActions.changedForm({ values }));
  }

  paymentInitialized(paymentType: PaymentType, session: string, initializedStatus: PaymentInitializeStatus) {
    const success = initializedStatus === PaymentInitializeStatus.SUCCESS;
    const failed = initializedStatus === PaymentInitializeStatus.FAILED;

    if (success) {
      if (paymentType === 'cybersource') {
        this.initCyberSourcePaymentType(session);
      }

      if (paymentType === 'shift4') {
        this.initShift4PaymentType(session);
      }
    }
  }

  markAsUnTouched() {
    this.formGroup.markAsUntouched({ onlySelf: true });
    this.formGroup.markAsPristine({ onlySelf: true });
  }

  initPaymentResource(paymentType: PaymentType) {
    if (paymentType === 'cybersource') {
      return this.addCybersourceScript();
    }

    if (paymentType === 'shift4') {
      return this.addShift4Script();
    }

    return of(paymentType);
  }

  initCyberSourcePaymentType(session: string) {
    this.cybersourcePayment
      .init('#payment-card-number', '#payment-card-cvv', session)
      .pipe(
        tap(({ event, errors, options }) => {
          if (event === 'numberBlur') {
            this.cardFormGroup.controls.number.markAsTouched();
          }

          if (event === 'cvvBlur') {
            this.cardFormGroup.controls.number.markAsTouched();
          }

          switch (event) {
            case 'numberLoaded':
            case 'numberChanged':
              if (errors) {
                this.cardFormGroup.controls.number.setValidators(setFormControlError(errors));
              } else {
                this.cardFormGroup.controls.number.clearValidators();
              }

              this.cardFormGroup.controls.cardType.setValue(options?.cardType || '');
              this.cardFormGroup.controls.cardName.setValue(options?.cardName || '');
              break;

            case 'cvvLoaded':
            case 'cvvChanged':
              if (errors) {
                this.cardFormGroup.controls.cvv.setValidators(setFormControlError(errors));
              } else {
                this.cardFormGroup.controls.cvv.clearValidators();
              }
              break;
          }

          this.cardFormGroup.controls.cvv.updateValueAndValidity();
          this.cardFormGroup.controls.number.updateValueAndValidity();
        }),
        takeUntil(this.alive$)
      )
      .subscribe();
  }

  initShift4PaymentType(session: string) {
    this.shift4Payment.init('#payment-shift4').subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disableAutoRenew) {
      if (changes.disableAutoRenew.currentValue) {
        disableFormControl(this.formGroup.controls.autoRenew);
      } else {
        enableFormControl(this.formGroup.controls.autoRenew);
      }
    }
  }

  ngOnDestroy() {
    this.store.dispatch(PaymentActions.changedStatus({ paymentStatus: PaymentStatus.INACTIVE }));
    this.alive$.next(null);
    this.alive$.complete();
  }

  addCybersourceScript() {
    const scriptSrc = 'https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js';

    return from(this.scriptsService.addJs(scriptSrc));
  }

  addShift4Script() {
    const scriptSrc = 'https://js.dev.shift4.com/shift4.js';

    return from(this.scriptsService.addJs(scriptSrc));
  }
}
