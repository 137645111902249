import { Injector, NgModule } from '@angular/core';
import { createCustomElement, NgElementConstructor } from '@angular/elements';
import { APP_BASE_HREF, CommonModule, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { AgentSchedulerCustomElementComponent } from '../../custom-elements/agent-scheduler.component';
import { BatteryQuoterCustomElementComponent } from '../../custom-elements/battery-quoter.component';
import { HiddenTitleCustomElementComponent } from '../../custom-elements/hidden-title.component';
import { MyAccountCtaCustomElementComponent } from '../../custom-elements/my-account-cta.component';
import { MultiblockCustomElementComponent } from '../../custom-elements/multiblock.component';
import { OfficeAgentsCustomElementComponent } from '../../custom-elements/office-agents.component';
import { AccountEmailSyncCustomElementComponent } from '../../custom-elements/account-email-sync.component';
import { FileManagerCustomElementComponent } from '../../custom-elements/file-manager.component';
import { NotificationCustomElementComponent } from '../../custom-elements/notification.component';
import { SmartWeatherCustomElementComponent } from '../../custom-elements/smart-weather.component';
import { rxStateProvider } from '../../modules/share/providers/rx-state.provider';
import { RouterModule } from '@angular/router';
import { AngularFireAnalyticsModule, UserTrackingService } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../../../environments/environment';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { QuillModule } from 'ngx-quill';
import { AppStoreModule } from '../../store/store.module';
import { SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { CONFIG as AngularFireAnalytics_CONFIG } from '@angular/fire/compat/analytics';
import { HOOSIER_RX_STATE, HoosierState } from '../../modules/join-renew/hoosier/hoosier.service';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MultiblockModule } from '../../modules/multiblocks/multiblock.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AvaEmemberCustomElementComponent } from '../../custom-elements/ava-emember.component';
import { EmemberModule } from '../../modules/emember/emember.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxLocationStrategiesModule } from '../../services/location-strategy/ngx-location-strategies.module';

registerLocaleData(en);

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    AppStoreModule,
    RouterModule.forRoot([]),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFirestoreModule,
    QuillModule.forRoot(),
    HttpClientModule,
    MultiblockModule,
    EmemberModule,
    NgxLocationStrategiesModule.forRoot({ routeQueryName: 'app', stickyParams: ['page'] }),
  ],
  providers: [
    NzModalService,
    rxStateProvider(),
    {
      provide: APP_BASE_HREF,
      useValue: window.baseHref,
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: FIRESTORE_SETTINGS,
      useValue: environment.emulator
        ? {
            host: 'localhost:8080',
            ssl: false,
          }
        : {
            experimentalForceLongPolling: true,
          },
    },
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide: AngularFireAnalytics_CONFIG,
      useValue: {
        send_page_view: false,
        anonymize_ip: true,
      },
    },
    {
      provide: HOOSIER_RX_STATE,
      useFactory: () => new RxState<HoosierState>(),
    },
    RxEffects,
    RxState,
    UserTrackingService,
  ],
})
export class CustomElementModule {
  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    /**
     * Create the custom element from an Angular component.
     */
    const agentSchedulerElement = createCustomElement(AgentSchedulerCustomElementComponent, {
      injector: this.injector,
    });
    const batteryElement = createCustomElement(BatteryQuoterCustomElementComponent, { injector: this.injector });
    const hiddenTitleElement = createCustomElement(HiddenTitleCustomElementComponent, { injector: this.injector });
    const myAccountCtaElement = createCustomElement(MyAccountCtaCustomElementComponent, { injector: this.injector });
    const multiblockElement = createCustomElement(MultiblockCustomElementComponent, { injector: this.injector });
    const ememberElement = createCustomElement(AvaEmemberCustomElementComponent, { injector: this.injector });
    const officeAgentsElement = createCustomElement(OfficeAgentsCustomElementComponent, { injector: this.injector });

    const accountEmailSyncElement = createCustomElement(AccountEmailSyncCustomElementComponent, {
      injector: this.injector,
    });
    const fileManagerElement = createCustomElement(FileManagerCustomElementComponent, { injector: this.injector });
    const notificationElement = createCustomElement(NotificationCustomElementComponent, { injector: this.injector });
    const smartWeatherElement = createCustomElement(SmartWeatherCustomElementComponent, { injector: this.injector });

    /**
     * Define the custom element with a name.
     */
    defineCustomElement('ava-agent-scheduler-custom-element', agentSchedulerElement);
    defineCustomElement('ava-battery-quoter-custom-element', batteryElement);
    defineCustomElement('ava-hidden-title-custom-element', hiddenTitleElement);
    defineCustomElement('ava-multiblock-custom-element', multiblockElement);
    defineCustomElement('ava-emember-custom-element', ememberElement);
    defineCustomElement('ava-my-account-cta-custom-element', myAccountCtaElement);
    defineCustomElement('ava-office-agents-custom-element', officeAgentsElement);

    defineCustomElement('ava-smart-weather-custom-element', smartWeatherElement);
    defineCustomElement('ava-file-manager-custom-element', fileManagerElement);
    defineCustomElement('ava-account-email-sync-custom-element', accountEmailSyncElement);
    defineCustomElement('ava-notification-custom-element', notificationElement);

    function defineCustomElement(
      elementName: string,
      element: NgElementConstructor<unknown> | CustomElementConstructor
    ) {
      if (customElements.get(elementName) === undefined) {
        customElements.define(elementName, element);
      }
    }

    /**
     * Add the custom element name to the init/init.js array of customElementTags in getCustomElements().
     * This will provide an analytics event for the custom element.
     * If the browser is unsupported, it will also provide a browser upgrade message.
     */
  }
}
