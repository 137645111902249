import { Inject, Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { combineLatestWith, debounceTime, takeWhile, tap } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { WINDOW } from '@ng-web-apis/common';
import { JoinRenewService, OpStatus } from '../../join-renew.service';
import { GLOBAL_RX_STATE, GlobalState } from '../../../../services/state';
import {
  AccountMembershipView,
  AccountSection,
  CheckoutStep,
  Flow,
  HOOSIER_RX_STATE,
  HoosierService,
  HoosierState,
  SessionDocResponse,
  SessionDocResponseObject,
  View,
} from '../hoosier.service';
import { FormGroup } from '@angular/forms';
import { PricePreviewsService } from './price-previews';
import { Membership } from '../services/member-info';
import { AlertGroup, AlertMessage, AlertMessageService, AlertType } from '../../../../services/alert-message';
import { ValidationData } from '../services/validation-data';
import { LinkService } from '../../../../services/link';
import Timestamp = firebase.firestore.Timestamp;
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteRecostValidateChangeAREventPayload,
  MembershipConnectSuiteRecostValidateChangeARResponse,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { EventName } from '@aaa/interface-joinRenew-joinRenewLib';

export interface ValidateAutoRenewResponseObject extends SessionDocResponseObject {
  error: ValidateAutoRenewError;
  // meta: SessionDocMeta
  response: ValidateAutoRenewResponse;
}

export interface ValidateAutoRenewResponse extends MembershipConnectSuiteRecostValidateChangeARResponse {
  validationData: ValidationData;
  executionData: string;
}

export interface ValidateAutoRenewError {
  // errorCode: number
  // error: string
}

/*
export interface ValidationData {
  // attributes: {
  //   isAutoRenew: string
  //   balance: string
  //   responseText: string
  //   version: string
  //   totalCost: string
  //   responseCode: string
  // }
  // membership: ValidateMembership
}
*/

interface ValidateMembership extends Membership {
  // costSummary?: {
  //   autoRenewDiscount: string
  //   discountAmount: string
  //   solicitationDiscount: string
  //   solicitationRequiresAutoRenew: string
  //   totalCost: string
  // }
}

/**
 * recostValidateRenew for:
 *   quickRenew (with memberNumber, lastName, and postalCode)
 *   myAccountRenew (with logged in member)
 */

enum ChangeAutoRenewStep {
  AUTO_RENEW,

  AUTO_RENEW_ADD,
  AUTO_RENEW_ADD_CONFIRMATION,
  AUTO_RENEW_ADD_EXECUTE,
  AUTO_RENEW_ADD_VALIDATE,

  AUTO_RENEW_REMOVE,
  AUTO_RENEW_REMOVE_CONFIRMATION,
  AUTO_RENEW_REMOVE_EXECUTE,
  AUTO_RENEW_REMOVE_VALIDATE,
}

@Injectable({
  providedIn: 'root',
})
export class ValidateChangeAutoRenewService {
  form: FormGroup;
  numberOfRetries: number = 0;

  constructor(
    private pricePreviewsService: PricePreviewsService,
    @Inject(WINDOW)
    private window: Window,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private joinRenewService: JoinRenewService,
    private hoosierService: HoosierService,
    private rxEffects: RxEffects,
    private alertMessageService: AlertMessageService,
    private linkService: LinkService
  ) {
    this.form = hoosierService.form;
    rxEffects.register(this.recostValidateAutoRenew$);
    rxEffects.register(this.VALIDATE_KEY$);
    rxEffects.register(this.VALIDATE$);
    rxEffects.register(this.retryOnError$);
  }

  recostValidateAutoRenew$ = this.hoosierState.select('activeView').pipe(
    combineLatestWith(this.hoosierState.select('accountDetails', 'memberNumber')),
    tap(([activeView, memberNumber]: [View | null, string]) => {
      const changeAutoRenewPayload: MembershipConnectSuiteRecostValidateChangeAREventPayload = {
        method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_AR,
        memberNumber: memberNumber,
        autoRenew: null,
      };
      if (memberNumber) {
        const VALIDATE_AUTO_RENEW = this.hoosierState.get('VALIDATE_AUTO_RENEW');
        switch (activeView) {
          case AccountMembershipView.AUTO_RENEW:
          case AccountMembershipView.AUTO_RENEW_CARD:
            changeAutoRenewPayload.autoRenew = true;
            if (
              VALIDATE_AUTO_RENEW?.response?.validationData?.attributes?.isAutoRenew !== 'true' ||
              this.hoosierState.get('VALIDATE_AUTO_RENEW_STATUS') === OpStatus.RUNNING
            ) {
              this.recostValidateAutoRenew(changeAutoRenewPayload);
            }
            break;
          case AccountMembershipView.AUTO_RENEW_REMOVE:
            changeAutoRenewPayload.autoRenew = false;
            if (
              VALIDATE_AUTO_RENEW?.response?.validationData?.attributes?.isAutoRenew !== 'false' ||
              this.hoosierState.get('VALIDATE_AUTO_RENEW_STATUS') === OpStatus.RUNNING
            ) {
              this.recostValidateAutoRenew(changeAutoRenewPayload);
            }
        }
      }
    })
  );

  retryOnError$ = this.hoosierState.select('VALIDATE_AUTO_RENEW_STATUS').pipe(
    takeWhile(() => this.numberOfRetries < 3),
    debounceTime(10000),
    tap(status => {
      if (status === OpStatus.IS_ERROR) {
        this.alertMessageService.set(AlertMessage.ERROR_RETRY, AlertType.INFO);
        const changeAutoRenewPayload = this.form.get('changeAutoRenewPayload')?.value;
        if (changeAutoRenewPayload) {
          this.recostValidateAutoRenew(changeAutoRenewPayload, true);
        }
      }
    })
  );

  VALIDATE_KEY$ = this.hoosierState.select('VALIDATE_AUTO_RENEW_KEY').pipe(
    combineLatestWith(this.hoosierState.select('sessionDoc', 'responses', 'membership', 'connectsuite')),
    tap(([VALIDATE_AUTO_RENEW_KEY, connectsuite]: [string | null, SessionDocResponse]) => {
      if (VALIDATE_AUTO_RENEW_KEY && connectsuite[VALIDATE_AUTO_RENEW_KEY]) {
        this.hoosierState.set('VALIDATE_AUTO_RENEW_KEY', () => null);

        const responseTime = Timestamp.now().toMillis() - parseInt(VALIDATE_AUTO_RENEW_KEY);
        if (this.globalState.get('adminUser')) {
          console.log(responseTime, 'milliseconds - VALIDATE_AUTO_RENEW');
        }
        const validateResponseObject = connectsuite[VALIDATE_AUTO_RENEW_KEY] as ValidateAutoRenewResponseObject;
        const isError = validateResponseObject.meta.isError;

        if (!isError) {
          this.alertMessageService.clearAll(AlertGroup.ERROR);

          // this.hoosierState.set("activeSection", () => AccountSection.MEMBERSHIP)
          // this.hoosierState.set("activeView", () => AccountMembershipView.CHANGE_LEVEL_CONFIRMATION)

          this.hoosierState.set('VALIDATE_AUTO_RENEW_STATUS', () => OpStatus.SUCCESS);
          this.hoosierState.set('VALIDATE_AUTO_RENEW', () => validateResponseObject);
          this.hoosierState.set('VALIDATE_AUTO_RENEW_ERROR', () => null);
        }

        if (isError) {
          this.hoosierState.set('VALIDATE_AUTO_RENEW_STATUS', () => OpStatus.IS_ERROR);
          this.hoosierState.set('VALIDATE_AUTO_RENEW', () => null);
          this.hoosierState.set('VALIDATE_AUTO_RENEW_ERROR', () => validateResponseObject);
          this.alertMessageService.set(AlertMessage.ERROR_CRITICAL, AlertType.ERROR);
        }
      }
    })
  );

  VALIDATE$ = this.hoosierState.select('VALIDATE_AUTO_RENEW').pipe(
    tap(VALIDATE_AUTO_RENEW => {
      if (VALIDATE_AUTO_RENEW) {
        const totalCost = parseFloat(VALIDATE_AUTO_RENEW.response.validationData.attributes.totalCost);
        this.form.get(['paymentPayload', 'executionData', 'amountDetails', 'totalAmount'])?.setValue(totalCost);
        if (
          this.hoosierState.get('activeView') === AccountMembershipView.AUTO_RENEW_REMOVE &&
          VALIDATE_AUTO_RENEW.response.validationData.attributes.isAutoRenew === 'false'
        ) {
          this.hoosierState.set('activeCheckoutStep', () => CheckoutStep.EXECUTE);
        }
      }
    })
  );

  setActiveView(view: AccountMembershipView): void {
    this.linkService.replaceUrlArgument(Flow.ACCOUNT, Flow.ACCOUNT + '/' + AccountSection.MEMBERSHIP + '/' + view);
  }

  recostValidateAutoRenew(
    changeAutoRenewPayload: MembershipConnectSuiteRecostValidateChangeAREventPayload,
    retry?: boolean
  ): void {
    if (retry) {
      this.numberOfRetries++;
    }
    if (!retry) {
      this.numberOfRetries = 0;
    }
    const responseKeyTimestampString = Timestamp.now().toMillis().toString();
    this.hoosierState.set('VALIDATE_AUTO_RENEW_KEY', () => responseKeyTimestampString);
    this.hoosierState.set('VALIDATE_AUTO_RENEW_STATUS', () => OpStatus.RUNNING);
    this.form.get('changeAutoRenewPayload')?.patchValue({ autoRenew: changeAutoRenewPayload.autoRenew });

    changeAutoRenewPayload.responseKey = responseKeyTimestampString;
    changeAutoRenewPayload.method = MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_AR;

    if (this.globalState.get('adminUser')) {
      // console.log(changeAutoRenewPayload)
    }
    this.joinRenewService
      .sendToEventCoordinatorReceiver(EventName.MEMBERSHIP_QUERY, changeAutoRenewPayload)
      .then(response => {
        if (this.globalState.get('adminUser')) {
          console.log(
            EventName.MEMBERSHIP_QUERY +
              '---' +
              MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_AR +
              '---' +
              responseKeyTimestampString +
              '---' +
              response
          );
        }
      })
      .catch(error => {
        this.hoosierState.set('VALIDATE_AUTO_RENEW_STATUS', () => OpStatus.FAILED);
        // console.log(error)
      });
  }
}
