import { Injectable } from '@angular/core';
import { Image, ImageEditorOptions } from '../../fields/image/service';
import { TemplateType } from '../template/service';
import { ScreenSize } from '../../services/style';
import { AbstractControl } from '@angular/forms';
import { FormService } from '../../services/form';
import { FieldType } from '../../services/field';
import { TargetOption, TargetType } from '../../services/link';

export type BackgroundImages = {
  [key in ScreenSize]: Image;
};

@Injectable({
  providedIn: 'root',
})
export class BackgroundImagesService {
  constructor(private formService: FormService) {}

  get newForm(): AbstractControl | undefined {
    return this.formService.newForm(this.newItem);
  }

  get newItem(): BackgroundImages {
    return {
      [ScreenSize.DESKTOP]: {
        altText: '',
        filePath: '',
        fileType: null,
        // label: "Desktop Background Image",
        targetOption: TargetType.SELF,
        targetPath: '',
        fieldType: FieldType.IMAGE,
      },
      [ScreenSize.TABLET]: {
        altText: '',
        filePath: '',
        fileType: null,
        // label: "Tablet Background Image",
        targetOption: TargetType.SELF,
        targetPath: '',
        fieldType: FieldType.IMAGE,
      },
      [ScreenSize.MOBILE]: {
        altText: '',
        filePath: '',
        fileType: null,
        // label: "Mobile Background Image",
        targetOption: TargetType.SELF,
        targetPath: '',
        fieldType: FieldType.IMAGE,
      },
    };
  }

  get editorOptionsArray(): ImageEditorOptions[] {
    return [
      {
        templateType: TemplateType.BANNER,
        screenSize: ScreenSize.DESKTOP,
        alt: false,
        cropper: false,
        description: 'Desktop',
        dimensions: '1440x480',
        label: {
          placeholder: '',
          plural: 'Images',
          singular: 'Image',
        },
        minWidth: {
          px: 1440,
        },
        maxWidth: {
          px: 1440,
        },
        minHeight: {
          px: 480,
        },
        maxHeight: {
          px: 480,
        },
        target: [],
        targetOption: false,
        targetPath: false,
      },
      {
        templateType: TemplateType.BANNER,
        screenSize: ScreenSize.TABLET,
        alt: false,
        cropper: true,
        description: 'Tablet',
        dimensions: '768x320',
        label: {
          placeholder: '',
          plural: 'Images',
          singular: 'Image',
        },
        minWidth: {
          px: 768,
        },
        maxWidth: {
          px: 768,
        },
        minHeight: {
          px: 320,
        },
        maxHeight: {
          px: 320,
        },
        target: [],
        targetOption: false,
        targetPath: false,
      },
      {
        templateType: TemplateType.BANNER,
        screenSize: ScreenSize.MOBILE,
        alt: false,
        cropper: true,
        description: 'Mobile',
        dimensions: '544x350',
        label: {
          placeholder: '',
          plural: 'Images',
          singular: 'Image',
        },
        minWidth: {
          px: 544,
        },
        maxWidth: {
          px: 544,
        },
        minHeight: {
          px: 350,
        },
        maxHeight: {
          px: 350,
        },
        target: [],
        targetOption: false,
        targetPath: false,
      },
      {
        templateType: TemplateType.BANNER_LARGE,
        screenSize: ScreenSize.DESKTOP,
        alt: false,
        cropper: false,
        description: 'Desktop',
        dimensions: '1440x769',
        label: {
          placeholder: '',
          plural: 'Images',
          singular: 'Image',
        },
        minWidth: {
          px: 1440,
        },
        maxWidth: {
          px: 1440,
        },
        minHeight: {
          px: 769,
        },
        maxHeight: {
          px: 769,
        },
        target: [],
        targetOption: false,
        targetPath: false,
      },
      {
        templateType: TemplateType.BANNER_LARGE,
        screenSize: ScreenSize.TABLET,
        alt: false,
        cropper: true,
        description: 'Tablet',
        dimensions: '768x411',
        label: {
          placeholder: '',
          plural: 'Images',
          singular: 'Image',
        },
        minWidth: {
          px: 768,
        },
        maxWidth: {
          px: 768,
        },
        minHeight: {
          px: 411,
        },
        maxHeight: {
          px: 411,
        },
        target: [],
        targetOption: false,
        targetPath: false,
      },
      {
        templateType: TemplateType.BANNER_LARGE,
        screenSize: ScreenSize.MOBILE,
        alt: false,
        cropper: true,
        description: 'Mobile',
        dimensions: '544x350',
        label: {
          placeholder: '',
          plural: 'Images',
          singular: 'Image',
        },
        minWidth: {
          px: 544,
        },
        maxWidth: {
          px: 544,
        },
        minHeight: {
          px: 350,
        },
        maxHeight: {
          px: 350,
        },
        target: [],
        targetOption: false,
        targetPath: false,
      },
      {
        templateType: TemplateType.JOIN_BANNER,
        screenSize: ScreenSize.DESKTOP,
        alt: false,
        cropper: false,
        description: 'Desktop',
        dimensions: '1440x600',
        label: {
          placeholder: '',
          plural: 'Images',
          singular: 'Image',
        },
        minWidth: {
          px: 1440,
        },
        maxWidth: {
          px: 1440,
        },
        minHeight: {
          px: 600,
        },
        maxHeight: {
          px: 600,
        },
        target: [TargetType.SELF, TargetType.NEW],
        targetOption: true,
        targetPath: true,
      },
      {
        templateType: TemplateType.JOIN_BANNER,
        screenSize: ScreenSize.TABLET,
        alt: false,
        cropper: true,
        description: 'Tablet',
        dimensions: '768x411',
        label: {
          placeholder: '',
          plural: 'Images',
          singular: 'Image',
        },
        minWidth: {
          px: 768,
        },
        maxWidth: {
          px: 768,
        },
        minHeight: {
          px: 411,
        },
        maxHeight: {
          px: 411,
        },
        target: [],
        targetOption: false,
        targetPath: false,
      },
      {
        templateType: TemplateType.JOIN_BANNER,
        screenSize: ScreenSize.MOBILE,
        alt: false,
        cropper: true,
        description: 'Mobile',
        dimensions: '544x350',
        label: {
          placeholder: '',
          plural: 'Images',
          singular: 'Image',
        },
        minWidth: {
          px: 544,
        },
        maxWidth: {
          px: 544,
        },
        minHeight: {
          px: 350,
        },
        maxHeight: {
          px: 350,
        },
        target: [],
        targetOption: false,
        targetPath: false,
      },
    ];
  }
}
