import { Inject, Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import anchorme from 'anchorme';
import sanitizeHtml from 'sanitize-html';
import { GLOBAL_RX_STATE, GlobalState } from './state';
import { RxState } from '@rx-angular/state';
import { delay, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private http: HttpClient
  ) {}

  /**
   * The next 4 functions are used by agentScheduler
   * TODO: Refactor agentScheduler to use reactive form validators (the following "ValidatorFn" functions)
   *       then remove these boolean functions, they will be unused after refactoring agentScheduler
   */
  validateIsEmail(email: string): boolean {
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return regex.test(email);
  }

  validateIsPhone(phone: string): boolean {
    const regex = /([().+ -]*[0-9]){10,11}[().+ -]*?/;
    return regex.test(phone);
  }

  validateIsTextString(string: string): boolean {
    const regex = /^\D+$/g;
    return regex.test(string);
  }

  validateComment(c: FormControl): ValidationErrors | null {
    let dirty: ('links' | 'email addresses' | 'HTML tags')[] = [];
    sanitizeHtml(c.value, {
      exclusiveFilter: function (frame) {
        if (frame.tag) {
          dirty.push('HTML tags');
        }
        return false;
      },
    });
    anchorme({
      input: c.value,
      options: {
        specialTransform: [
          {
            test: /[^]*/, //everything
            transform: s => {
              if (anchorme.validate.email(s)) {
                dirty.push('email addresses');
              } else {
                dirty.push('links');
              }
              return s;
            },
          },
        ],
      },
    });

    /**
     *  alternate (verbose) way to build english representation
     *
     if (!dirty) {
      return null
    }
     if (dirty.length === 1) {
      return { errorMessage: dirty[0] + " are not allowed." }
    }
     if (dirty.length === 2) {
      return { errorMessage: dirty[0] + " and " + dirty[1] + " are not allowed." }
    }
     if (dirty.length === 3) {
      return { errorMessage: dirty[0] + ", " + dirty[1] + " and " + dirty[2] + " are not allowed." }
    }

     /**
     * build english representation of 'dirty' array
     */
    dirty = Array.from(new Set(dirty));
    const err: boolean = dirty.length > 0;
    let message: string = '';
    if (dirty.length === 1) {
      message += dirty[0];
    } else if (dirty.length > 1) {
      const anded: string = dirty.pop() + ' and ' + dirty.pop();
      if (dirty.length > 0) {
        dirty.push(anded as any);
        message += dirty.join(', ');
      } else {
        message += anded;
      }
    }
    message += ' are not allowed.';

    return !err
      ? null
      : {
          errorMessage: message,
        };
  }

  email(control: AbstractControl): ValidationErrors | null {
    const regex = /^[\w+.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9]{2,63}$/;
    if (control.value && !regex.test(control.value) && control.value.length) {
      return { invalidFormat: true };
    }
    return null;
  }

  validateIsPhoneFunction(control: AbstractControl): ValidationErrors | null {
    // const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}\d*$/;
    if (control.value && !regex.test(control.value)) {
      return { errorMessage: 'not valid phone number format' };
    }
    return null;
  }

  validateIsStrongPasswordFunction(control: AbstractControl): ValidationErrors | null {
    const regex = /^(?=.*\d)(?=.*[@#\-_$%^&+=§!?])(?=.*[a-z])(?=.*[A-Z])[0-9A-Za-z@#\-_$%^&+=§!?]{8,20}$/;
    if (!regex.test(control.value)) {
      return { errorMessage: 'not a strong password' };
    }
    return null;
  }

  validateIsTextStringFunction(control: AbstractControl): ValidationErrors | null {
    const regex = /^[A-Za-z\s]*$/;
    const spacesOnly = /^\s*$/;
    // console.log(control)
    // console.log(control.value)
    // console.log(regex.test(control.value))
    if (!regex.test(control.value) || spacesOnly.test(control.value)) {
      return { errorMessage: 'has non-text characters' };
    }
    return null;
  }

  validateCommentFunction(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dirty: ('links' | 'email addresses' | 'HTML tags')[] = [];
      sanitizeHtml(control.value, {
        exclusiveFilter: function (frame) {
          if (frame.tag) {
            dirty.push('HTML tags');
          }
          return false;
        },
      });
      anchorme({
        input: control.value,
        options: {
          specialTransform: [
            {
              test: /[^]*/, //everything
              transform: s => {
                if (anchorme.validate.email(s)) {
                  dirty.push('email addresses');
                } else {
                  dirty.push('links');
                }
                return s;
              },
            },
          ],
        },
      });

      if (!dirty) {
        return null;
      }
      if (dirty.length === 1) {
        return { errorMessage: dirty[0] + ' are not allowed.' };
      }
      if (dirty.length === 2) {
        return { errorMessage: dirty[0] + ' and ' + dirty[1] + ' are not allowed.' };
      }
      if (dirty.length === 3) {
        return { errorMessage: dirty[0] + ', ' + dirty[1] + ' and ' + dirty[2] + ' are not allowed.' };
      }
      return null;
    };
  }

  emailIsAvailable(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value.length || this.globalState.get('environment', 'ngServe')) {
      if (control.value === 'test@test.com') {
        return of({ emailExists: true }).pipe(delay(1000));
      }
      return of(null).pipe(delay(1000));
    }
    let headers = new HttpHeaders();
    const servicesTokenCSRF = this.globalState.get('windowMetaData', 'servicesTokenCSRF');
    if (servicesTokenCSRF) {
      headers = headers.append('X-CSRF-TOKEN', servicesTokenCSRF);
    }
    // angular will attach this "X-CSRF-TOKEN" if the url begins with "//"
    return this.http
      .post(
        this.globalState.get('location', 'origin') + '/api/v1/emember/email_exists',
        {
          email: control.value,
        },
        {
          headers: headers,
          responseType: 'text',
        }
      )
      .pipe(
        // debounceTime(500),
        map((data: any) => {
          // console.log(data)
          const response = data.replace('---\n', '').replace('\n', '');
          // console.log(response)
          if (response === 'exists: false') {
            return null; // validation is a success, do not return an error message
          }
          if (response === 'exists: true') {
            return { emailExists: true };
          }
          return { unableToVerify: true };
        }),
        catchError(error => {
          console.log(error);
          return of({ lookupFailed: true });
        })
      ) as Observable<ValidationErrors>;
  }

  zipIsInRegion(control: AbstractControl): Observable<ValidationErrors | null> {
    if (control.value.length !== 5 || this.globalState.get('environment', 'ngServe')) {
      if (control.value !== '11111') {
        return of({ zipNotInRegion: true }).pipe(delay(5000));
      }
      return of(null).pipe(delay(1000));
    }

    return this.http.get('https://ww2.aaa.com/RESTWS/aaa/services/geo/postal/' + control.value).pipe(
      map((data: any) => {
        // console.log(data)
        const clubs = data?.services?.geo?.postal?.clubs?.club;
        // console.log(clubs)
        if (Array.isArray(clubs)) {
          const clubId = this.globalState.get('windowMetaData', 'clubId');
          const zipIsInRegion = clubs.find(club => club?.code === clubId);
          if (zipIsInRegion) {
            return null;
          }
        }
        return { zipNotInRegion: true };
      }),
      catchError(error => {
        console.log(error);
        return of({ lookupFailed: true });
      })
    ) as Observable<ValidationErrors>;
  }
}
