import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgIf } from '@angular/common';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { AlertType } from './types';
import { delay, first, of, tap } from 'rxjs';

@Component({
  selector: 'ava-alert',
  template: `
    <nz-alert
      *ngIf="message || description"
      class="ava-alert"
      nzBanner
      [nzShowIcon]="nzShowIcon"
      [nzType]="type"
      [nzMessage]="messageTemplate"
      [nzDescription]="description ? descriptionTemplate : ''"
      [nzCloseable]="closeable"
    />
    <ng-template #messageTemplate>
      <div [innerHTML]="message"></div>
    </ng-template>
    <ng-template #descriptionTemplate>
      <div [innerHTML]="description"></div>
    </ng-template>
  `,
  styles: [
    `
      :host {
        .ava-alert {
          color: #232323d9;
          font-size: 16px;
          font-weight: 200;
        }
      }
    `,
  ],
  standalone: true,
  imports: [NgIf, NzAlertModule],
})
export class AlertComponent implements OnChanges {
  @Input() message: string | null = '';
  @Input() timeout = 10000;
  @Input() description = '';
  @Input() nzShowIcon = false;
  @Input() type: AlertType = AlertType.INFO;
  @Input() closeable: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.message && changes.message.currentValue) {
      this.setTimeOut();
    }
  }

  setTimeOut() {
    of(false)
      .pipe(
        delay(this.timeout),
        first(),
        tap(() => (this.message = '')),
        tap(() => (this.description = ''))
      )
      .subscribe();
  }
}
