import { getClubOption } from '../membership/membership.selectors';
import {
  address1,
  birthday,
  cardCvv,
  cardNumber,
  city,
  email,
  expMonth,
  expYear,
  firstName,
  lastName,
  password,
  phone,
  state,
  zipcode,
} from './form-errors';
import { createSelector } from '@ngrx/store';

export const getMembershipFormErrors = createSelector(getClubOption, option => {
  return {
    firstName,
    lastName,
    address1,
    city,
    state,
    zipcode: zipcode(option),
    birthday,
    phone,
    email: email(option),
    password,
  };
});

export const getGiftRecipientFormErrors = createSelector(getClubOption, option => {
  return {
    firstName,
    lastName,
    address1,
    city,
    state,
    zipcode: zipcode(option),
    birthday,
    phone,
    email: email(option),
  };
});

export const getZipcodeFormErrors = createSelector(getClubOption, option => {
  return {
    zipcode: zipcode(option),
  };
});

export const getMembershipAssociateFormErrors = createSelector(getClubOption, option => ({
  firstName,
  lastName,
  birthday,
  email: email(option),
}));

export const getBillingFormErrors = createSelector(getClubOption, option => ({
  firstName,
  lastName,
  address1,
  city,
  state,
  zipcode: zipcode(option),
}));

export const getPaymentFormErrors = createSelector(getClubOption, option => ({
  cardNumber,
  cardCvv,
  expMonth,
  expYear,
}));

export const getAccountAddressFormErrors = createSelector(getClubOption, option => ({
  address1,
  city,
  state,
  zipcode: zipcode(option),
}));

export const getAccountPhoneFormErrors = createSelector(getClubOption, option => ({ phone }));

export const getAccountEmailFormErrors = createSelector(getClubOption, option => ({ email: email(option) }));
