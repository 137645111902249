import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { combineLatestWith, Observable, of, tap } from 'rxjs';
import { MultiblockStyle as ThisStyle, Styles } from './services/style';
import { BlockService, Options } from './services/block';
import { FormService } from '../services/form';
import { Link, LinkService } from '../services/link';
import { ThemeService } from '../options/theme/service';
import { Block, Column } from './services/block';
import { ColorService } from '../services/color';
import { TemplateType } from '../options/template/service';
import { DataReadService } from '../services/data-read';
import { RevisionReadService } from '../services/revision-read';
import { Border, BreakpointWidth, Style } from '../services/style';
import { GLOBAL_RX_STATE, GlobalState, StateService } from '../services/state';
import { ColumnWidthService } from '../options/column-width/service';
import { FieldType } from '../services/field';
import { BlockState } from './services/state';

@Component({
  selector: 'ava-multiblock-view-column',
  templateUrl: './view-column.html',
  styles: [':host { position: relative }'],
  providers: [RxState, RxEffects],
})
export class MultiblockViewColumnComponent implements OnInit {
  @Input() stateId: string | undefined;
  @Input() columnIndex: number | undefined;

  globalState$ = this.globalState.select();
  blockState: RxState<BlockState> | undefined;
  blockState$: Observable<BlockState> | undefined;
  formGroup: FormGroup | undefined;
  styles: Styles;
  // contentStyles: ContentStyles
  border: Border;
  options: Options;
  highlight: { [key: string]: boolean };
  columnWidth: number | undefined;
  rowsFlexDirection: 'ROW' | 'COLUMN' = 'COLUMN';

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private thisService: BlockService,
    private thisStyle: ThisStyle,
    private colorService: ColorService,
    private columnWidthService: ColumnWidthService,
    private dataReadService: DataReadService,
    private formService: FormService,
    private linkService: LinkService,
    private revisionReadService: RevisionReadService,
    private stateService: StateService,
    private styleService: Style,
    private themeService: ThemeService,
    private changeDetectorRef: ChangeDetectorRef,
    private rxEffects: RxEffects
  ) {
    this.styles = thisStyle.styles;
    this.border = styleService.border;
    this.options = thisService.options;

    this.highlight = thisService.highlight;
  }

  get columnWidth$(): Observable<any> {
    if (this.blockState) {
      return this.blockState.select('blockWidth').pipe(
        combineLatestWith(
          this.blockState.select('blockValue', 'columns'),
          this.blockState.select('blockValue', 'options', 'breakpointsEnabled'),
          this.blockState.select('blockValue', 'options', 'columnGap', 'px'),
          this.blockState.select('blockValue', 'template', 'type')
        ),
        tap(
          ([blockWidth, columns, breakpointsEnabled, columnGapPx, templateType]: [
            number | undefined,
            Column[],
            boolean,
            number,
            TemplateType | null,
          ]) => {
            if (blockWidth && this.columnIndex !== undefined) {
              /**
               * single column display for Join Banner when smaller than Desktop with a maxWidth
               */
              const maxWidth = 500;
              if (blockWidth < BreakpointWidth.LARGE && templateType === TemplateType.JOIN_BANNER) {
                this.columnWidth = blockWidth < maxWidth ? blockWidth : maxWidth;
                return;
              }
              if (columns[this.columnIndex]) {
                this.columnWidth = this.columnWidthService.columnWidth(
                  blockWidth,
                  this.columnIndex,
                  columns,
                  breakpointsEnabled,
                  columnGapPx
                );
              }
            }
          }
        )
      );
    }
    return of(null);
  }

  get backgroundImageRows$(): Observable<any> {
    if (this.blockState) {
      return this.blockState.select('blockValue', 'template', 'type').pipe(
        combineLatestWith(
          this.blockState.select('blockValue', 'rows'),
          this.blockState.select('blockValue', 'columns')
        ),
        tap(([templateType, rows, columns]: [TemplateType | null, Block['rows'], Block['columns']]) => {
          if (this.blockState && templateType) {
            const backgroundImageRows = this.getBackgroundImageRows(templateType, rows, columns[0]?.rows || []);
            this.blockState.set('backgroundImageRows', () => backgroundImageRows);
          }
        })
      );
    }
    return of(null);
  }

  ngOnInit(): void {
    if (this.stateId) {
      this.blockState = this.stateService.states[this.stateId];
      this.formGroup = this.formService.forms[this.blockState.get('block', 'id')];
    }
    this.blockState$ = this.blockState?.select();

    this.rxEffects.register(this.columnWidth$);
    this.rxEffects.register(this.backgroundImageRows$);

    /**
     * set rows flex-direction
     */
    /*
        this.rxEffects.register(this.blockState.select("blockValue", "template", "type")
          .pipe(
            combineLatestWith(
              this.parentBlockState.select("blockValue", "rows", this.blockState.get("parentRowId"), "label")
            ),
            tap(([templateType, rowLabel]: [TemplateType, string]) => {
              let rowsFlexDirection: ColumnState["rowsFlexDirection"] = "COLUMN"
              if (templateType === TemplateType.CALL_TO_ACTION) {
                rowsFlexDirection = "ROW"
              }
              if (rowLabel === "Buttons") {
                rowsFlexDirection = "ROW"
              }
              this.columnState.set({ rowsFlexDirection: rowsFlexDirection })
            })
          ))
    */
  }

  /**
   * helpers
   */

  getBackgroundImageRows(templateType: TemplateType, rows: Block['rows'], rowIds: string[]): boolean[] {
    const backgroundImageRows = [];
    switch (templateType) {
      case TemplateType.BANNER:
      case TemplateType.BANNER_LARGE:
      case TemplateType.JOIN:
        if (rows[rowIds[0]]?.fieldType === FieldType.IMAGE) {
          backgroundImageRows.push(true);
          if (rows[rowIds[1]]?.fieldType === FieldType.IMAGE) {
            backgroundImageRows.push(true);
            if (rows[rowIds[2]]?.fieldType === FieldType.IMAGE) {
              backgroundImageRows.push(true);
            }
          }
        }
        break;
      default:
    }
    return backgroundImageRows;
  }

  /**
   * template methods
   */

  /*
    async setActive(event: boolean, blockSessionId: string, parentColumnIndex: number, parentRowIndex: number, rowId: string): Promise<void> {
      const element: HTMLElement | null = this.document.getElementById(blockSessionId + "-c" + parentColumnIndex.toString() + "-r" + parentRowIndex.toString())
      element?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })

      /!**
       * wait 600 milliseconds for the previous smooth scroll to finish before closing the other accordions
       *!/
      this.columnState.set("active", oldState => {
        oldState.active[rowId] = event
        return oldState.active
      })
      await new Promise(r => setTimeout(r, 600))
      this.columnState.set("active", () => {
        return { [rowId]: event }
      })
    }
  */

  setBackgroundColor(columnIndex: number, hover: boolean): void {
    const columnsAsCards = this.blockState?.get('columnsAsCards');
    if (columnsAsCards) {
      if (hover) {
        columnsAsCards.colors[columnIndex] = {
          background: '#00a0df',
          text: 'white',
        };
      }
      if (!hover) {
        columnsAsCards.colors[columnIndex] = {
          background: '#e6e6e6',
          text: '#00a0df',
        };
      }
      this.blockState?.set('columnsAsCards', () => columnsAsCards);
    }
  }

  cardClick(event: Event, link: Link): void {
    event.preventDefault();
    this.linkService.linkClick(link);
  }
}
