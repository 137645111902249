import { Component } from '@angular/core';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@Component({
  selector: 'ava-divider',
  template: `
    <nz-divider />
  `,
  styles: [
    `
      :host {
        nz-divider {
          margin: 0;
        }
      }
    `,
  ],
  standalone: true,
  imports: [NzDividerModule],
})
export class AvaDividerComponent {}
