import { Component, forwardRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { FormBaseControlComponent } from './base';
import { Subject, takeUntil, tap } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'ava-form-tel',
  template: `
    <nz-form-item [ngClass]="{ 'ava-label-hide': !formLabel }">
      <nz-form-label class="ava-form-label" [nzFor]="controlId" [nzSpan]="7">
        {{ formLabel || placeholder }}
      </nz-form-label>
      <nz-form-control [nzValidateStatus]="formControlStatus" [nzErrorTip]="errors" nzHasFeedback>
        <nz-input-group [nzSuffix]="suffixRef">
          <input
            [formControl]="control"
            [placeholder]="placeholder"
            (blur)="onBlur()"
            maxlength="12"
            nz-input
            type="tel"
            class="ava-form-control"
            [attr.id]="controlId"
          />
          <input style="display: none" [formControl]="formControl" [placeholder]="placeholder" nz-input type="tel" />
        </nz-input-group>
        <ng-template #errors let-control>
          <ng-container *ngIf="formControl?.errors">
            <ng-container *ngIf="(formControl.errors | keyvalue)[0] as error">
              <div
                class="ava-form-error"
                [innerHTML]="errorMessages && errorMessages[error.key] ? errorMessages[error.key] : error.key"
              ></div>
            </ng-container>
          </ng-container>
        </ng-template>
        <ng-template #suffixRef>
          {{ formControl.value ? placeholder : '' }}
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      @import '../../../../styles/ava-responsive';

      :host {
        font-weight: 200;
        font-family: var(--ava-font-family);

        input::placeholder,
        ::ng-deep .ant-input-suffix {
          @include formControlPlaceholderStyle();
        }

        .ava-label-hide nz-form-label {
          width: 0;
          overflow: hidden;
          position: absolute;
        }

        .ava-form-control {
          @include formControlInputFontStyle();
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormTelComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, NzFormModule, NzGridModule, NzInputModule, ReactiveFormsModule, NzAutocompleteModule],
  standalone: true,
})
export class FormTelComponent extends FormBaseControlComponent {
  control = new FormControl('', { nonNullable: true });
  alive$ = new Subject();
  constructor() {
    super();
    this.control.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(value => this.setMask(value)),
        takeUntil(this.alive$)
      )
      .subscribe();
  }

  onBlur() {
    const value = this.removeMask(this.control.value);

    if (value !== this.formControl.value) {
      this.formControl.setValue(value);
    }
  }

  override writeValue(value: any) {
    super.writeValue(value);
    this.setMask(value);
  }

  override setDisabledState(isDisabled: boolean) {
    if (isDisabled && this.control.enabled) {
      this.control.disable();
    }

    if (!isDisabled && this.control.disabled) {
      this.control.enable();
    }
  }

  private removeMask(value: string) {
    const onlyIntegers = `${value}`.replace(/\D/g, '');
    const firstTenDigits = onlyIntegers.substr(0, 10);

    return firstTenDigits;
  }

  private setMask(value: string) {
    let phoneNumber = '';
    const separator = '-';
    const firstTwelveDigits = (value || '').replace(/\D/g, '').substr(0, 10).split('');

    for (let index = 0; index < firstTwelveDigits.length; index++) {
      const character = firstTwelveDigits[index];
      switch (index) {
        case 3:
          phoneNumber = phoneNumber + separator;
          break;
        case 6:
          phoneNumber = phoneNumber + separator;
          break;
      }
      phoneNumber = phoneNumber + character;
    }

    this.control.setValue(phoneNumber);
  }
}
