import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { JoinActions } from './join.actions';
import { delay, exhaustMap, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ValidateSucceededResponseObject } from '../types/types';
import { Operation, OperationExecutePayload } from '@aaa/interface-joinRenew-joinRenewLib';
import { parseDateToApi } from '../utils/parse-date-to-api';
import { FormGroupValue } from '../../modules/share/form.utils';
import { getJoinFormOfferSummary, getJoinFormPage, getJoinFormSelectedLevel } from './join.selectors';
import { getPayment } from '@aaa/emember/store-payment';
import { JoinForm, JoinPage, JoinPayParams } from './join.models';
import _map from 'lodash/map';
import { PaymentForm } from '@aaa/emember/share/payment-form';
import {
  Membership,
  MembershipMGetOfferingsEventPayload,
  MembershipMMethod,
  MembershipMOperationExecuteJoinEventPayload,
  MembershipMValidateJoinEventPayload,
  MembershipOffering,
  MUserData,
} from '@aaa/interface-joinRenew-membership-membershipM';
import { M } from '../m.type';
import { ExecuteService } from '../services/execute.service';
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from '@aaa/interface-joinRenew-payment-paymentCybersource';
import { ConfirmedMember } from '../../modules/share/membership-card-detail-list/types';
import { ClubApp } from '@aaa/emember/types';
import { checkCybersourcePaymentValidation } from '../check-cybersource-payment-validation';
import { checkMembershipErrorsMSystem } from '../check-membership-errors-m-system';
import { MembershipOfferSummary } from '../price-offers/helpers/types';
import { MembershipsLevel, PriceOffersActions, PriceOffersQuery } from '@aaa/emember/store-price-offers';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { checkOperationErrorsMSystem } from '../check-operation-errors-m-system';
import { Cybersource } from '../cybersource.type';
import { DataLayerService } from '../../modules/share/services/data-layer.service';
import { AnalyticsPurchaseEvent } from '../../../types/analytics-purchase-event';
import { getMembershipLevels, getMembershipOffering } from '../price-offers/helpers/parse-price-offer-m-system';
import { getClubId } from '@aaa/emember/store-membership';
import { convertMembership } from '../price-offers/helpers/m-membership';
import { mergeMembershipOffers } from '../price-offers/helpers/merge-membership-offers';
import { AppAnalyticsEvents } from '../../../types/analytics-events';
import { getTransactionId } from '../utils/get-transaction-id';

@Injectable({ providedIn: 'root' })
export class JoinMSystemEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Northampton, ClubApp.Shelby]));
  executeService = inject(ExecuteService);
  dataLayer = inject(DataLayerService);

  updateSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.recostValidateSucceeded),
      switchMap(response => of(response).pipe(withLatestFrom(this.store.select(getClubId)))),
      mergeMap(([{ response }, clubId]) => {
        const { validationMembership, membershipOffering } = response as {
          validationMembership: Membership;
          membershipOffering: MembershipOffering[];
        };
        const totalCost = Number(validationMembership?.DuesTotal[0].$.duesAmount || 0);
        const levelOffers = getMembershipOffering(membershipOffering);
        const levels = getMembershipLevels(membershipOffering, clubId);
        const newUpdateMembershipOffer = convertMembership(validationMembership);
        const membershipType = validationMembership.PrimaryMember[0].$.membershipType;

        levelOffers[membershipType] = mergeMembershipOffers(levelOffers[membershipType], newUpdateMembershipOffer);

        return [PriceOffersActions.update({ levels, levelOffers }), JoinActions.setTotalCost({ totalCost })];
      })
    )
  );

  setConfirmedMembersSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.paySucceeded),
      switchMap(action => of(action).pipe(withLatestFrom(this.store.select(getJoinFormSelectedLevel)))),
      map(([{ membership }, selectedLevel]) => {
        const members = this.getMembers(membership, selectedLevel);

        return JoinActions.setConfirmedMembers({ members });
      })
    )
  );

  recostValidateJoin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        JoinActions.recostValidate,
        JoinActions.updateRecostValidate,
        JoinActions.validatePromoCode,
        JoinActions.retryRecostValidate
      ),
      switchMap(({ formValues }) => {
        const query: PriceOffersQuery = {
          zipCode: formValues?.memberInfo?.account?.zipcode || '',
          promoCode: formValues?.memberInfo?.membership?.promoCode || '',
          couponCode: formValues?.memberInfo?.membership?.couponCode || '',
          programCode: formValues?.memberInfo?.membership?.programCode || '',
        };
        const getExecutionData$ = of(null).pipe(switchMap(() => this.getOfferingsExecutionData(query)));
        const getRecostValidation = (response: M.GetOfferingsResponseResponseObject) =>
          of(response.response.executionData).pipe(
            withLatestFrom(
              this.store.select(getJoinFormOfferSummary),
              this.store.select(getPayment),
              this.store.select(getJoinFormSelectedLevel),
              this.store.select(getJoinFormPage)
            ),
            switchMap(([executionData, offer, payment, level, page]) =>
              this.recostValidation(formValues, offer, executionData, payment, level, page).pipe(
                map(validationMembership => {
                  const actionPayload: ValidateSucceededResponseObject<M.RecostValidateComposeResponseObject> = {
                    executionData,
                    response: {
                      validationMembership,
                      membershipOffering: response.response.offeringsResponse.Result.MembershipOffering,
                    },
                  };
                  return JoinActions.recostValidateSucceeded(actionPayload);
                })
              )
            )
          );

        const result$ = of(null).pipe(
          switchMap(() => getExecutionData$),
          switchMap(response =>
            of(null).pipe(
              withLatestFrom(this.store.select(getClubId)),
              tap(([, clubId]) => {
                // Need to get offer from prev response to update state before to do recost and validate
                // this specifically for M System workflow
                const membershipOffering = response.response.offeringsResponse.Result.MembershipOffering;
                const levelOffers = getMembershipOffering(membershipOffering);
                const levels = getMembershipLevels(membershipOffering, clubId);
                this.store.dispatch(PriceOffersActions.update({ levels, levelOffers }));
              }),
              delay(10),
              switchMap(() => getRecostValidation(response))
            )
          ),
          catchError(error => of(JoinActions.recostValidateFailed({ error })))
        );

        return result$;
      })
    )
  );

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.pay),
      exhaustMap(({ params }) =>
        this.pay(params).pipe(
          map(({ membership, payment }) => JoinActions.paySucceeded({ membership, payment })),
          catchError(error => of(JoinActions.payFailed({ error })))
        )
      )
    )
  );

  getOfferingsExecutionData(filter: PriceOffersQuery) {
    const payload: MembershipMGetOfferingsEventPayload = {
      method: MembershipMMethod.GET_OFFERINGS,
      promoCode: filter.couponCode || filter.promoCode,
      zip: filter?.zipCode || '',
    };

    return this.executeService.membershipQuery<M.GetOfferingsResponseResponseObject>(payload).pipe(
      map(validateObject => {
        const membershipError = validateObject?.meta?.isError;

        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject?.error, validateObject, !!filter.couponCode, !!filter.promoCode);
        }

        return validateObject;
      })
    );
  }

  recostValidation(
    formValues: FormGroupValue<JoinForm>,
    offers: MembershipOfferSummary,
    executionData: string,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
    selectedLevel: MembershipsLevel | null,
    page: JoinPage
  ) {
    const hasCouponCode = !!formValues.memberInfo?.membership?.couponCode;
    const hasPromoCode = !!formValues.memberInfo?.membership?.promoCode;
    const donorInfo: MembershipMOperationExecuteJoinEventPayload['joinData']['donorInfo'] = {
      address: {
        address1: formValues.memberInfo?.account?.address1 || '',
        address2: formValues.memberInfo?.account?.address2 || '',
        cityName: formValues.memberInfo?.account?.city || '',
        postalCode: formValues.memberInfo?.account?.zipcode || '',
        stateProv: formValues.memberInfo?.account?.state || '',
      },
      donorInformation: {
        firstName: formValues.memberInfo?.account?.firstName || '',
        lastName: formValues.memberInfo?.account?.lastName || '',
        namePrefix: formValues.memberInfo?.account?.suffix || '',
        middleInitial: '',
        email: formValues.memberInfo?.account?.email || '',
        homePhone: formValues.memberInfo?.account?.phone || '',
        businessPhone: formValues.memberInfo?.account?.phone || '',
        cellPhone: formValues.memberInfo?.account?.phone || '',
        nameSuffix: formValues.memberInfo?.account?.suffix || '',
      },
      giftOptions: {
        renewalType: 'P',
        sendMbrCardTo: 'D',
      },
    };
    const payload: MembershipMValidateJoinEventPayload = {
      executionData,
      method: MembershipMMethod.VALIDATE_JOIN,
      joinData: {
        gift: page === 'gift',
        donorInfo: page === 'gift' ? donorInfo : undefined,
        membershipType: selectedLevel?.membershipType || '',
        promoData: {
          promoCode: formValues.memberInfo?.membership?.promoCode || '',
          couponCode: formValues.memberInfo?.membership?.couponCode || '',
          programCode: formValues.memberInfo?.membership?.programCode || '',
        },
        options: offers.optional.map(({ code }) => code),
        autoRenew: !!payment.formValues.autoRenew,
        address: {
          address1: formValues.memberInfo?.account?.address1 || '',
          address2: formValues.memberInfo?.account?.address2 || '',
          stateProv: formValues.memberInfo?.account?.state || '',
          postalCode: formValues.memberInfo?.account?.zipcode || '',
          cityName: formValues.memberInfo?.account?.city || '',
        },
        userData: [
          {
            type: 'primary',
            firstName: formValues.memberInfo?.account?.firstName || 'fake first name',
            lastName: formValues.memberInfo?.account?.lastName || 'fake last name',
            dob: parseDateToApi(formValues.memberInfo?.account?.birthday || ''),
            email: formValues.memberInfo?.account?.email || '',
            homePhone: formValues.memberInfo?.account?.phone || '',
            options: offers.optionalPrimary.map(({ code }) => code),
          },
          ...(formValues.memberInfo?.membershipAssociates?.map<
            MembershipMValidateJoinEventPayload['joinData']['userData'][0]
          >((associate, associateIndex) => ({
            type: 'associate',
            firstName: associate.firstName || 'fake first name',
            lastName: associate.lastName || 'fake last name',
            dob: parseDateToApi(associate.birthday || ''),
            email: associate.email || '',
            homePhone: formValues.memberInfo?.account?.phone || '',
            options: (offers.associates[associateIndex]?.associateOptional || []).map(({ code }) => code),
          })) || []),
        ],
      },
    };

    return this.executeService.membershipQuery<M.RecostValidateResponseObject>(payload).pipe(
      map(validateObject => {
        const membershipError = !!validateObject?.meta?.isError;

        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject?.error, validateObject, hasCouponCode, hasPromoCode);
        }

        return validateObject.response.validateResponse.Result.Membership[0] || null;
      })
    );
  }

  pay(params: JoinPayParams) {
    const { formValues, payment, totalCost, executionData, selectedLevel, offers, page } = params;
    const hasCouponCode = !!formValues.memberInfo?.membership?.couponCode;
    const hasPromoCode = !!formValues.memberInfo?.membership?.promoCode;
    const paymentPayload: PaymentCybersourceOperationExecuteEventPayload = {
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(formValues?.billing?.billingTo?.address1),
          address2: String(formValues?.billing?.billingTo?.address2),
          administrativeArea: String(formValues?.billing?.billingTo?.state),
          buildingNumber: '',
          country: 'US',
          district: String(formValues?.billing?.billingTo?.state),
          email: String(formValues.memberInfo?.account?.email || 'fallback@avagate.com'),
          firstName: String(formValues.memberInfo?.account?.firstName),
          lastName: String(formValues.memberInfo?.account?.lastName),
          locality: String(formValues.memberInfo?.account?.city),
          phoneNumber: String(formValues.memberInfo?.account?.phone),
          postalCode: String(formValues.memberInfo?.account?.zipcode),
        },
        amountDetails: {
          totalAmount: String(totalCost),
          currency: 'USD',
        },
        creditCardBrandedName: payment.formValues?.card?.cardName || '',
      },
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
      operation: Operation.JOIN,
    };
    const donorInfo: MembershipMOperationExecuteJoinEventPayload['joinData']['donorInfo'] = {
      address: {
        address1: formValues.memberInfo?.account?.address1 || '',
        address2: formValues.memberInfo?.account?.address2 || '',
        cityName: formValues.memberInfo?.account?.city || '',
        postalCode: formValues.memberInfo?.account?.zipcode || '',
        stateProv: formValues.memberInfo?.account?.state || '',
      },
      donorInformation: {
        firstName: formValues.memberInfo?.account?.firstName || '',
        lastName: formValues.memberInfo?.account?.lastName || '',
        namePrefix: formValues.memberInfo?.account?.suffix || '',
        middleInitial: '',
        email: formValues.memberInfo?.account?.email || '',
        homePhone: formValues.memberInfo?.account?.phone || '',
        businessPhone: formValues.memberInfo?.account?.phone || '',
        cellPhone: formValues.memberInfo?.account?.phone || '',
        nameSuffix: formValues.memberInfo?.account?.suffix || '',
      },
      giftOptions: {
        renewalType: 'P',
        sendMbrCardTo: 'D',
      },
    };
    const membershipPayload: MembershipMOperationExecuteJoinEventPayload = {
      executionData: executionData,
      method: MembershipMMethod.OPERATION_EXECUTE,
      operation: Operation.JOIN,
      joinData: {
        gift: page === 'gift',
        donorInfo: page === 'gift' ? donorInfo : undefined,
        membershipType: selectedLevel?.membershipType || '',
        promoData: {
          promoCode: formValues.memberInfo?.membership?.promoCode || '',
          couponCode: formValues.memberInfo?.membership?.couponCode || '',
          programCode: formValues.memberInfo?.membership?.programCode || '',
        },
        options: offers.optional.map(({ code }) => code),
        autoRenew: !!payment.formValues.autoRenew,
        address: {
          address1: formValues.memberInfo?.account?.address1 || '',
          address2: formValues.memberInfo?.account?.address2 || '',
          stateProv: formValues.memberInfo?.account?.state || '',
          postalCode: formValues.memberInfo?.account?.zipcode || '',
          cityName: formValues.memberInfo?.account?.city || '',
        },
        userData: [
          {
            ...this.getPrimaryInfo(page, formValues, offers),
          },
          ...(formValues.memberInfo?.membershipAssociates?.map<
            MembershipMValidateJoinEventPayload['joinData']['userData'][0]
          >((associate, associateIndex) => ({
            type: 'associate',
            firstName: associate.firstName || '',
            lastName: associate.lastName || '',
            dob: parseDateToApi(associate.birthday || ''),
            email: associate.email || '',
            homePhone: formValues.memberInfo?.account?.phone || '',
            options: [
              ...(offers.associates[associateIndex]?.associateOptional || []).map(({ code }) => code),
              ...(offers.associates[associateIndex]?.associate || []).map(({ code }) => code),
            ],
          })) || []),
        ],
      },
    };
    const payload: OperationExecutePayload = {
      membershipEvent: membershipPayload,
      operation: Operation.JOIN,
      paymentEvent: paymentPayload,
    };

    return this.executeService.execute<M.ExecuteResponseObject, Cybersource.ExecutePaymentResponseObject>(payload).pipe(
      map(({ validateObject, paymentObject, operationObject }) => {
        const paymentError = !!paymentObject?.meta?.isError;
        if (paymentError) {
          checkCybersourcePaymentValidation(paymentObject.error);
        }

        const analyticsEventParams: AnalyticsPurchaseEvent['eventParams'] = {
          currency: 'USD',
          transaction_id: getTransactionId(paymentObject),
          value: totalCost,
          items: [
            {
              quantity: 1,
              item_id: 'primary',
              price: totalCost,
              item_name: page === 'join' ? AppAnalyticsEvents.JoinNew : AppAnalyticsEvents.GiftNew,
            },
          ],
          context: 'ava-store ' + (page === 'join' ? AppAnalyticsEvents.JoinNew : AppAnalyticsEvents.GiftNew),
          membershipLevel: selectedLevel.level,
        };
        this.dataLayer.purchaseEvent(analyticsEventParams);

        const membershipError = validateObject?.meta?.isError;
        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject?.error, validateObject, hasCouponCode, hasPromoCode);
        }

        const operationError = !!operationObject?.meta?.isError;
        if (operationError) {
          checkOperationErrorsMSystem(operationObject.error, operationObject);
        }

        return { membership: validateObject, payment: paymentObject };
      })
    );
  }

  getPrimaryInfo(page: JoinPage, formValues: FormGroupValue<JoinForm>, offers: MembershipOfferSummary): MUserData {
    return {
      type: 'primary',
      firstName: page === 'gift' ? formValues.giftInfo?.account?.firstName : formValues.memberInfo?.account?.firstName,
      lastName: page === 'gift' ? formValues.giftInfo?.account?.lastName : formValues.memberInfo?.account?.lastName,
      dob:
        page === 'gift'
          ? parseDateToApi(formValues.giftInfo?.account?.birthday || '')
          : parseDateToApi(formValues.memberInfo?.account?.birthday || ''),
      email: page === 'gift' ? formValues.giftInfo?.account?.email : formValues.memberInfo?.account?.email,
      homePhone: page === 'gift' ? formValues.giftInfo?.account?.phone : formValues.memberInfo?.account?.phone,
      options: offers.optionalPrimary.map(({ code }) => code),
    };
  }

  getMembers(payload: M.ExecuteResponseObject, selectedLevel: MembershipsLevel | null): ConfirmedMember[] {
    const members = [
      ...(payload.response.response.Result?.Membership[0]?.PrimaryMember || []),
      ...(payload.response.response.Result?.Membership[0]?.AssociateMember || []),
    ];

    return _map(members, item => {
      return {
        fullName: [item.$.firstName, item.$.lastName, item.$.nameSuffix].join(' '),
        membershipUserId: item.$.email,
        membershipNumber: item.$.membershipNumber,
        membershipLabel: selectedLevel?.name || '',
        expirationDate: item.$.dob, // yyyy/mm/dd
      } as ConfirmedMember;
    });
  }
}
