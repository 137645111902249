import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { AppModule } from './app/bootstrap/app/app.module';
import { CustomElementModule } from './app/bootstrap/custom-element/custom-element.module';
import { WebEnvironment, WindowMetaData } from './app/interfaces/window';

if (!environment) {
  throw 'app main.ts cannot find environment';
}
if (environment.production) {
  enableProdMode();
}

declare global {
  interface Window {
    ga: any;
    metaData: WindowMetaData;
    google_trackConversion: any;
    Flex: any;
    formloaded: boolean;
    iFrameResize: any;
    baseHref: string;
    chat: any;
    Quiq: any;
    kpde: {
      server: string;
    };
  }
}

/*
try {
  const head = document.head;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.setAttribute('async', '');
  script.setAttribute('defer', '');
  // script.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBPL0PQHj155YbOJfWSQb_NOXLRJ8lZis8');
  script.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCyuxSHgL1KJ5eLfBzJJ6ledX2fift-_y8');
  head.append(script);
} catch (e) {
  console.log(e);
}
*/

const memberNumber = [
  '4290230520263017', // 0, 0 associates
  '4290230391553918', // 1, plus, active, expires 1/1/23, 1 associate, autoRenew OFF WONKY
  '4290238611129815', // 2, plus, active, expires 1/1/23, 1 associate, autoRenew OFF
  // 2, error code 023 "We're sorry, we are unable to display your membership information at this time."
  '4290230000019913', // 3, 0 associates
  '4290230000123616', // 4, plus, active, expires 10/1/23, 1 associate, autoRenew OFF
  '4290230000131411', // 5, classic, active, expires 10/1/23, 1 associate, autoRenew OFF
  '4290230000220214', // 6, classic, active, expires 10/1/23, 0 associates, autoRenew ON
  '4290230241671514', // 7, 0 associates
  '4290230235873613', // 8, plus, active, expires 1/1/23, 2 associates, autoRenew ON
  // "4290230241674914", // 9, plus, active, expires 12/1/23, 1 associate, autoRenew ON
  '4290230241674914', // 9, Premier, active, expires 12/1/23, 1 associate, autoRenew ON, upgraded from plus for $60
  '4290230000236418', // 10, classic, pending, expired 10/1/22, 1 associate, autoRenew OFF
  // 10, renewal is free associate, upgrades are at discounted costs
  '4290230000236426', // 11
  '4290230000330310', // 12, PLUS, PENDING, expired 10/01/2022, 0 associates, autoRenew OFF
  '4290230000331813', // 13, plus, pending, expired 10/1/22, 1 associate, autoRenew ON >> renewed
  '4290230000362313', // 14, plus, pending, expired 10/1/22, 1 associate, autoRenew OFF >> active expires 10/1/23 >> autoRenew ON
  '4290230000359210', // 15, classic, pending, expires 6/1/23, 1 associate, autoRenew ON >> renewed
  '4290230241675614', // 16,
  '4290230241675713', // 17,
  'guest', // 18
  '4290230241671613', // 19
  '4290230391553918', // 20
  '4290230394149219', // 21
  '4290230001169410', // 22 AR ON with one associate
  '4290230001316417', // 23 AR OFF no associates, in Pending >> AR ON
  '4290230241672611', // 24
  '4290230391553918', // 25
  '4290230001169410', // 26
  '4290230536712213', // 27
  '4290238038888811', // 28
  '4290230465826018', // 29
  '4290230241680119', // 30
  '4290230241680036', // 31
  '4290230241679913', // 32
  '4290230241679616', // 33
  '4290230241679715', // 34
  '4290230241679517', // 35
  '4290230000305411', // 36
  '4290230241675713', // 37
  '4290230000227912', // 38
  '4290230572234817', // 39 canceled user
  '4290230000931414', // 40 active user
  '4290238110089619', // 41 active user
  '4290230241063316', // hoosier in pending
  '4382380000419008', // 43
  '4382383098795008', // 44
  '4382383098797004', // 45
  '4382389271793033', // 46
  '4290711905037902', // 47
  '4382382895285007', // 48 midstates mzp
];
// console.log(environment)

if (environment.ngServe) {
  window.metaData = {
    appMode: true,
    clubId: '023',
    origin: 'https://test.hoosier.aaa.com',
    // clubId: "188",
    // origin: "https://test.shelbycounty.aaa.com",
    user: {
      firstName: 'Lowell',
      lastName: 'Evergreen',
      email: 'lowell.evergreen@avagate.com',
      memberNumber: memberNumber[48],
      id: '1',
      roles: [],
      status: 'active',
      token: '',
      zipcode: '17402',
    },
    webEnv: WebEnvironment.LOCAL,
  };

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
} else if (/[.a-z]*millisite/.test(window.location.hostname.toLowerCase())) {
  const clubName = window.location.hostname.split('.')[1].toLowerCase();
  interface ClubMap {
    [key: string]: string;
  }
  const clubIdMap: ClubMap = {
    colorado: '006',
    hoosier: '023',
    minneapolis: '057',
    northway: '104',
    southjersey: '071',
    southpa: '237',
    shelby: '160',
    shelbycounty: '160',
    northampton: '188',
  };

  window.metaData = {
    appMode: true,
    clubId: clubIdMap[clubName],
    origin: window.location.origin.toLowerCase().replace('millisite', 'aaa'),
    user: {
      firstName: 'Lowell',
      lastName: 'Evergreen',
      email: 'lowell.evergreen@avagate.com',
      memberNumber: '4290230235873613',
      id: '1',
      roles: [],
      status: 'notFound',
      token: '',
      zipcode: '17402',
    },
    webEnv: WebEnvironment.APP_TEST,
  };

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
} else {
  platformBrowserDynamic()
    .bootstrapModule(CustomElementModule)
    .catch(err => console.error(err));
}
