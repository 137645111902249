import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { JoinActions } from './join.actions';
import { BehaviorSubject, exhaustMap, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Operation } from '@aaa/interface-joinRenew-joinRenewLib';
import { FormGroupValue } from '../../modules/share/form.utils';
import { ValidateSucceededResponseObject } from '../types/types';
import {
  MembershipMzpCustomer,
  MembershipMzpGetMembershipCostsJoin,
  MembershipMzpMethod,
  MembershipMzpOperationExecuteEventPayload,
  MzpMembershipLevel,
} from '@aaa/interface-joinRenew-membership-membershipMzp';
import { getPayment } from '@aaa/emember/store-payment';
import { JoinForm, JoinPayParams } from './join.models';
import { DrupalNewLoginAccountCredentials } from '@aaa/interface-joinRenew-membership-membershipMzp';
import { encryptPassword } from '../utils/encrypt-password';
import { Mzp } from '../mzp.type';
import { twoDigitNumber } from '../../modules/share/utils/two-digit-number';
import { PaymentForm } from '@aaa/emember/share/payment-form';
import { ConfirmedMember } from '../../modules/share/membership-card-detail-list/types';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { RequestError, RequestErrorType } from '../generic-errors';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { PriceOffersActions } from '@aaa/emember/store-price-offers';
import { getAssociatesMember, getOptionalOffers, getPrimaryMember } from '../mzp-utils';
import { checkCybersourcePaymentValidation } from '../check-cybersource-payment-validation';
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from '@aaa/interface-joinRenew-payment-paymentCybersource';
import { checkMembershipErrorsMzpSystem } from '../check-membership-errors-mzp-system';
import { DataLayerService } from '../../modules/share/services/data-layer.service';
import { AnalyticsPurchaseEvent } from '../../../types/analytics-purchase-event';
import { getClearCacheSettings } from '../utils/get-cache-settings';
import { Cybersource } from '../cybersource.type';
import { AppAnalyticsEvents } from '../../../types/analytics-events';
import { getTransactionId } from '../utils/get-transaction-id';

@Injectable({ providedIn: 'root' })
export class JoinMzpSystemEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.MidStates]));
  executeService = inject(ExecuteService);
  dataLayer = inject(DataLayerService);

  reservationKey$ = new BehaviorSubject<Record<string, string>>({});
  updateSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.recostValidateSucceeded),
      mergeMap(({ response }: ValidateSucceededResponseObject<Mzp.MzpGetMembershipCostsJoinResponse>) => {
        const memberInfo = response.validationData;
        const membershipType = response.validationData.membership.coverageLevelCd;
        const totalCost = response?.validationData.balances?.find(cost => cost.type === 'Membership')?.amount || 0;
        const optionalOffers = [
          ...getOptionalOffers([getPrimaryMember(memberInfo)], 'primary'),
          ...getOptionalOffers(getAssociatesMember(memberInfo), 'associate'),
        ];

        return [
          PriceOffersActions.updateOptionals({
            membershipType,
            optionalOffers,
            filter: {
              addIfMissed: [
                { offering: 'associateOptional', code: 'MD' },
                { offering: 'optionalPrimary', code: 'MD' },
              ],
            },
          }),
          JoinActions.setTotalCost({ totalCost }),
        ];
      })
    )
  );

  setConfirmedMembersSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.paySucceeded),
      map(({ membership }) => this.getMembers(membership)),
      map(members => JoinActions.setConfirmedMembers({ members }))
    )
  );

  recostValidateJoin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        JoinActions.recostValidate,
        JoinActions.updateRecostValidate,
        JoinActions.validatePromoCode,
        JoinActions.retryRecostValidate
      ),
      exhaustMap(({ formValues }) =>
        of(formValues).pipe(
          switchMap(action => of(action).pipe(withLatestFrom(this.store.select(getPayment)))),
          switchMap(([formValues, payment]) =>
            this.recostValidation(formValues, payment).pipe(
              map(res => JoinActions.recostValidateSucceeded(res)),
              catchError(error => of(JoinActions.recostValidateFailed({ error })))
            )
          )
        )
      )
    )
  );

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.pay),
      exhaustMap(({ params }) =>
        this.pay(params).pipe(
          map(({ membership, payment }) => JoinActions.paySucceeded({ membership, payment })),
          catchError(error => of(JoinActions.payFailed({ error })))
        )
      )
    )
  );

  getReservationKey = (key?: string) => {
    if (key && this.reservationKey$?.value[key]) {
      return String(this.reservationKey$?.value[key]);
    }

    return '';
  };

  setReservationKey = (key?: string, value?: string) => {
    key && value && this.reservationKey$.next({ [key]: value });
  };

  pay(params: JoinPayParams) {
    const { formValues, totalCost, payment, executionData, selectedLevel, page } = params;
    const loginCredentials: DrupalNewLoginAccountCredentials = {
      email: formValues.memberInfo?.account?.email,
      encryptedPassword: encryptPassword(formValues.memberInfo?.account?.password),
      zip: formValues.memberInfo?.account?.zipcode,
      iso: '',
      club: '',
      household: '',
      associate: '',
      check_digit: '',
    };
    const paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(formValues?.billing?.billingTo?.address1),
          address2: String(formValues?.billing?.billingTo?.address2),
          administrativeArea: String(formValues?.billing?.billingTo?.state),
          buildingNumber: '',
          country: 'US',
          district: String(formValues?.billing?.billingTo?.state),
          email: String(formValues.memberInfo?.account?.email || 'fallback@avagate.com'),
          firstName: String(formValues.memberInfo?.account?.firstName),
          lastName: String(formValues.memberInfo?.account?.lastName),
          locality: String(formValues.memberInfo?.account?.city),
          phoneNumber: String(formValues.memberInfo?.account?.phone),
          postalCode: String(formValues.memberInfo?.account?.zipcode),
        },
        amountDetails: {
          totalAmount: String(totalCost),
          currency: 'USD',
        },
        creditCardBrandedName: payment.formValues?.card?.cardName || '',
      },
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
      operation: Operation.JOIN,
    };
    const membershipEvent: MembershipMzpOperationExecuteEventPayload = {
      loginCredentials,
      executionData,
      method: MembershipMzpMethod.OPERATION_EXECUTE,
      operation: Operation.JOIN,
    };
    const payload = {
      membershipEvent: membershipEvent,
      paymentEvent: paymentEvent,
      operation: Operation.JOIN,
    };

    return this.executeService
      .execute<Mzp.JoinExecuteResponseObject, Cybersource.ExecutePaymentResponseObject>(payload)
      .pipe(
        map(({ paymentObject, validateObject }) => {
          const error = !paymentObject && !validateObject;
          const paymentError = !!paymentObject?.meta.isError;

          if (error) {
            // sometimes when email it is invalid
            throw new RequestError(RequestErrorType.PaymentInvalidData, { reason: 'INVALID_DATA' });
          }

          if (paymentError) {
            checkCybersourcePaymentValidation(paymentObject.error);
          }

          const analyticsEventParams: AnalyticsPurchaseEvent['eventParams'] = {
            currency: 'USD',
            transaction_id: getTransactionId(paymentObject),
            value: totalCost,
            items: [
              {
                quantity: 1,
                item_id: 'primary',
                price: totalCost,
                item_name: page === 'join' ? AppAnalyticsEvents.JoinNew : AppAnalyticsEvents.GiftNew,
              },
            ],
            context: 'ava-store ' + (page === 'join' ? AppAnalyticsEvents.JoinNew : AppAnalyticsEvents.GiftNew),
            membershipLevel: selectedLevel.level,
          };
          this.dataLayer.purchaseEvent(analyticsEventParams);

          const membershipError = !!validateObject?.meta?.isError;
          if (membershipError) {
            checkMembershipErrorsMzpSystem(validateObject.error, validateObject);
          }

          return { membership: validateObject, payment: paymentObject };
        })
      );
  }

  recostValidation(
    formValues: FormGroupValue<JoinForm>,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> }
  ): Observable<ValidateSucceededResponseObject<Mzp.MzpGetMembershipCostsJoinResponse>> {
    const customerAssociateData: MembershipMzpCustomer[] =
      (formValues?.memberInfo?.membershipAssociates || []).map((item, index) => {
        return {
          associateId: twoDigitNumber(index + 1),
          medical: item.accidentMedicalPlan,
          firstName: item.firstName || '',
          lastName: item.lastName || '',
          suffix: item.suffix || '',
          email: item.email || '',
          birthDt: item.birthday || '',
        };
      }) || [];
    const customerPrimaryData: MembershipMzpCustomer = {
      associateId: twoDigitNumber(0),
      medical: formValues.memberInfo?.account?.accidentMedicalPlan,
      firstName: formValues.memberInfo?.account?.firstName || 'fake name',
      lastName: formValues.memberInfo?.account?.lastName || 'fake name',
      suffix: formValues.memberInfo?.account?.suffix || '',
      email: formValues.memberInfo?.account?.email || '',
      birthDt: formValues.memberInfo?.account?.birthday || '',
      phones: [{ type: 'CELL', number: String(formValues.memberInfo?.account?.phone || '') }],
    };
    const payload: MembershipMzpGetMembershipCostsJoin = {
      promoData: {
        promoCode: formValues.memberInfo?.membership?.promoCode,
        couponCode: formValues.memberInfo?.membership?.couponCode,
        programCode: formValues.memberInfo?.membership?.programCode,
        reservationKey: this.getReservationKey(formValues.memberInfo?.membership?.couponCode),
      },
      zipcode: formValues.memberInfo?.account?.zipcode || '',
      membershipLevel: formValues.memberInfo?.membership?.membershipLevel as MzpMembershipLevel,
      rv: !!formValues.memberInfo?.membership?.rv,
      associateCount: formValues.memberInfo?.membershipAssociates?.length || 0,
      method: MembershipMzpMethod.GET_MEMBERSHIP_COSTS_JOIN,
      autoRenew: !!payment.formValues?.autoRenew,
      customers: [customerPrimaryData].concat(customerAssociateData),
      address: {
        addressLine1: formValues.memberInfo?.account?.address1 || '',
        addressLine2: formValues.memberInfo?.account?.address2 || '',
        city: formValues.memberInfo?.account?.city || '',
        state: formValues.memberInfo?.account?.state || '',
        postalCode: formValues.memberInfo?.account?.zipcode || '',
      },
    };

    if (payload.promoData?.couponCode || payload.promoData?.programCode) {
      payload.cacheSettings = getClearCacheSettings();
    }

    return this.executeService
      .membershipQuery<Mzp.JoinRecostValidationResponseObject, MembershipMzpGetMembershipCostsJoin>(payload)
      .pipe(
        tap(res => this.setReservationKey(payload.promoData?.couponCode || '', res.response?.reservationKey)),
        map(({ response, meta, error }) => {
          const isError = !!meta.isError;

          if (isError) {
            if (error.error && Array.isArray(error.error)) {
              const errorText = error.error[0];

              if (errorText.startsWith('Unable to re-evaluate membership')) {
                throw new RequestError(RequestErrorType.JoinFormError);
              }

              if (errorText.startsWith('The AAA coupon code is invalid')) {
                throw new RequestError(RequestErrorType.MembershipInvalidCouponCode);
              }

              if (errorText.startsWith('Invalid market code')) {
                throw new RequestError(RequestErrorType.MembershipInvalidPromoCode, response);
              }
            }

            throw new RequestError(RequestErrorType.JoinFormError);
          }

          const errorStatus = response.validationData.status;

          if (errorStatus === 'error' && response.validationData.error) {
            // Todo: need to check if promocode is invalid
            const error = response.validationData.error[0];

            if (error.startsWith('Invalid market code.')) {
              throw new RequestError(RequestErrorType.MembershipInvalidPromoCode, error);
            }

            throw new RequestError(RequestErrorType.JoinFormError);
          }

          const executionData = response?.executionData;

          return { response: response, executionData };
        })
      );
  }

  getMembers(payload: Mzp.JoinExecuteResponseObject): ConfirmedMember[] {
    const accountJoinInfo = new Mzp.ConfirmedMember(payload.response.mzpResponse);

    return accountJoinInfo.membershipCardData;
  }
}
