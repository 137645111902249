import firebase from 'firebase/compat/app';
import Timestamp = firebase.firestore.Timestamp;
import { SessionDocResponseObject } from '@aaa/emember/store-types';

export interface SessionDocObject extends SessionDocResponseObject {
  response: any;
  error: any;
}

export interface SessionDoc {
  debug: {
    PAYMENT_QUERY: string; // "ok",
    MEMBERSHIP_QUERY: string; // "ok",
    OPERATION_EXECUTE: string; // "ok"
  };
  responses: {
    membership: {
      connectsuite?: {
        [responseKey: string]: SessionDocObject;
      };
      mzp?: {
        [responseKey: string]: SessionDocObject;
      };
      m?: {
        [responseKey: string]: SessionDocObject;
      };
    };
    payment: {
      cybersource: {
        [responseKey: string]: SessionDocObject;
      };
    };
    operation: {
      defaultOperation: {
        [responseKey: string]: SessionDocObject;
      };
    };
  };
  ttl: Timestamp;
  updated: number;
}

export enum FirebaseAuthErrors {
  // Thrown if the email address is not valid.</dd>
  InvalidEmail = 'auth/invalid-email',
  // Thrown if the user corresponding to the given email has been
  UserDisabled = 'auth/user-disabled',
  // Thrown if there is no user corresponding to the given email.
  UserNotFound = 'auth/user-not-found',
  // Thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set.
  WrongPassword = 'auth/wrong-password',
}
