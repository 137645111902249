import { Component, Input } from '@angular/core';
import { FileManagerModule } from '../modules/file-manager/file-manager.module';

@Component({
  selector: 'ava-file-manager-custom-element',
  template: `
    <ava-file-manager [directory]="directory"></ava-file-manager>
  `,
  standalone: true,
  imports: [FileManagerModule],
})
export class FileManagerCustomElementComponent {
  @Input() directory: string = '';
}
