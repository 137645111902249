import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getClubId, getClubOption } from '@aaa/emember/store-membership';
import { ClubApp } from '@aaa/emember/types';
import { MembershipAssociateAddState } from './membership-associate-add.models';
import { RequestStatus } from '../../../types/request-status';
import { getErrorMessage } from '../generic-errors';
import { OfferFilter } from '../price-offers/helpers/types';
import { createMembershipOfferSummary } from '../price-offers/helpers/create-membership-offer-summary';
import { AccountDetailsSelectors } from '../../modules/share/ngrx-helpers/account-details.selectors';

export const MEMBERSHIP_ASSOCIATE_ADD_FEATURE_KEY = 'membership-associate-add';

export const getMembershipAssociateState = createFeatureSelector<MembershipAssociateAddState>(
  MEMBERSHIP_ASSOCIATE_ADD_FEATURE_KEY
);
export const getMembershipAssociateFormActiveStep = createSelector(
  getMembershipAssociateState,
  ({ formActiveStep }) => formActiveStep
);
export const getMembershipAssociateAddAccountDetails = createSelector(
  getMembershipAssociateState,
  ({ accountDetails }) => accountDetails
);
export const MembershipAssociateAddAccount = AccountDetailsSelectors(
  getMembershipAssociateAddAccountDetails,
  getClubOption
);
export const getSummaryOffer = createSelector(
  MembershipAssociateAddAccount.getIncludedOffers,
  getClubOption,
  (summary, option) => {
    if (option.clubId === ClubApp.MidStates) {
      return {
        ...summary,
        primary: null,
        associates: summary.associates.filter(a => a.associate.some(({ status }) => status === 'pending')),
      };
    }

    return summary;
  }
);
export const getMembershipAssociateFormValues = createSelector(
  getMembershipAssociateState,
  ({ formValues }) => formValues
);
export const getMembershipAssociateSummaryFilter = createSelector(getMembershipAssociateFormValues, formValues => {
  const filter: OfferFilter = {
    medicalRider: false,
    associates: (formValues?.associates || []).map(associate => {
      return { medicalRider: !!associate.accidentMedicalPlan };
    }),
    autoRenew: false,
    promoCode: false,
  };

  return filter;
});

export const getMembershipAssociateAddSummary = createSelector(
  MembershipAssociateAddAccount.getAvailableOffers,
  getMembershipAssociateSummaryFilter,
  createMembershipOfferSummary
);

export const getMessageNoAssociates = createSelector(getClubId, clubId => {
  if ([ClubApp.Shelby, ClubApp.Northampton].includes(clubId)) {
    return 'Your AAA Membership does not have an Associate Member. You may add Household Members when you renew your yearly Membership.';
  } else {
    return 'Your AAA Membership does not have an Associate Member. You may add Household Members to your yearly membership anytime.';
  }
});

export const getMembershipAssociateAddPaymentSummary = createSelector(
  MembershipAssociateAddAccount.getPaymentSummary,
  getMembershipAssociateFormValues,
  getClubId,
  (paymentSummary, formValues, clubApp) => {
    if ([ClubApp.Hoosier, ClubApp.MidStates].includes(clubApp) && formValues.associates?.length) {
      return paymentSummary.slice(paymentSummary.length - formValues.associates.length);
    }

    return paymentSummary;
  }
);

export const getMembershipAssociateExecutionData = createSelector(
  getMembershipAssociateState,
  ({ executionData }) => executionData
);
export const getMembershipAssociateValidationRequest = createSelector(
  getMembershipAssociateState,
  ({ validationRequestStatus }) => validationRequestStatus
);
export const getMembershipAssociateExecutionRequest = createSelector(
  getMembershipAssociateState,
  ({ executionRequestStatus }) => executionRequestStatus
);

export const getMembershipAssociateAddErrors = createSelector(
  getMembershipAssociateState,
  ({ executionError, validationError }) => validationError || executionError
);
export const getMembershipAssociateAddErrorsMessage = createSelector(
  getMembershipAssociateAddErrors,
  getClubOption,
  (error, options) => getErrorMessage(error, options)
);
export const getIsLoadingValidationRequest = createSelector(
  getMembershipAssociateValidationRequest,
  state => state === RequestStatus.RUNNING
);

export const getMembershipAssociateValidateFailed = createSelector(
  getMembershipAssociateValidationRequest,
  status => status === RequestStatus.FAILED
);

export const getIsLoadingExecutionRequest = createSelector(
  getMembershipAssociateExecutionRequest,
  state => state === RequestStatus.RUNNING
);
