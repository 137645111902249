import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatestWith, Observable, of, tap } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { ValidateStatus } from '../../join-renew.service';
import { InputOptions } from '../form-field-options/options-interfaces';
import { HOOSIER_RX_STATE, HoosierService, HoosierState, View } from '../hoosier.service';
import { NzOptionSelectionChange } from 'ng-zorro-antd/auto-complete';
import { usAutocomplete } from 'smartystreets-javascript-sdk';
import Suggestion = usAutocomplete.Suggestion;

@Component({
  selector: 'ava-hoosier-input',
  templateUrl: './input.html',
  providers: [RxEffects],
})
export class InputComponent implements OnInit {
  @Input() index: number | undefined;
  @Input() options: InputOptions | undefined;
  ValidateStatus = ValidateStatus;
  formGroup: FormGroup | undefined;
  formControl: FormControl | undefined;
  showLabel: boolean | undefined;

  constructor(
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private hoosierService: HoosierService
  ) {
    rxEffects.register(this.toggleValidators$);
  }

  toggleValidators$ = this.hoosierState.select('showAllValidationErrors').pipe(
    combineLatestWith(
      this.hoosierState.select('showActiveGroupValidationErrors'),
      this.hoosierState.select('activeView')
    ),
    tap(([showAllValidationErrors, showActiveGroupValidationErrors, activeView]: [boolean, boolean, View | null]) => {
      if (showAllValidationErrors || showActiveGroupValidationErrors) {
        if (this.formControl && this.options?.validators.length) {
          if (showAllValidationErrors) {
            this.formControl.addValidators(this.options.validators);
            this.formControl.addAsyncValidators(this.options.asyncValidators);
            this.formControl.updateValueAndValidity();
          }
          if (!showAllValidationErrors) {
            this.formControl.removeValidators(this.options.validators);
            this.formControl.removeAsyncValidators(this.options.asyncValidators);
          }

          if (this.options.validateGroup.find(validateGroupItem => activeView === validateGroupItem)) {
            if (showActiveGroupValidationErrors) {
              this.formControl.addValidators(this.options.validators);
              this.formControl.addAsyncValidators(this.options.asyncValidators);
              this.formControl.updateValueAndValidity();
            }
            if (!showActiveGroupValidationErrors) {
              this.formControl.removeValidators(this.options.validators);
              this.formControl.removeAsyncValidators(this.options.asyncValidators);
            }
          }
          /*
                        if (this.options.validateGroup[this.hoosierState.get("view")]) {
                          if (showActiveGroupValidationErrors) {
                            this.formControl.addValidators(this.options.validators)
                            this.formControl.updateValueAndValidity()
                          }
                          if (!showActiveGroupValidationErrors) {
                            this.formControl.removeValidators(this.options.validators)
                          }
                        }
            */
        }
      }
    })
  );

  get formControlValue$(): Observable<any> {
    return (
      this.formControl?.valueChanges.pipe(
        tap(formControlValue => {
          this.showLabel = formControlValue.length;

          /**
           * setValue is required when we have the same edit field viewable in more that one place simultaneously
           */
          // fieldForm.setValue(formControlValue, { emitEvent: false })
        })
      ) || of(null)
    );
  }

  ngOnInit(): void {
    if (this.options) {
      const fieldPath =
        this.index !== undefined ? [...this.options.fieldPath, this.index.toString()] : this.options.fieldPath;

      this.formGroup =
        (this.hoosierService.form.get(fieldPath) as FormGroup) || (this.hoosierService.form as FormGroup);
      this.formControl = this.formGroup.get(this.options.fieldName) as FormControl;
      this.showLabel = !!this.formControl?.value?.length;
      this.rxEffects.register(this.formControlValue$);
    }
  }

  enableValidators(): void {
    if (this.formControl && this.options?.validators.length) {
      this.formControl.addValidators(this.options.validators);
      this.formControl.addAsyncValidators(this.options.asyncValidators);
      this.formControl.updateValueAndValidity();
    }
  }

  compareFunction = (o1: Suggestion | string, o2: Suggestion): boolean => {
    if (o1) {
      return typeof o1 === 'string' ? o1 === o2.text : o1.text === o2.text;
    } else {
      return false;
    }
  };

  selectionChange(event: NzOptionSelectionChange): void {
    if (
      this.formControl &&
      this.options?.config.addressAutocompleteOptions &&
      event.source.active &&
      event.source.selected
    ) {
      /**
       * overwrite field with full addressAutocompleteOption.text field
       * so that the valueChanges listener knows which option to use for populating other address fields
       * then overwrite again with the street address value
       */
      this.formControl.setValue(event.source.nzValue);
      this.formControl.setValue(event.source.nzLabel);
    }
  }
}
