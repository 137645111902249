import { Component, Inject, Input, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonModule, DOCUMENT } from '@angular/common';
import { AnalyticsEvent, AnalyticsService } from '../../services/analytics';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MetaWindow, WebEnvironment } from '../../interfaces/window';
import { NzButtonModule } from 'ng-zorro-antd/button';

export interface ParamsObj {
  meta: ParamsMeta;
  options: ParamsOptions;
}

export interface ParamsMeta {
  clubId: string;
  user: {
    email: string;
    firstName: string;
    lastName: string;
    memberNumber: string;
    token: string;
    id: string;
  };
  webEnv: WebEnvironment;
}

export interface ParamsOptions {
  callBack: string;
  options: {
    value: string;
    token: string;
  }[];
  returnPath: string;
}

@Component({
  imports: [CommonModule, ReactiveFormsModule, NzButtonModule],
  selector: 'ava-account-email-sync',
  standalone: true,
  template: `
    <div style="padding-bottom: 10px">
      <div style="font-weight: 400; color: #d43f3a">! Our records indicate you have two email addresses on file.</div>
      <div>Please select the email address for your membership account, website login and email communications.</div>
    </div>

    <form [formGroup]="form">
      <div
        style="font-weight: 400; padding-bottom: 5px; display: grid; gap: 8px; grid-template-columns: min-content auto; align-items: center"
        *ngFor="let option of options!.options"
      >
        <input type="radio" name="email" formControlName="email" [id]="option.value" [value]="option.token" />
        <label [for]="option.value">
          {{ option.value }}
        </label>
      </div>
      <div>
        <button
          nz-button
          nzType="primary"
          type="submit"
          [disabled]="form!.pristine"
          [nzLoading]="submitted"
          (click)="onSubmit(form!.value.email); $event.preventDefault()"
        >
          Submit
        </button>
      </div>
    </form>
  `,
})
export class AccountEmailSyncComponent implements OnInit {
  @Input() params: string = '';
  paramsObj: ParamsObj | undefined;
  meta: ParamsMeta | undefined;
  options: ParamsOptions | undefined;
  form: FormGroup | undefined;
  submitted: boolean = false;
  window: MetaWindow;

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    private analyticsService: AnalyticsService,
    private formBuilder: FormBuilder,
    public domWindow: Window
  ) {
    this.window = domWindow as unknown as MetaWindow;
  }

  ngOnInit(): void {
    console.log(this.params);
    this.paramsObj = JSON.parse(this.params);
    this.meta = this.paramsObj?.meta;
    this.options = this.paramsObj?.options;
    // console.log(this.options)

    this.form = this.formBuilder.group({
      email: ['', Validators.required],
    });

    // // @ts-ignore
    // this.window.gtag('event', 'shown', {
    //   'event_category': 'email_preference',
    //   'event_label': 'multi_email_on_file',
    //   'value': 1,
    //   'send_to': this.window['metaData'].gaId
    // })

    const event: AnalyticsEvent = {
      eventCategory: 'email_preference',
      eventAction: 'shown',
      eventLabel: 'multi_email_on_file',
      eventValue: 1,
    };
    this.analyticsService.sendEvent(event);

    const eventCustomElement: AnalyticsEvent = {
      eventCategory: 'Custom Element',
      eventAction: 'Supported',
      eventLabel: '<ava-account-email-sync>' + window.location.origin.toLowerCase(),
      eventValue: 1,
    };
    this.analyticsService.sendEvent(eventCustomElement);
  }

  async onSubmit(option: string): Promise<void> {
    if (this.submitted) return;
    this.submitted = true;
    // console.log('OnSubmit() processing')

    const event: AnalyticsEvent = {
      eventCategory: 'email_preference',
      eventAction: 'selected',
      eventLabel: 'multi_email_on_file',
      eventValue: 1,
    };
    this.analyticsService.sendEvent(event);

    const headers = new HttpHeaders({});
    try {
      await this.http
        .request('POST', this.options?.callBack + option, {
          headers: headers,
        })
        .toPromise();
      // console.log('reloading')
      this.document.location.href = this.options?.returnPath.toLowerCase() || '';
      // console.log(result)
    } catch (e) {
      this.submitted = false;
      // console.log(e)
    }
  }
}
