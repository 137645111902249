import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MembershipRenewActions } from './membership-renew.actions';
import { Observable, of, withLatestFrom } from 'rxjs';
import { Operation, OperationExecutePayload } from '@aaa/interface-joinRenew-joinRenewLib';
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteOperationExecuteEventPayload,
  MembershipConnectSuiteRecostValidateRenewEventPayload,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { FormGroupValue } from '../../modules/share/form.utils';
import { getPaymentAutoRenewValue } from '@aaa/emember/store-payment';
import { AccountDetails, ValidateSucceededResponseObject } from '../types/types';
import { ConnectSuite } from '../connect-suite.type';
import { getMembershipRenewAccountDetails } from './membership-renew.selectors';
import { PaymentForm } from '@aaa/emember/share/payment-form';
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from '@aaa/interface-joinRenew-payment-paymentCybersource';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { RequestError, RequestErrorType } from '../generic-errors';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { MembershipRenewForm } from './membership-renew.models';
import { checkOperationErrorsConnectSuiteSystem } from '../check-operation-errors-connect-suite-system';
import { getClearCacheSettings } from '../utils/get-cache-settings';
import { DataLayerService } from '../../modules/share/services/data-layer.service';
import { AnalyticsPurchaseEvent } from '../../../types/analytics-purchase-event';
import { getTransactionId } from '../utils/get-transaction-id';
import { AppAnalyticsEvents } from '../../../types/analytics-events';

@Injectable({ providedIn: 'root' })
export class MembershipRenewConnectSuiteSystemEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Hoosier, ClubApp.SouthJersey]));
  executeService = inject(ExecuteService);
  dataLayer = inject(DataLayerService);

  setSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipRenewActions.recostValidateSucceeded),
      map(({ response }: ValidateSucceededResponseObject<ConnectSuite.MemberQuickRenewResponseObject>) => {
        const accountDetails = new ConnectSuite.AccountInfo(response.response.validationData);

        return MembershipRenewActions.setAccountDetails({ accountDetails });
      })
    )
  );

  recostValidateMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipRenewActions.recostValidate),
      withLatestFrom(this.store.select(getPaymentAutoRenewValue), this.store.select(getMembershipRenewAccountDetails)),
      switchMap(([, autoRenew, accountDetails]) =>
        this.recostValidate(autoRenew, accountDetails).pipe(
          map(res => MembershipRenewActions.recostValidateSucceeded(res)),
          catchError(error => of(MembershipRenewActions.recostValidateFailed({ error })))
        )
      )
    )
  );

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipRenewActions.pay),
      switchMap(({ params }) =>
        this.pay(params.accountDetails, params.formValues, params.executionData, params.totalCost, params.payment).pipe(
          map(() => MembershipRenewActions.paySucceeded({})),
          catchError(error => of(MembershipRenewActions.payFailed({ error })))
        )
      )
    )
  );

  pay(
    accountDetails: AccountDetails,
    formValues: FormGroupValue<MembershipRenewForm>,
    executionData: string,
    totalCost: number,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> }
  ) {
    const membershipEvent: MembershipConnectSuiteOperationExecuteEventPayload = {
        operation: Operation.UPDATE,
        cacheSettings: getClearCacheSettings(),
        executionData,
        method: MembershipConnectSuiteMethod.OPERATION_EXECUTE,
      },
      paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
        executionData: {
          flexMicroFormToken: payment.token,
          billTo: {
            address1: String(formValues?.billing?.billingTo?.address1),
            address2: String(formValues?.billing?.billingTo?.address2),
            administrativeArea: String(formValues?.billing?.billingTo?.state),
            buildingNumber: '',
            country: 'US',
            district: String(formValues?.billing?.billingTo?.state),
            email: String(accountDetails.email || 'fallback@avagate.com'),
            firstName: formValues?.billing?.billingTo?.firstName || '',
            lastName: formValues?.billing?.billingTo?.lastName || '',
            locality: String(formValues?.billing?.billingTo?.city),
            phoneNumber: String(accountDetails?.phone?.cell),
            postalCode: String(formValues?.billing?.billingTo?.zipcode),
          },
          amountDetails: {
            totalAmount: String(totalCost),
            currency: 'USD',
          },
          creditCardBrandedName: payment.formValues.card?.cardName || '',
        },
        method: PaymentCybersourceMethod.OPERATION_EXECUTE,
        operation: Operation.UPDATE,
      },
      payload: OperationExecutePayload = {
        membershipEvent,
        paymentEvent,
        operation: Operation.UPDATE,
      };

    return this.executeService.execute<ConnectSuite.MemberRenewResponseObject>(payload).pipe(
      map(({ validateObject, operationObject }) => {
        if (validateObject?.meta.isError) {
          throw new RequestError(RequestErrorType.RenewMembership, validateObject);
        }

        const operationError = !!operationObject?.meta?.isError;
        if (operationError) {
          checkOperationErrorsConnectSuiteSystem(operationObject.error, operationObject);
        }

        const analyticsEventParams: AnalyticsPurchaseEvent['eventParams'] = {
          currency: 'USD',
          transaction_id: getTransactionId(validateObject),
          value: totalCost,
          items: [{ quantity: 1, price: totalCost, item_id: 'primary', item_name: AppAnalyticsEvents.AccountRenewal }],
          context: 'ava-store ' + AppAnalyticsEvents.AccountRenewal,
        };
        this.dataLayer.purchaseEvent(analyticsEventParams);

        return validateObject;
      })
    );
  }

  recostValidate(
    autoRenew: boolean,
    accountDetails: AccountDetails | null
  ): Observable<ValidateSucceededResponseObject<ConnectSuite.ValidateRenewResponseObject>> {
    const payload: MembershipConnectSuiteRecostValidateRenewEventPayload = {
      method: MembershipConnectSuiteMethod.RECOST_VALIDATE_RENEW,
      autoRenew: autoRenew,
      memberNumber: accountDetails?.memberNumber || '',
      verificationData: {
        lastName: accountDetails?.lastName || '',
        postalCode: accountDetails?.address.zip || '',
      },
    };

    return this.executeService.membershipQuery<ConnectSuite.ValidateRenewResponseObject>(payload).pipe(
      map(validateObject => {
        const isError =
          validateObject.meta.isError ||
          validateObject.response.validationData?.membership?.attributes?.membershipStatus !== 'P';
        const executionData = validateObject?.response?.executionData || '';

        if (isError) {
          const membershipStatus = validateObject.response?.validationData?.membership?.attributes?.membershipStatus;
          let error: RequestError = new RequestError(RequestErrorType.Error);

          // 1. check if membership is cancelled
          if (!validateObject.meta.isError && membershipStatus === 'C') {
            error = new RequestError(RequestErrorType.MembershipCancelled);
          } else if (!validateObject.meta.isError && membershipStatus !== 'C') {
            // 2. check if membership is not expired yet
            error = new RequestError(RequestErrorType.MembershipNotExpiredYet);
          } else {
            const errorCode = validateObject.error?.errorCode?.toString() || validateObject?.error?.responseCode;

            switch (errorCode) {
              case '1':
              case '020': // INVALID_ID("020") // "The membership number is invalid."
                error = new RequestError(RequestErrorType.MembershipInvalidNumber);
                break;
              case '022': // MEMBERSHIP_NOT_FOUND("022")
                error = new RequestError(RequestErrorType.MembershipNotFound);
                break;
              case '023': // INVALID_MEMBERSHIP("023")
                error = new RequestError(RequestErrorType.MembershipInvalidNumber);
                break;
              case '2':
                error = new RequestError(RequestErrorType.MembershipInvalidLastName);
                break;
              case '3':
                error = new RequestError(RequestErrorType.MembershipInvalidZipCode);
                break;
            }
          }

          throw error;
        }

        return {
          executionData,
          response: validateObject,
        };
      })
    );
  }
}
