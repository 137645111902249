import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

@Component({
  standalone: true,
  selector: 'ava-skeleton',
  template: `
    <nz-skeleton-element
      nzType="button"
      *ngIf="isLoading; else showContent"
      [nzActive]="isActive"
      [nzSize]="size"
      [nzShape]="shape"
      nzBlock
    ></nz-skeleton-element>
    <ng-template #showContent>
      <ng-content></ng-content>
    </ng-template>
  `,
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  imports: [CommonModule, NzSkeletonModule],
})
export class AvaSkeletonComponent {
  @Input() type: 'button' | 'input' | 'avatar' | 'image' = 'input';
  @Input() block = true;
  @Input() isLoading = false;
  @Input() isActive = true;
  @Input() size: 'large' | 'small' | 'default' = 'default';
  @Input() shape: 'circle' | 'round' | 'default' = 'default';
}
