import { range } from '../../utils/range';
import {
  MembershipOffer,
  MembershipOfferItem,
  MembershipOfferSummary,
  OfferAssociateFiler,
  OfferFilter,
} from './types';

export const associateCountCondition = (component: MembershipOfferItem, options: { associateIndex: number }) => {
  if (component.conditions.length) {
    const associateIndex = options.associateIndex + 1;
    const executeConditions = component.conditions.filter(condition => {
      if (condition.apply === 'associateCount') {
        if (condition.operator === 'moreThanOrEqual' && associateIndex >= condition.value) return true;
        if (condition.operator === 'moreThan' && associateIndex > condition.value) return true;
        if (condition.operator === 'lessThanOrEqual' && associateIndex <= condition.value) return true;
        if (condition.operator === 'lessThan' && associateIndex > condition.value) return true;
        if (condition.operator === 'equal' && associateIndex === condition.value) return true;

        return false;
      }

      return true;
    });

    return executeConditions.length === component.conditions.length;
  }

  return true;
};

export const associateMedicalRiderCondition = (component: MembershipOfferItem, options: OfferAssociateFiler) => {
  if (component.conditions.length) {
    const medicalRider = options.medicalRider;
    const executeConditions = component.conditions.filter(condition => {
      if (condition.apply === 'medicalRider') {
        return condition.operator === 'equal' && medicalRider;
      }

      return true;
    });

    return executeConditions.length === component.conditions.length;
  }

  return true;
};

export const primaryMedicalRiderCondition = (component: MembershipOfferItem, options: OfferFilter) => {
  if (component.conditions.length) {
    const medicalRider = options.medicalRider;
    const executeConditions = component.conditions.filter(condition => {
      if (condition.apply === 'medicalRider') {
        return condition.operator === 'equal' && medicalRider;
      }

      return true;
    });

    return executeConditions.length === component.conditions.length;
  }

  return true;
};

export const primaryAutoRenewCondition = (component: MembershipOfferItem, options: OfferFilter) => {
  if (component.conditions.length) {
    const autoRenew = options.autoRenew;
    const executeConditions = component.conditions.filter(condition => {
      if (condition.apply === 'autorenew') {
        return condition.operator === 'equal' && autoRenew;
      }

      return true;
    });

    return executeConditions.length === component.conditions.length;
  }

  return true;
};

export const promoCodeCondition = (component: MembershipOfferItem, options: OfferFilter) => {
  if (component.conditions.length) {
    const promoCode = options.promoCode;
    const executeConditions = component.conditions.filter(condition => {
      if (condition.apply === 'promoCode') {
        return condition.operator === 'equal' && promoCode;
      }

      return true;
    });

    return executeConditions.length === component.conditions.length;
  }

  return true;
};

export function createMembershipOfferSummary(offerComponents: MembershipOfferItem[], options: OfferFilter) {
  const associatesOption = options.associates ? options.associates : [];
  const associateCount = options?.associates?.length || 0;
  const offers: MembershipOfferSummary = {
    primary: undefined,
    optionalPrimary: [],
    associates: [],
    optional: [],
  };

  offers.primary = offerComponents
    .filter(component => component.offering === 'primary')
    .filter(
      component =>
        primaryMedicalRiderCondition(component, options) &&
        primaryAutoRenewCondition(component, options) &&
        promoCodeCondition(component, options)
    )
    .map(component => ({
      amount: component.amount,
      code: component.code,
      description: component.description,
      selectedByDefault: component.selectedByDefault,
      offering: component.offering,
    }))[0];

  offers.optionalPrimary = offerComponents
    .filter(component => component.offering === 'optionalPrimary')
    .filter(
      component =>
        component.selectedByDefault ||
        (primaryMedicalRiderCondition(component, options) &&
          primaryAutoRenewCondition(component, options) &&
          promoCodeCondition(component, options))
    )
    .map(component => ({
      amount: component.amount,
      code: component.code,
      description: component.description,
      selectedByDefault: component.selectedByDefault,
      offering: component.offering,
    }));

  // add associate
  if (associatesOption.length) {
    const associates = offerComponents.filter(component => component.offering === 'associate');
    const associateOptionals = offerComponents.filter(component => component.offering === 'associateOptional');
    const associateComponent = range(associateCount).map(associateIndex => {
      const availableAssociates: MembershipOffer[] = associates
        .filter(
          component =>
            associateCountCondition(component, { associateIndex }) &&
            associateMedicalRiderCondition(component, associatesOption[associateIndex]) &&
            primaryAutoRenewCondition(component, options) &&
            promoCodeCondition(component, options)
        )
        .map(component => ({
          amount: component.amount,
          code: component.code,
          description: component.description,
          offering: 'associate',
          selectedByDefault: component.selectedByDefault,
        }));
      const availableAssociateOptionals: MembershipOffer[] = associateOptionals
        .filter(
          component =>
            associateCountCondition(component, { associateIndex }) &&
            associateMedicalRiderCondition(component, associatesOption[associateIndex]) &&
            primaryAutoRenewCondition(component, options) &&
            promoCodeCondition(component, options)
        )
        .map(component => ({
          amount: component.amount,
          code: component.code,
          description: component.description,
          offering: 'associateOptional',
          selectedByDefault: component.selectedByDefault,
        }));

      return {
        associate: availableAssociates,
        associateOptional: availableAssociateOptionals,
      };
    });

    offers.associates = associateComponent;
  }

  offers.optional = offerComponents
    .filter(component => component.offering === 'optional')
    .filter(component => component.selectedByDefault)
    .map(component => ({
      amount: component.amount,
      code: component.code,
      description: component.description,
      selectedByDefault: component.selectedByDefault,
      offering: component.offering,
    }));

  return offers;
}

export function getSummaryPrice(offers: MembershipOfferSummary) {
  let sum = offers.primary ? offers.primary.amount : 0;
  sum += offers.optionalPrimary.reduce((acc, m) => acc + m.amount, 0);
  sum += offers.optional.reduce((acc, m) => acc + m.amount, 0);
  sum += offers.associates.reduce((acc, { associate, associateOptional }) => {
    const sumAssociate = associate.reduce((acc, m) => acc + m.amount, 0);
    const sumAssociateOptional = associateOptional.reduce((acc, m) => acc + m.amount, 0);

    return acc + sumAssociate + sumAssociateOptional;
  }, 0);

  return sum;
}
