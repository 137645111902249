import { Injectable } from '@angular/core';

export interface Alignment {
  type: AlignmentType;
  label: string;
  alignContent: 'flex-end' | 'center' | 'flex-start';
  justifyContent: 'flex-start' | 'center' | 'flex-end' | 'left' | 'right';
  justifySelf: 'flex-start' | 'center' | 'flex-end';
  textAlign: 'left' | 'center' | 'right';
  icon: {
    theme: 'outline';
    type: 'align-left' | 'align-center' | 'align-right';
  };
}

export enum AlignmentType {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

@Injectable({
  providedIn: 'root',
})
export class AlignmentService {
  alignmentOptions: AlignmentType[] = [
    AlignmentType.LEFT,
    AlignmentType.CENTER,
    // AlignmentType.RIGHT
  ];

  alignment(alignmentType: AlignmentType): Alignment | undefined {
    return this.alignments.find(alignment => alignment.type === alignmentType);
  }

  get alignments(): Alignment[] {
    return [
      {
        type: AlignmentType.CENTER,
        label: 'Center',
        alignContent: 'center',
        justifyContent: 'center',
        justifySelf: 'center',
        textAlign: 'center',
        icon: {
          theme: 'outline',
          type: 'align-center',
        },
      },
      {
        type: AlignmentType.LEFT,
        label: 'Left',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        justifySelf: 'flex-start',
        textAlign: 'left',
        icon: {
          theme: 'outline',
          type: 'align-left',
        },
      },
      {
        type: AlignmentType.RIGHT,
        label: 'Right',
        alignContent: 'flex-end',
        justifyContent: 'flex-end',
        justifySelf: 'flex-end',
        textAlign: 'right',
        icon: {
          theme: 'outline',
          type: 'align-right',
        },
      },
    ];
  }
}
