import { ClubApp } from '@aaa/emember/types';
import { ClubOption } from '../../types/club-option';

export enum RequestErrorType {
  Error = 'Error',
  UpdateAddress = 'UpdateAddress',
  UpdatePhone = 'UpdatePhone',
  UpdateEmail = 'UpdateEmail',
  AddAssociate = 'AddAssociate',

  PaymentError = 'PaymentError',
  PaymentExceedsCreditLimit = 'PaymentExceedsCreditLimit',
  PaymentDeclined = 'PaymentDeclined',
  PaymentExpiredCard = 'PaymentExpiredCard',
  PaymentInsufficientFund = 'PaymentInsufficientFund',
  PaymentInvalidCvv = 'PaymentInvalidCvv',
  PaymentInvalidData = 'PaymentInvalidData',
  PaymentPaymentRefused = 'PaymentPaymentRefused',
  PaymentMissingFields = 'PaymentMissingFields',
  PaymentCaptureContextError = 'PaymentCaptureContextError',
  PaymentTypeError = 'PaymentTypeError',
  PaymentGenerateTokenError = 'PaymentGenerateTokenError',

  RenewMembership = 'RenewMembership',
  MembershipPaymentDuplicateReceivedError = 'MembershipPaymentDuplicateReceivedError',
  UpgradedMembership = 'UpgradedMembership',
  UpgradedMembershipPayment = 'UpgradedMembershipPayment',
  AutoRenewMembership = 'AutoRenewMembership',
  RemoveAutoRenewMembership = 'RemoveAutoRenewMembership',
  PricePreviewError = 'PricePreviewError', // When Api Fails
  PricePreviewInvalidZipCodeError = 'PricePreviewInvalidZipCodeError',
  PricePreviewInvalidClubNumber = 'PricePreviewInvalidClubNumber',
  PricePreviewInvalidPromoCode = 'PricePreviewInvalidPromoCode',
  PricePreviewExpiredPromoCode = 'PricePreviewExpiredPromoCode',
  PricePreviewInvalidCouponCode = 'PricePreviewInvalidCouponCode',
  PricePreviewRedeemedCouponCode = 'PricePreviewRedeemedCouponCode',
  PricePreviewReservedCouponCode = 'PricePreviewReservedCouponCode',

  JoinFormError = 'JoinFormError',
  JoinFormMissingRequirementsError = 'JoinFormMissingRequirementsError',
  JoinFormMinAgeRequireError = 'JoinFormMinAgeRequireError',

  OperationError = 'OperationError',
  OperationTimeoutError = 'OperationTimeoutError',

  MembershipError = 'MembershipError',
  MembershipCancelled = 'MembershipCancelled',
  MembershipNotExpiredYet = 'MembershipNotExpiredYet',
  MembershipNotFound = 'MembershipNotFound',
  MembershipInvalidNumber = 'MembershipInvalidNumber',
  MembershipInvalidPromoCode = 'MembershipInvalidPromoCode',
  MembershipInvalidCouponCode = 'MembershipInvalidCouponCode',
  MembershipEnabledAutoRenew = 'MembershipEnabledAutoRenew',
  MembershipInvalidLastName = 'MembershipInvalidLastName',
  MembershipInvalidZipCode = 'MembershipInvalidZipCode',
  MembershipAddAssociateError = 'MembershipAddAssociateError',
  MembershipAddAssociateInvalidPromoCode = 'MembershipAddAssociateInvalidPromoCode',
  MembershipAlreadyExistingError = 'MembershipAlreadyExistError',
  MembershipDuplicateJoinsError = 'MembershipAlreadyExistError',

  AccountInfoInit = 'AccountInfoInit',
}
export class RequestError<Type = any, Data = any> {
  time = Date.now().valueOf();
  constructor(
    public type: RequestErrorType | Type,
    public data?: Data
  ) {}
}

export function getErrorMessage(error: RequestError<RequestErrorType> | null, option: ClubOption) {
  if (!error) {
    return '';
  }

  switch (error?.type) {
    case RequestErrorType.PaymentExceedsCreditLimit:
      return `The credit card has reached the credit limit.`;
    case RequestErrorType.PaymentExpiredCard:
      return `Please check the expiration date on your credit card for an invalid or expired number.`;
    case RequestErrorType.PaymentInsufficientFund:
      return `There are insufficient funds in the account.`;
    case RequestErrorType.PaymentInvalidCvv:
      return `Invalid card verification number. Please update the number.`;
    case RequestErrorType.PaymentDeclined:
      return `The card has been declined.`;
    case RequestErrorType.PaymentPaymentRefused:
      return `The transaction was declined by bank.`;
    case RequestErrorType.PaymentMissingFields:
      return `Missing card number. Please update the number.`;
    case RequestErrorType.PaymentInvalidData: //  Todo: need to check correct error message
      return `Invalid card data. Please update card information.`;
    case RequestErrorType.PaymentError:
      return `Your payment was unsuccessful. Please try again or reach out to Member Services at <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a>.`;
    case RequestErrorType.MembershipCancelled:
      return `Your membership is currently canceled. Please call Member services at <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> during business hours.`;
    case RequestErrorType.MembershipNotExpiredYet:
      return 'Sorry, your bill is not yet due. You will be able to renew your membership before your AAA membership expires.';

    case RequestErrorType.JoinFormError:
    case RequestErrorType.RenewMembership:
    case RequestErrorType.Error:
      return `We are experiencing technical difficulties. Please try again later or call member services at
        <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> for immediate support.`;
    case RequestErrorType.MembershipPaymentDuplicateReceivedError:
      return `Duplicate payments have been received today`;
    case RequestErrorType.MembershipInvalidCouponCode:
      return `This coupon code is not valid`;
    case RequestErrorType.PricePreviewInvalidPromoCode:
    case RequestErrorType.MembershipInvalidPromoCode:
    case RequestErrorType.MembershipAddAssociateInvalidPromoCode:
      return `This promo is not valid`;
    case RequestErrorType.PricePreviewExpiredPromoCode:
      return `This promo code is expired`;
    case RequestErrorType.MembershipEnabledAutoRenew:
      return `Your membership is currently in the process of being auto-renewed and no changes can be made online at this time. You must contact our office at <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> for changes to your membership.`;
    case RequestErrorType.OperationError:
    case RequestErrorType.OperationTimeoutError:
    case RequestErrorType.MembershipError:
      switch (option.clubId) {
        case ClubApp.MidStates:
          return `We are experiencing technical difficulties. Please try again later or call member services at <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> for immediate support.`;
        default:
          return `We are experiencing technical difficulties. </br>  Please call our club at <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> for further assistance.`;
      }
    case RequestErrorType.MembershipNotFound:
      switch (option.clubId) {
        case ClubApp.Hoosier:
          return `Sorry, your membership was not found.
        Please make sure the Primary's Membership Number, Last Name and ZIP Code you have entered are correct.
        If you are unable to access your online account, please call <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> during business hours.`;
        default:
          return `We cannot find member at this time.
              </br>
              Please call our club at <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> for further assistance.
            `;
      }
    case RequestErrorType.MembershipAlreadyExistingError:
    case RequestErrorType.MembershipDuplicateJoinsError:
      return `This membership already exists. Please call <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> for assistance.`;
    case RequestErrorType.MembershipAddAssociateError:
      return `We cannot add this associate at this time.
              </br>
              Please call our club at <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> for further assistance.
            `;
    case RequestErrorType.JoinFormMinAgeRequireError:
      return `We're sorry, the primary member is not within the age limit.`;
    case RequestErrorType.AddAssociate:
    case RequestErrorType.UpdatePhone:
    case RequestErrorType.UpdateAddress:
      return `Sorry, your changes could not be saved.`;

    default:
      return ``;
  }
}
