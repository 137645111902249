import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { getMedicalPlanFullDescription } from '@aaa/emember/store-membership';
import { AsyncPipe } from '@angular/common';
import { AppStore } from '@aaa/emember/store-types';

@Component({
  selector: 'ava-accidental-medical-coverage',
  template: `
    <div [innerHTML]="medicalPlanFullDescription$ | async"></div>
  `,
  styles: [
    `
      :host {
        display: block;
        padding: 1rem;
      }
    `,
  ],
  standalone: true,
  imports: [AsyncPipe],
})
export class AccidentalMedicalCoverageComponent {
  store = inject(Store<AppStore>);
  medicalPlanFullDescription$ = this.store.select(getMedicalPlanFullDescription);
}
