import { Component, OnInit } from '@angular/core';
import { BatteryQuoterService } from '../../battery-quoter.service';
import { AnalyticsEvent, AnalyticsScreenViewEvent, AnalyticsService } from '../../../../services/analytics';
import { CommonModule } from '@angular/common';
import firebase from 'firebase/compat/app';
import EventName = firebase.analytics.EventName;

@Component({
  selector: 'ava-no-results',
  templateUrl: './no-results.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class NoResultsComponent implements OnInit {
  constructor(
    public bs: BatteryQuoterService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    const event: AnalyticsEvent = {
      eventCategory: 'battery_quote',
      eventAction: 'no-results',
      eventLabel: 'battery',
      eventValue: 1,
    };
    this.analyticsService.sendEvent(event);

    const analyticsScreenViewEvent: AnalyticsScreenViewEvent = {
      eventName: EventName.SCREEN_VIEW,
      eventParams: {
        firebase_screen: 'Battery-Quote',
        firebase_screen_class: 'Battery-Quote',
        action: 'Quote-No-Results',
      },
    };
    this.analyticsService.sendFirebaseScreenViewEvent(analyticsScreenViewEvent);
  }
}
