import { MembershipOfferItem } from './types';

export function mergeMembershipOffers(offers: MembershipOfferItem[], offers2: MembershipOfferItem[]) {
  return offers.map(offer => {
    const itemUpdate = offers2.find(o => o.code === offer.code && o.offering === offer.offering);

    if (itemUpdate) {
      return {
        ...offer,
        ...itemUpdate,
        conditions: offer.conditions.length ? offer.conditions : itemUpdate.conditions,
      };
    }

    return offer;
  });
}
