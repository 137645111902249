import { Inject, Injectable, InjectionToken } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import { LOCATION, SESSION_STORAGE } from '@ng-web-apis/common';
import { combineLatestWith, debounceTime, filter, Observable, of, tap } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { FormService, FormStatus } from '../../../services/form';
import { ConnectsuiteService } from './services/connectsuite';
import { OpStatus } from '../join-renew.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { GLOBAL_RX_STATE, GlobalState, StateService } from '../../../services/state';
import { MemberLookupResponseObject } from './events/member-lookup';
import { ValidateJoinResponseObject } from './events/validate-join';
import { PricePreviewsResponse, PricePreviewsResponseObject } from './events/price-previews';
import { ValidateRenewResponseObject } from './events/validate-renew';
import { ExecuteMembership, ExecuteMembershipResponseObject } from './events/execute';
import {
  AnalyticsCustomEvent,
  AnalyticsPurchaseEvent,
  AnalyticsScreenViewEvent,
  AnalyticsService,
} from '../../../services/analytics';
import { ScriptsService } from '../../../services/scripts';
import { WebEnvironment } from '../../../interfaces/window';
import { ExecutePaymentResponseObject, PaymentErrorDescription } from './services/payment';
import { ValidateLevelResponseObject } from './events/validate-level';
import { AccountDetails } from './components/flows/account';
import { ValidateAssociatesResponseObject } from './events/validate-associates';
import { ValidateAutoRenewResponseObject } from './events/validate-auto-renew';
import {
  ConnectSuiteMembershipCode,
  connectSuiteMembershipCodes,
  ConnectSuitePrices,
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteRecostValidateChangeAREventPayload,
  MembershipConnectSuiteRecostValidateChangeAssociatesEventPayload,
  MembershipConnectSuiteRecostValidateChangeLevelEventPayload,
  MembershipConnectSuiteRecostValidateJoinEventPayload,
  MembershipConnectSuiteRecostValidateRenewEventPayload,
  MembershipPayloadAssociate,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from '@aaa/interface-joinRenew-payment-paymentCybersource';
import { CaptureContextResponseObject } from './components/common/payment-flex-micro-form';
import { compress as lzStringCompress, decompress as lzStringDecompress } from 'lz-string';
import { ValidateChangeAddressResponseObject } from './events/validate-address';
import { ValidateChangePhoneResponseObject } from './events/validate-phone';
import { TimeoutErrorService } from './services/timeout-error';
import { WindowRefService } from '../../share/services/window-ref.service';
import Timestamp = firebase.firestore.Timestamp;
import EventName = firebase.analytics.EventName;

export const HOOSIER_RX_STATE = new InjectionToken<RxState<HoosierState>>('HOOSIER_RX_STATE');

export type SummaryItem = { label: string; amount: number };

export interface HoosierState {
  form: FormGroup;
  formStatus: FormStatus;

  activeChange: number | null;
  activeDestination: Section | View | CheckoutStep | null;
  activeFlow: Flow | null;
  activeSection: Section | null;
  activeView: View | null;
  activeCheckoutStep: CheckoutStep | null;
  activeTasks: Task[];

  joinOperation: number | boolean;
  showActiveGroupValidationErrors: boolean;
  showAllValidationErrors: boolean;

  // EXECUTE: ExecuteOperationResponseObject
  // EXECUTE_ERROR: ExecuteOperationResponseObject
  EXECUTE_KEY: string | null;

  // Status for cyber source to generate token
  // sometimes take to long, and it needs to check the status before to move to the next action
  PAYMENT_CYBERSOURCE_GENERATE_TOKEN: OpStatus;

  MEMBERSHIP: ExecuteMembershipResponseObject | null;
  MEMBERSHIP_ERROR: ExecuteMembershipResponseObject | null;

  PAYMENT: ExecutePaymentResponseObject | null;
  PAYMENT_ERROR: ExecutePaymentResponseObject | null;
  PAYMENT_ERROR_MESSAGE: PaymentErrorDescription;

  PAYMENT_CAPTURE_CONTEXT: CaptureContextResponseObject | null;
  PAYMENT_CAPTURE_CONTEXT_ERROR: CaptureContextResponseObject | null;
  PAYMENT_CAPTURE_CONTEXT_KEY: string | null;
  PAYMENT_CAPTURE_CONTEXT_STATUS: OpStatus;
  PAYMENT_TOKEN_ERROR: any; // cybersource createToken error response

  MEMBER_LOOKUP: MemberLookupResponseObject | null;
  MEMBER_LOOKUP_ERROR: MemberLookupResponseObject | null;
  MEMBER_LOOKUP_KEY: string | null;
  MEMBER_LOOKUP_STATUS: OpStatus;

  MEMBER_LOOKUP_ADMIN: MemberLookupResponseObject | null;
  MEMBER_LOOKUP_ADMIN_ERROR: MemberLookupResponseObject | null;
  MEMBER_LOOKUP_ADMIN_KEY: string | null;
  MEMBER_LOOKUP_ADMIN_STATUS: OpStatus;

  PRICE_PREVIEWS: PricePreviewsResponseObject | null;
  PRICE_PREVIEWS_ERROR: PricePreviewsResponseObject | null;
  PRICE_PREVIEWS_KEY: string | null;
  PRICE_PREVIEWS_STATUS: OpStatus;

  pricePreviewsFunction: (
    pricePreviews: PricePreviewsResponse,
    level: string,
    rv: boolean,
    quantity: number
  ) => number | undefined;
  pricePreviewsResponse: PricePreviewsResponse;

  PROMO_CODE_STATUS: OpStatus;

  VALIDATE_JOIN: ValidateJoinResponseObject | null;
  VALIDATE_JOIN_ERROR: ValidateJoinResponseObject | null;
  VALIDATE_JOIN_KEY: string | null;
  VALIDATE_JOIN_STATUS: OpStatus;

  VALIDATE_RENEW: ValidateRenewResponseObject | null;
  VALIDATE_RENEW_ERROR: ValidateRenewResponseObject | null;
  VALIDATE_RENEW_KEY: string | null;
  VALIDATE_RENEW_STATUS: OpStatus;
  VALIDATE_CHANGE_ADDRESS: ValidateChangeAddressResponseObject | null;
  VALIDATE_CHANGE_ADDRESS_ERROR: ValidateChangeAddressResponseObject | null;
  VALIDATE_CHANGE_ADDRESS_KEY: string | null;
  VALIDATE_CHANGE_ADDRESS_STATUS: OpStatus;

  VALIDATE_CHANGE_PHONE: ValidateChangePhoneResponseObject | null;
  VALIDATE_CHANGE_PHONE_ERROR: ValidateChangePhoneResponseObject | null;
  VALIDATE_CHANGE_PHONE_KEY: string | null;
  VALIDATE_CHANGE_PHONE_STATUS: OpStatus;

  VALIDATE_LEVEL: ValidateLevelResponseObject | null;
  VALIDATE_LEVEL_ERROR: ValidateLevelResponseObject | null;
  VALIDATE_LEVEL_KEY: string | null;
  VALIDATE_LEVEL_STATUS: OpStatus;

  VALIDATE_ASSOCIATES: ValidateAssociatesResponseObject | null;
  VALIDATE_ASSOCIATES_ERROR: ValidateAssociatesResponseObject | null;
  VALIDATE_ASSOCIATES_KEY: string | null;
  VALIDATE_ASSOCIATES_STATUS: OpStatus;

  VALIDATE_AUTO_RENEW: ValidateAutoRenewResponseObject | null;
  VALIDATE_AUTO_RENEW_ERROR: ValidateAutoRenewResponseObject | null;
  VALIDATE_AUTO_RENEW_KEY: string | null;
  VALIDATE_AUTO_RENEW_STATUS: OpStatus;

  sessionDoc: HoosierSessionDoc;

  formIsLoaded: boolean;
  showFormPage: boolean;
  lastUpdated: Timestamp;

  rvSelected: boolean; // because form rv option defaults to false, does not have a null state
  // TODO: initialize with null, convert validateJoin to use false when null, and remove this boolean variable

  membershipCode: ConnectSuiteMembershipCode; // from the url (membershipLevel=LEVEL&rv=BOOLEAN) then selected from hard-coded list of membershipCodes

  accountDetails: AccountDetails;
  summary: {
    associates: MembershipPayloadAssociate[];
    associateFeeTotal: ConnectSuitePrices['associateFee'] | null; // multiplied by associateCount
    autoRenewDiscount: number | null;
    enrollFee: PricePreviewsResponse['enrollFee'] | null;
    membershipCodeLabel: ConnectSuiteMembershipCode['label'];
    primaryFee: ConnectSuitePrices['primaryFee'] | null;
    promotionalCode: string | null;
    solicitationDiscount: number | null;
    totalCost: number | null;

    items: SummaryItem[];
    balance: number | null;
  };
}

export interface HoosierForm {
  membershipPayload: MembershipConnectSuiteRecostValidateJoinEventPayload;
  paymentPayload: PaymentCybersourceOperationExecuteEventPayload;
  renewPayload: MembershipConnectSuiteRecostValidateRenewEventPayload;
  changeAssociatesPayload: MembershipConnectSuiteRecostValidateChangeAssociatesEventPayload;
  changeAutoRenewPayload: MembershipConnectSuiteRecostValidateChangeAREventPayload;
  changeLevelPayload: MembershipConnectSuiteRecostValidateChangeLevelEventPayload;

  agreeToTerms: boolean;
  password: string;

  useMemberInfoForBilling: boolean;

  creditCard: {
    expirationMonth: number | null;
    expirationYear: number | null;
    numberIsValid: boolean;
    securityCodeIsValid: boolean;
  };

  lastUpdated: Timestamp;
}

export interface HoosierSessionDoc {
  debug: {
    PAYMENT_QUERY: string; // "ok",
    MEMBERSHIP_QUERY: string; // "ok",
    OPERATION_EXECUTE: string; // "ok"
  };
  responses: {
    membership: {
      connectsuite: {
        [responseKey: string]: SessionDocResponseObject;
      };
    };
    payment: {
      cybersource: {
        [responseKey: string]: SessionDocResponseObject;
      };
    };
    operation: {
      defaultOperation: {
        [responseKey: string]: SessionDocResponseObject;
      };
    };
  };
  ttl: Timestamp;
  updated: number;
}

export interface SessionDocResponse {
  [responseKey: string]: SessionDocResponseObject;
}

export interface SessionDocResponseObject {
  // error: SessionDocError
  meta: {
    acknowledged: boolean;
    event: string;
    isError: boolean;
    method: string;
    operation: string;
    responseKey: string;
    updated: number;
  };
  // response: SessionDocResponseResponse
}

export enum Flow {
  ACCOUNT = 'ACCOUNT',
  GIFT = 'GIFT',
  JOIN = 'JOIN',
  QUICK_RENEW = 'QUICK_RENEW',
  RENEW = 'RENEW',
  MOBILE_APP_RENEW = 'MOBILE_APP_RENEW',
}

export type Section = AccountSection | JoinSection | QuickRenewSection;

export type View =
  | AccountFinancialView
  | AccountInformationView
  | AccountInsuranceView
  | AccountMembershipView
  | AccountTravelView
  | JoinView
  | QuickRenewView
  | MobileAppRenewView;

export type AccountViewLabel = {
  [key in
    | AccountInformationView
    | AccountMembershipView
    | AccountInsuranceView
    | AccountTravelView
    | AccountFinancialView]: string | undefined;
};

export enum RenewSection {}

export enum QuickRenewSection {
  CONFIRMATION = 'CONFIRMATION',
  LOGIN = 'LOGIN',
  VALIDATE = 'VALIDATE',
  EXECUTE = 'EXECUTE',
  SUMMARY_AND_PAYMENT = 'SUMMARY_AND_PAYMENT',
}

export enum QuickRenewView {
  CONFIRMATION = 'CONFIRMATION',
  LOGIN = 'LOGIN',
  VALIDATE = 'VALIDATE',
  EXECUTE = 'EXECUTE',
  SUMMARY_AND_PAYMENT = 'SUMMARY_AND_PAYMENT',
}

export enum JoinSection {
  CONFIRMATION = 'CONFIRMATION',
  DONOR_INFO = 'DONOR_INFO',
  EXECUTE = 'EXECUTE',
  MEMBER_INFO = 'MEMBER_INFO',
  PAYMENT = 'PAYMENT',
  PRICE_PREVIEW = 'PRICE_PREVIEW',
  RV_COVERAGE = 'RV_COVERAGE',
  SUMMARY = 'SUMMARY',
}

export enum JoinView {
  ASSOCIATES = 'ASSOCIATES',
  CONFIRMATION = 'CONFIRMATION',
  DONOR_INFO = 'DONOR_INFO',
  EXECUTE = 'EXECUTE',
  MEMBER_INFO = 'MEMBER_INFO',
  PAYMENT = 'PAYMENT',
  PRICE_PREVIEW = 'PRICE_PREVIEW',
  RV_COVERAGE = 'RV_COVERAGE',
  SUMMARY = 'SUMMARY',
}

export enum AccountSection {
  FINANCIAL = 'FINANCIAL',
  HOME = 'HOME',
  INFORMATION = 'INFORMATION',
  INSURANCE = 'INSURANCE',
  MEMBERSHIP = 'MEMBERSHIP',
  TRAVEL = 'TRAVEL',
}

export enum AccountInformationView {
  ADDRESS = 'ADDRESS',
  EMAIL = 'EMAIL',
  HOME = 'HOME',
  PASSWORD = 'PASSWORD',
  PHONE = 'PHONE',
}

export enum AccountMembershipView {
  ASSOCIATES = 'ASSOCIATES',
  AUTO_RENEW = 'AUTO_RENEW',
  CANCEL = 'CANCEL',
  CARD_SERVICES = 'CARD_SERVICES',
  GIFT = 'GIFT',
  HOME = 'HOME',
  LEVEL = 'LEVEL',
  RENEW = 'RENEW',

  ASSOCIATES_ADD = 'ASSOCIATES_ADD',
  ASSOCIATES_REMOVE = 'ASSOCIATES_REMOVE',
  AUTO_RENEW_CARD = 'AUTO_RENEW_CARD',
  AUTO_RENEW_REMOVE = 'AUTO_RENEW_REMOVE',
}

export enum MobileAppRenewView {
  RENEW = 'RENEW',
}

export enum CheckoutStep {
  CONFIRMATION = 'CONFIRMATION',
  EXECUTE = 'EXECUTE',
  PAYMENT = 'PAYMENT',
  VALIDATE = 'VALIDATE',
}

export enum Task {
  TRANSITION = 'TRANSITION',
  CONFIRMATION = 'CONFIRMATION',
  EXECUTE = 'EXECUTE',
  PAYMENT = 'PAYMENT',
  VALIDATE = 'VALIDATE',
}

export enum AccountInsuranceView {
  HOME = 'HOME',
}

export enum AccountTravelView {
  HOME = 'HOME',
}

export enum AccountFinancialView {
  HOME = 'HOME',
}

export enum DefaultView {
  HOME = 'HOME',
  PRICE_CHART = 'PRICE_CHART',
  ROADSIDE_ASSISTANCE = 'ROADSIDE_ASSISTANCE',
  REVIEW = 'REVIEW',
  CHECKOUT = 'CHECKOUT',
  MEMBER_INFO = 'MEMBER_INFO',
  PAYMENT = 'PAYMENT',
  SUMMARY = 'SUMMARY',
  SIDEBAR_SUMMARY = 'SIDEBAR_SUMMARY',
  RV_COVERAGE = 'RV_COVERAGE',
  RENEW = 'RENEW',
  GIFT_RECIPIENT = 'GIFT_RECIPIENT',
  CARD_SERVICES = 'CARD_SERVICES',
  AUTO_RENEW = 'AUTO_RENEW',
  MEMBERSHIP_LEVEL = 'MEMBERSHIP_LEVEL',
  ASSOCIATES = 'ASSOCIATES',
  ASSOCIATES_ADD = 'ASSOCIATES_ADD',
  CANCEL = 'CANCEL',
  GIFT = 'GIFT',
}

export interface MembershipCard {
  filePath: string;
  membershipLabel: ConnectSuiteMembershipCode['label'];
  textColor: string;
}

export interface MembershipCardData {
  expirationDate?: string;
  fullName: string;
  membershipLabel: string;
  membershipNumber?: string;
}

@Injectable({
  providedIn: 'root',
})
export class HoosierService {
  form: FormGroup;
  private hoosierSessionDocRef: AngularFirestoreDocument<HoosierSessionDoc> | undefined;

  constructor(
    private timeoutErrorService: TimeoutErrorService,
    // private validateJoinService: ValidateJoinService,
    private window: WindowRefService,
    @Inject(LOCATION)
    private location: Location,
    @Inject(SESSION_STORAGE)
    private sessionStorage: Storage,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private stateService: StateService,
    // private joinRenewService: JoinRenewService,
    private formService: FormService,
    private connectsuiteService: ConnectsuiteService,
    private afs: AngularFirestore,
    private analyticsService: AnalyticsService,
    private scriptsService: ScriptsService
  ) {
    const joinRenewFormData_SessionStorageEnabled = true;
    const hoosierState_SessionStorageEnabled = false;

    let ssHoosierState: HoosierState | undefined;
    const hoosierStateStored = sessionStorage.getItem('hState');
    if (hoosierStateStored) {
      if (
        !globalState.get('adminUser') &&
        !globalState.get('debugMode') &&
        globalState.get('windowMetaData', 'webEnv').toUpperCase() === WebEnvironment.PROD
      ) {
        const hoosierStateString = lzStringDecompress(hoosierStateStored);
        if (hoosierStateString) {
          ssHoosierState = this.parseJSONObject(hoosierStateString) as HoosierState;
        }
      }
      if (
        globalState.get('adminUser') ||
        globalState.get('debugMode') ||
        globalState.get('windowMetaData', 'webEnv').toUpperCase() !== WebEnvironment.PROD
      ) {
        ssHoosierState = this.parseJSONObject(hoosierStateStored) as HoosierState;
      }
    }

    const ssFormData: HoosierForm = ssHoosierState?.form as unknown as HoosierForm;
    if (
      joinRenewFormData_SessionStorageEnabled &&
      ssFormData &&
      ssFormData.lastUpdated.seconds + 30 * 60 > Timestamp.now().seconds && // 30 minute expiration
      stateService.previousPathname === location.pathname.toLowerCase() // prevents overlap of form data between different Flows in custom-element mode on page load
    ) {
      if (ssFormData.creditCard) {
        ssFormData.creditCard.numberIsValid = false;
        ssFormData.creditCard.securityCodeIsValid = false;
      }
      this.form = this.formService.newForm(ssFormData) as FormGroup;
      // console.log("using sessionStorageFormData")
      // console.log(ssFormData)
    } else {
      this.form = this.formService.newForm(this.newHoosierItem) as FormGroup;
    }

    // console.log(JSON.parse(sessionStorage.getItem("hoosierState")))
    if (
      hoosierState_SessionStorageEnabled &&
      ssHoosierState &&
      ssHoosierState.lastUpdated.seconds + 3600 > Timestamp.now().seconds // 60 minute expiration
    ) {
      // console.log("using sessionStorageHoosierState")
      // console.log(ssHoosierState)
      hoosierState.set(ssHoosierState);
    } else {
      hoosierState.set({
        activeTasks: [],
        // PAYMENT: true as unknown as ExecutePaymentResponseObject,
        form: this.form,
        rvSelected: sessionStorage.getItem('rvSelected') === 'true',
        showActiveGroupValidationErrors: false,
        showAllValidationErrors: false,
        activeCheckoutStep: null,
        MEMBER_LOOKUP: null,
        MEMBER_LOOKUP_STATUS: OpStatus.STOPPED,
        PRICE_PREVIEWS_STATUS: OpStatus.STOPPED,
        PROMO_CODE_STATUS: OpStatus.STOPPED,
        VALIDATE_JOIN_STATUS: OpStatus.STOPPED,
        VALIDATE_RENEW_STATUS: OpStatus.STOPPED,
        VALIDATE_LEVEL_STATUS: OpStatus.STOPPED,
        VALIDATE_ASSOCIATES_STATUS: OpStatus.STOPPED,
        VALIDATE_AUTO_RENEW_STATUS: OpStatus.STOPPED,
        lastUpdated: Timestamp.now(),
        summary: {
          associates: [],
          associateFeeTotal: null,
          autoRenewDiscount: null,
          enrollFee: null,
          membershipCodeLabel: '',
          primaryFee: null,
          promotionalCode: null,
          solicitationDiscount: null,
          totalCost: null,

          items: [],
          balance: null,
        },
      });
    }
    hoosierState.set('formIsLoaded', () => true);

    rxEffects.register(this.formStatus$);
    rxEffects.register(this.sessionId$);
    rxEffects.register(this.hoosierState$);
    rxEffects.register(this.rvSelected$);
    rxEffects.register(this.hideAllBlockRows$);
    rxEffects.register(this.analyticsFlowSectionViewEvent$);
    rxEffects.register(this.analyticsCheckoutStepEvent$);
  }

  get formStatus$(): Observable<any> {
    return this.form.statusChanges.pipe(
      tap(formStatus => {
        this.hoosierState.set('formStatus', () => FormStatus[formStatus]);
      })
    );
  }

  sessionId$ = this.globalState.select('userSession', 'sessionId').pipe(
    tap(sessionId => {
      if (sessionId) {
        this.hoosierSessionDocRef = this.afs
          .collection('wss-aaa-web')
          // .doc(globalState.get("windowMetaData", "clubId"))
          .doc('023')
          .collection('apps')
          .doc('join-renew')
          .collection('sessions')
          .doc(sessionId);
        this.rxEffects.register(this.sessionDoc$);
      }
    })
  );

  hoosierState$ = this.hoosierState.select().pipe(
    debounceTime(300),
    tap(hoosierState => {
      const stateStorage: { [key: string]: any } = {};
      for (const [key, value] of Object.entries(hoosierState)) {
        switch (key) {
          case 'form':
            stateStorage[key] = hoosierState[key].getRawValue();
            break;
          case 'lastUpdated':
            stateStorage[key] = Timestamp.now();
            break;
          default:
            stateStorage[key] = value;
        }
      }

      if (
        !this.globalState.get('adminUser') &&
        !this.globalState.get('debugMode') &&
        this.globalState.get('windowMetaData', 'webEnv').toUpperCase() === WebEnvironment.PROD
      ) {
        this.sessionStorage.setItem('hState', lzStringCompress(JSON.stringify(stateStorage)));
      }
      if (
        this.globalState.get('adminUser') ||
        this.globalState.get('debugMode') ||
        this.globalState.get('windowMetaData', 'webEnv').toUpperCase() !== WebEnvironment.PROD
      ) {
        // const state = stateStorage as HoosierState
        // console.table({
        //   flow: state.activeFlow,
        //   section: state.activeSection,
        //   view: state.activeView,
        //   checkout: state.activeCheckoutStep,
        //   destination: state.activeDestination
        // })
        this.sessionStorage.setItem('hState', JSON.stringify(stateStorage));
      }
    })
  );

  get sessionDoc$(): Observable<any | undefined> {
    if (this.hoosierSessionDocRef) {
      return this.hoosierSessionDocRef.valueChanges().pipe(
        tap(sessionDoc => {
          if (sessionDoc) {
            this.hoosierState.set('sessionDoc', () => sessionDoc);
          }
        })
      );
    }
    return of(undefined);
  }

  hideAllBlockRows$ = this.hoosierState.select('activeFlow').pipe(
    combineLatestWith(this.hoosierState.select('activeSection')),
    tap(([activeFlow, activeSection]: [Flow | null, Section | null]) => {
      switch (activeFlow) {
        case Flow.JOIN:
        case Flow.GIFT:
          switch (activeSection) {
            case JoinSection.PRICE_PREVIEW:
              this.globalState.set('hideAllBlockRows', () => false);
              return;
          }
          break;
        case Flow.ACCOUNT:
        case Flow.QUICK_RENEW:
        case Flow.RENEW:
      }
      this.globalState.set('hideAllBlockRows', () => true);
    })
  );

  rvSelected$ = this.hoosierState.select('rvSelected').pipe(
    tap(rvSelected => {
      this.sessionStorage.setItem('rvSelected', rvSelected ? 'true' : 'false');
    })
  );

  analyticsFlowSectionViewEvent$ = this.hoosierState.select('activeFlow').pipe(
    combineLatestWith(this.hoosierState.select('activeSection'), this.hoosierState.select('activeView')),
    debounceTime(200),
    tap(([activeFlow, activeSection, activeView]: [Flow | null, Section | null, View | null]) => {
      if (activeFlow && activeSection && activeView) {
        const activeCheckoutStep = this.hoosierState.get('activeCheckoutStep');

        this.sendScreenViewEvent(activeFlow, activeSection, activeView, activeCheckoutStep);

        let analyticsEvent = '';

        switch (activeView) {
          case JoinView.CONFIRMATION:
            switch (activeFlow) {
              case Flow.GIFT:
                analyticsEvent = 'Gift-New';
                break;
              case Flow.JOIN:
                analyticsEvent = 'Join-New';
                break;
            }
            break;
        }
        switch (activeFlow) {
          case Flow.QUICK_RENEW:
            switch (activeView) {
              case QuickRenewView.CONFIRMATION:
                analyticsEvent = 'QuickRenew-Renewal';
                break;
            }
            break;
        }

        if (analyticsEvent) {
          this.sendPurchaseEvent(analyticsEvent, activeFlow, activeSection, activeView, activeCheckoutStep);
        }
      }
    })
  );

  analyticsCheckoutStepEvent$ = this.hoosierState.select('activeFlow').pipe(
    combineLatestWith(
      this.hoosierState.select('activeSection'),
      this.hoosierState.select('activeView'),
      this.hoosierState.select('activeCheckoutStep')
    ),
    debounceTime(200),
    filter(
      ([activeFlow, activeSection, activeView, activeCheckoutStep]) =>
        !!activeFlow && !!activeSection && !!activeView && !!activeCheckoutStep
    ),
    tap(([activeFlow, activeSection, activeView, activeCheckoutStep]) => {
      this.sendScreenViewEvent(activeFlow, activeSection, activeView, activeCheckoutStep);

      let analyticsEvent = '';

      switch (activeCheckoutStep) {
        case CheckoutStep.CONFIRMATION:
          switch (activeFlow) {
            case Flow.MOBILE_APP_RENEW:
              switch (activeView) {
                case AccountMembershipView.LEVEL:
                  analyticsEvent = 'MobileApp-Upgrade';
                  break;
                case AccountMembershipView.RENEW:
                  analyticsEvent = 'MobileApp-Renewal';
                  break;
              }
              break;
            case Flow.ACCOUNT:
              switch (activeView) {
                case AccountMembershipView.ASSOCIATES:
                case AccountMembershipView.ASSOCIATES_ADD:
                  analyticsEvent = 'Account-AddAssociates';
                  break;
                case AccountMembershipView.RENEW:
                  analyticsEvent = 'Account-Renewal';
                  break;
                case AccountMembershipView.LEVEL:
                  analyticsEvent = 'Account-Upgrade';
                  break;
                case AccountMembershipView.AUTO_RENEW:
                case AccountMembershipView.AUTO_RENEW_CARD:
                  analyticsEvent = 'Account-AutoRenewal';
                  break;
              }
              break;
          }
          break;
      }

      if (analyticsEvent) {
        this.sendPurchaseEvent(analyticsEvent, activeFlow, activeSection, activeView, activeCheckoutStep);
      }
    })
  );

  sendScreenViewEvent(
    activeFlow: Flow | null,
    activeSection: Section | null,
    activeView: View | null,
    activeCheckoutStep: CheckoutStep | null
  ) {
    const firebase_screen_class_View = (activeSection as string) !== (activeView as string) ? '-' + activeView : '';
    const analyticsScreenViewEvent: AnalyticsScreenViewEvent = {
      eventName: EventName.SCREEN_VIEW,
      eventParams: {
        firebase_screen: activeFlow + '-' + activeSection + firebase_screen_class_View,
        firebase_screen_class: activeFlow + '-' + activeSection + firebase_screen_class_View,
        flow: activeFlow,
        section: activeSection,
        view: activeView,
        checkoutStep: activeCheckoutStep,
      },
    };
    this.analyticsService.sendFirebaseScreenViewEvent(analyticsScreenViewEvent);
  }

  sendPurchaseEvent(
    analyticsEvent: string,
    activeFlow: Flow | null,
    activeSection: Section | null,
    activeView: View | null,
    activeCheckoutStep: CheckoutStep | null
  ) {
    const MEMBERSHIP = this.hoosierState.get('MEMBERSHIP') as ExecuteMembershipResponseObject;
    const membership: ExecuteMembership = MEMBERSHIP?.response?.response?.membership;

    let txnId = '';
    let amount = '';
    let duesComponentCode = '';
    let label = '';

    if (!membership) {
      /**
       * get data from PAYMENT and from membershipPayload form
       */
      const paymentResponse = this.hoosierState.get('PAYMENT');
      const form = this.hoosierState.get('form').getRawValue() as HoosierForm;
      const membership = form.membershipPayload.membership;
      const membershipCode = connectSuiteMembershipCodes.find(
        code => code.level === membership?.membershipLevel && code.rv === membership?.rv
      );
      txnId = paymentResponse?.response?.processorInformation?.transactionId || '';
      amount = paymentResponse?.response?.orderInformation?.amountDetails?.authorizedAmount || '';
      duesComponentCode = membershipCode?.duesComponentCode || '';
      label = membershipCode?.label || '';
    }
    if (membership) {
      const membershipCode = connectSuiteMembershipCodes.find(
        code => code.membershipType === membership.primaryMember?.attributes?.membershipType
      );
      txnId = membership.payment?.transaction?.txnId;
      amount = membership.payment?.attributes?.amount || '';
      duesComponentCode = membershipCode?.duesComponentCode || '';
      label = membershipCode?.label || '';
    }

    if (txnId && amount && duesComponentCode && label) {
      const analyticsPurchaseEvent: AnalyticsPurchaseEvent = {
        eventName: EventName.PURCHASE,
        eventParams: {
          currency: 'USD',
          transaction_id: txnId,
          value: parseFloat(amount),
          items: [
            {
              quantity: 1, // other reports use number of associates plus primary
              item_id: duesComponentCode,
              item_name: analyticsEvent,
              price: parseFloat(amount),
            },
          ],
          membershipLevel: label,
          context: 'ava-store join-renew ' + this.globalState.get('environment', 'firebaseConfig', 'projectId'),
          flow: activeFlow,
          section: activeSection,
          view: activeView,
          checkoutStep: activeCheckoutStep,
        },
      };
      this.analyticsService.sendFirebasePurchaseEvent(analyticsPurchaseEvent);
      switch (analyticsEvent) {
        case 'Gift-New':
        case 'Join-New':
          analyticsPurchaseEvent.eventName = 'purchaseNew';
          this.analyticsService.sendFirebasePurchaseEvent(analyticsPurchaseEvent);
      }
    }
  }

  parseJSONObject(jsonString: string): object | undefined {
    try {
      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {
      console.log(e);
    }
    return;
  }

  /**
   * depends on google conversion script:
   * https://www.googleadservices.com/pagead/conversion_async.js
   */
  get google_trackConversion(): any {
    return this.window.nativeWindow.google_trackConversion;
  }

  sendConversionEvent(): void {
    this.sendFirebaseCustomTrackConversionEvent('initializing');
    const conversionData = {
      google_conversion_id: 994591697,
      google_conversion_label: 'vYwmCJf9hW8Q0Yeh2gM',
      google_remarketing_only: false,
    };

    /**
     * load google ads conversion script and send conversion event
     */
    if (this.globalState.get('windowMetaData', 'webEnv').toUpperCase() === WebEnvironment.PROD) {
      this.sendFirebaseCustomTrackConversionEvent('checking environment');
      if (typeof this.google_trackConversion === 'function') {
        this.sendFirebaseCustomTrackConversionEvent('sending - script was preloaded');
        this.google_trackConversion(conversionData);
      }
      if (typeof this.google_trackConversion !== 'function') {
        this.sendFirebaseCustomTrackConversionEvent('loading onload');
        const scriptSrc = 'https://www.googleadservices.com/pagead/conversion_async.js';
        this.scriptsService.addJsToHead(scriptSrc).onload = () => {
          this.sendFirebaseCustomTrackConversionEvent('loaded onload');
          if (typeof this.google_trackConversion === 'function') {
            this.sendFirebaseCustomTrackConversionEvent('sending onload');
            this.google_trackConversion(conversionData);
          }
        };
      }
    }
  }

  get newHoosierItem(): HoosierForm {
    return {
      agreeToTerms: false,
      useMemberInfoForBilling: true,
      membershipPayload: {
        associates: [],
        donorMembership: this.connectsuiteService.donorMembershipItem,
        membership: this.connectsuiteService.membershipItem,
        method: MembershipConnectSuiteMethod.RECOST_VALIDATE_JOIN,
        primary: this.connectsuiteService.primaryItem,
        responseKey: '',
      },
      creditCard: {
        expirationMonth: null, // new Date().getMonth() + 1,
        expirationYear: null, // new Date().getFullYear(),
        numberIsValid: false,
        securityCodeIsValid: false,
      },
      lastUpdated: Timestamp.now(),
      password: '',
      paymentPayload: {
        executionData: {
          amountDetails: {
            currency: 'USD',
            totalAmount: '',
          },
          billTo: {
            address1: '',
            address2: '',
            administrativeArea: 'IN', // state required and pre-filled
            buildingNumber: '',
            country: 'US',
            district: '', // not required
            email: '',
            firstName: '',
            lastName: '',
            locality: '', // city
            phoneNumber: '',
            postalCode: '',
          },
          creditCardBrandedName: '',
          flexMicroFormToken: '',
        },
        method: PaymentCybersourceMethod.OPERATION_EXECUTE,
        operation: null,
        responseKey: '',
      },
      renewPayload: {
        autoRenew: null,
        memberNumber: '',
        method: MembershipConnectSuiteMethod.RECOST_VALIDATE_RENEW,
        responseKey: '',
        verificationData: {
          lastName: '',
          postalCode: '',
        },
      },
      changeAssociatesPayload: {
        associateCount: 0,
        associates: [],
        autoRenew: null,
        memberNumber: '',
        method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_ASSOCIATES,
      },
      changeAutoRenewPayload: {
        autoRenew: null,
        memberNumber: '',
        method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_AR,
      },
      changeLevelPayload: {
        autoRenew: null,
        memberNumber: '',
        method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_LEVEL,
        newLevel: {
          level: null,
          rv: null,
        },
      },
    };
  }

  get newDonorMembershipItem(): MembershipConnectSuiteRecostValidateJoinEventPayload['donorMembership'] {
    return this.connectsuiteService.donorMembershipItem;
  }

  addAssociate(): void {
    const membershipFormArray = this.form.get(['membershipPayload', 'associates']) as FormArray;
    const changAssociatesFormArray = this.form.get(['changeAssociatesPayload', 'associates']) as FormArray;
    switch (this.hoosierState.get('activeFlow')) {
      case Flow.JOIN:
      case Flow.GIFT:
        membershipFormArray.insert(
          membershipFormArray.length,
          this.formService.newForm(this.connectsuiteService.associateItem)
        );
        this.form?.get(['membershipPayload', 'membership'])?.patchValue({ associateCount: membershipFormArray.length });
        break;
      case Flow.ACCOUNT:
        this.hoosierState.set('VALIDATE_ASSOCIATES', () => null);
        changAssociatesFormArray.insert(
          changAssociatesFormArray.length,
          this.formService.newForm(this.connectsuiteService.associateItem)
        );
        this.form?.get(['changeAssociatesPayload'])?.patchValue({ associateCount: changAssociatesFormArray.length });
        break;
    }
  }

  removeAssociate(index: number): void {
    const membershipFormArray = this.form.get(['membershipPayload', 'associates']) as FormArray;
    const changAssociatesFormArray = this.form.get(['changeAssociatesPayload', 'associates']) as FormArray;
    switch (this.hoosierState.get('activeFlow')) {
      case Flow.JOIN:
      case Flow.GIFT:
        membershipFormArray.removeAt(index);
        this.form?.get(['membershipPayload', 'membership', 'associateCount'])?.patchValue(membershipFormArray.length);
        break;
      case Flow.ACCOUNT:
        this.hoosierState.set('VALIDATE_ASSOCIATES', () => null);
        changAssociatesFormArray.removeAt(index);
        this.form?.get(['changeAssociatesPayload'])?.patchValue({ associateCount: changAssociatesFormArray.length });
        break;
    }
  }

  get accountStepLabel(): { [key in CheckoutStep]: string } {
    return {
      CONFIRMATION: '',
      EXECUTE: '',
      PAYMENT: 'Review and Payment',
      VALIDATE: '',
    };
  }

  get accountViewLabel(): AccountViewLabel {
    return {
      // Account Information
      ADDRESS: 'Home Address',
      EMAIL: 'Email',
      PASSWORD: 'Password',
      PHONE: 'Phone Number',

      // Account Membership
      ASSOCIATES: 'Associate Members',
      CANCEL: 'Cancel Membership',
      CARD_SERVICES: 'Card Services',
      GIFT: 'Gift Membership',
      HOME: undefined, // toolbar title falls back to section title when value is undefined
      LEVEL: 'Change Your Membership&nbsp;Plan',
      RENEW: 'Review and Payment',

      ASSOCIATES_ADD: 'Add an Associate Member',
      ASSOCIATES_REMOVE: 'Remove an Associate Member',

      AUTO_RENEW: 'Automatic Renewal',
      AUTO_RENEW_CARD: 'Change Card',
      AUTO_RENEW_REMOVE: 'Removing Automatic Renewal',

      // ASSOCIATES_ADD_CONFIRMATION: "",
      // ASSOCIATES_ADD_PAYMENT: "Review and Payment",
      // ASSOCIATES_ADD_PAYMENT_EXECUTE: "Review and Payment",
      // ASSOCIATES_ADD_VALIDATE: "Add an Associate Member",
      // ASSOCIATES_REMOVE_EXECUTE: "Remove an Associate Member",
      // ASSOCIATES_REMOVE_VALIDATE: "Remove an Associate Member",
      // ASSOCIATES_REMOVE_CONFIRMATION: "",

      // AUTO_RENEW_PAYMENT_CARD: "Automatic Renewal",
      // AUTO_RENEW_ADD_VALIDATE: "Automatic Renewal",
      // AUTO_RENEW_ADD_EXECUTE: "Automatic Renewal",
      // AUTO_RENEW_REMOVE_VALIDATE: "Automatic Renewal",
      // AUTO_RENEW_REMOVE_EXECUTE: "Automatic Renewal",

      // AUTO_RENEW_ADD: "AUTO_RENEW_ADD",
      // AUTO_RENEW_ADD_CONFIRMATION: "AUTO_RENEW_ADD_CONFIRMATION",
      // AUTO_RENEW_ADD_EXECUTE: "AUTO_RENEW_ADD_EXECUTE",
      // AUTO_RENEW_REMOVE: "AUTO_RENEW_REMOVE",
      // AUTO_RENEW_REMOVE_CONFIRMATION: "AUTO_RENEW_REMOVE_CONFIRMATION",
      // AUTO_RENEW_REMOVE_EXECUTE: "AUTO_RENEW_REMOVE_EXECUTE",

      // LEVEL_VALIDATE: "Change Your Membership&nbsp;Plan",
      // LEVEL_PAYMENT: "Review and Payment",
      // LEVEL_PAYMENT_EXECUTE: "Review and Payment",
      // LEVEL_CONFIRMATION: "Membership Plan Changed",

      // RENEW_CONFIRMATION: "",
      // RENEW_PAYMENT: "Review and Payment",
      // RENEW_PAYMENT_EXECUTE: "",
    };
  }

  membershipCard(membershipLabel: ConnectSuiteMembershipCode['label']): MembershipCard | undefined {
    return this.membershipCards.find(membershipCard => membershipCard.membershipLabel === membershipLabel);
  }

  get membershipCards(): MembershipCard[] {
    return [
      {
        filePath:
          'https://storage.googleapis.com/avagate-wss-1.appspot.com/023/web/images/joinrenew/hoosier-premier300.png',
        membershipLabel: 'Premier',
        textColor: 'black',
      },
      {
        filePath:
          'https://storage.googleapis.com/avagate-wss-1.appspot.com/023/web/images/joinrenew/hoosier-plus300.png',
        membershipLabel: 'Plus',
        textColor: 'black',
      },
      {
        filePath:
          'https://storage.googleapis.com/avagate-wss-1.appspot.com/023/web/images/joinrenew/hoosier-plus300.png',
        membershipLabel: 'Plus RV',
        textColor: 'black',
      },
      {
        filePath:
          'https://storage.googleapis.com/avagate-wss-1.appspot.com/023/web/images/joinrenew/hoosier-basic300.png',
        membershipLabel: 'Classic',
        textColor: 'black',
      },
    ];
  }

  sendFirebaseCustomTrackConversionEvent(step: string): void {
    const analyticsCustomEvent: AnalyticsCustomEvent = {
      eventName: 'national_ads_conversion',
      eventParams: {
        step: step,
      },
    };
    this.analyticsService.sendFirebaseCustomEvent(analyticsCustomEvent);
  }

  memberNameFull(names: string[]): string {
    let memberName = '';
    for (const name of names) {
      if (name) {
        memberName += name + ' ';
      }
    }
    return memberName;
  }

  membershipNumberFormatted(membershipNumber: string): string {
    return [
      membershipNumber.slice(0, 3),
      membershipNumber.slice(3, 6),
      membershipNumber.slice(6, 15),
      membershipNumber.slice(15),
    ].join(' ');
  }

  expirationDateFormatted(date: string | undefined): string {
    if (date) {
      return [date.slice(4, 6), date.slice(6, 8), date.slice(0, 4)].join('/');
    }
    return '';
  }
}
