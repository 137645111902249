import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { RxState } from '@rx-angular/state';
import {
  AccountMembershipView,
  AccountSection,
  CheckoutStep,
  Flow,
  HOOSIER_RX_STATE,
  HoosierState,
} from '../../hoosier.service';
import { FormGroup } from '@angular/forms';
import { RxEffects } from '@rx-angular/state/effects';
import { LinkService } from '../../../../../services/link';
import { GLOBAL_RX_STATE, GlobalState } from '../../../../../services/state';
import { ChangeLevelFormOptionsService } from '../../form-field-options/change-level';
import { OpStatus } from '../../../join-renew.service';
import { AlertType } from '../../../../../services/alert-message';
import { TransitionService } from '../../services/transition';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Action, Card } from './level';
import { ValidateLevelService } from '../../events/validate-level';
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteRecostValidateChangeLevelEventPayload,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';

interface Alert {
  message: string;
  type: AlertType;
}

@Component({
  selector: 'ava-hoosier-account-membership-level-card',
  templateUrl: './level-card.html',
})
export class LevelCardComponent implements OnInit, AfterViewInit {
  @Input() card: Card = {} as Card;
  @ViewChild('componentWidth') componentWidthElementRef: ElementRef | undefined;
  globalState$ = this.globalState.select();
  hoosierState$ = this.hoosierState.select();
  form: FormGroup | undefined;
  Action = Action;
  AccountMembershipView = AccountMembershipView;
  CheckoutStep = CheckoutStep;
  OpStatus = OpStatus;
  selected: boolean = false;
  narrowView: boolean | undefined;
  backgroundColors = {
    PREMIER: '#0C2B53',
    PLUS: '#123d74',
    CLASSIC: '#25538F',
  };
  alert: Alert = {} as Alert;
  downgradePopConfirmTitle = 'To change your membership benefits, please call 1-844-462-2246.';

  // downgradePopConfirmTitle = "Please confirm your request to downgrade your membership coverage effective on " + h.accountDetails.expiresDateFormatted + "."

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private linkService: LinkService,
    private changeLevelFormOptionsService: ChangeLevelFormOptionsService,
    private transitionService: TransitionService,
    private notification: NzNotificationService,
    private validateLevelService: ValidateLevelService
  ) {}

  ngOnInit(): void {
    this.form = this.hoosierState.get('form');
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  @HostListener('window:resize')
  onResize(): void {
    const width = this.componentWidthElementRef?.nativeElement.getBoundingClientRect().width;
    this.narrowView = width <= 450;
  }

  renew(event: Event): void {
    event.preventDefault();
    this.linkService.replaceUrlArgument(
      Flow.ACCOUNT,
      Flow.ACCOUNT + '/' + AccountSection.MEMBERSHIP + '/' + AccountMembershipView.RENEW
    );
  }

  upgrade(event: Event, newLevel: MembershipConnectSuiteRecostValidateChangeLevelEventPayload['newLevel']): void {
    this.selected = true;
    event.preventDefault();
    this.changeLevel(newLevel);
  }

  downgrade(newLevel: MembershipConnectSuiteRecostValidateChangeLevelEventPayload['newLevel']): void {
    this.selected = true;
    // event.preventDefault()
    this.changeLevel(newLevel);
  }

  changeLevel(newLevel: MembershipConnectSuiteRecostValidateChangeLevelEventPayload['newLevel']): void {
    this.hoosierState.set('activeCheckoutStep', () => CheckoutStep.VALIDATE);
    this.form?.get(['changeLevelPayload', 'newLevel'])?.patchValue(newLevel);

    const changeLevelPayload: MembershipConnectSuiteRecostValidateChangeLevelEventPayload = {
      autoRenew: this.hoosierState.get('accountDetails', 'autoRenew'),
      memberNumber: this.hoosierState.get('accountDetails', 'memberNumber'),
      method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_LEVEL,
      newLevel: newLevel,
    };
    this.validateLevelService.recostValidateLevel(changeLevelPayload);
  }

  /*
  setActiveView(view: AccountMembershipView): void {
    const section = this.hoosierState.get("activeSection")
    this.linkService.replaceUrlArgument(Flow.ACCOUNT, Flow.ACCOUNT + "/" + section + "/" + view)
  }
*/
}
