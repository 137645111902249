import { M } from './m.type';
import { RequestError, RequestErrorType } from './generic-errors';

export function checkPriceOfferErrorsMSystem(
  error: M.MembershipError,
  obj: any,
  hasCouponCode = false,
  hasPromoCode = false
) {
  const errorText = error?.data?.Result?.$?.errorText;

  if (errorText) {
    if (errorText?.startsWith('Invalid Club Number')) {
      return new RequestError(RequestErrorType.PricePreviewInvalidClubNumber, obj);
    }

    if (hasCouponCode) {
      if (errorText?.startsWith('Token Status is already reserved. Reserve must be')) {
        return new RequestError(RequestErrorType.PricePreviewReservedCouponCode, obj);
      }

      if (errorText?.startsWith('Token is already redeemed')) {
        return new RequestError(RequestErrorType.PricePreviewRedeemedCouponCode, obj);
      }

      return new RequestError(RequestErrorType.PricePreviewInvalidCouponCode, obj);
    }

    if (hasPromoCode) {
      if (errorText?.startsWith('We were unable to locate the promotional code')) {
        return new RequestError(RequestErrorType.PricePreviewInvalidPromoCode, obj);
      }

      if (errorText?.startsWith('We were unable to locate the promotional code')) {
        return new RequestError(RequestErrorType.PricePreviewInvalidPromoCode, obj);
      }

      if (errorText?.startsWith('The promotion code you entered expired')) {
        return new RequestError(RequestErrorType.PricePreviewExpiredPromoCode, obj);
      }

      return new RequestError(RequestErrorType.PricePreviewInvalidPromoCode, obj);
    }
  }

  return new RequestError(RequestErrorType.PricePreviewError, obj);
}
