import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModule } from '@angular/common';
import { LetModule } from '@ngrx/component';
import { FormInputComponent } from '../form/controls/input';
import { FormSelectComponent } from '../form/controls/select';
import { ButtonComponent } from '../ava-button/ava-button.component';
import { FormDateComponent } from '../form/controls/date';
import { FormPasswordComponent } from '../form/controls/password';
import { FormRadioComponent } from '../form/controls/radio';
import { FormTelComponent } from '../form/controls/tel';
import { FormArrayValue } from '../form.utils';
import { MembershipAssociateForm } from './membership-associate-form.model';
import { MembershipAssociateVm } from './membership-associate.vm';
import { nameSuffix } from '../../../constants/name-suffix';
import { FormCheckBoxComponent } from '../form/controls/checkbox';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Store } from '@ngrx/store';
import { AppStore } from '@aaa/emember/store-types';
import { getMembershipAssociateFormErrors } from '../../../store/form-messages';
import { AvaIconComponent } from '../ava-icon/ava-icon.component';
import { FormCheckBoxMedicalComponent } from '../form/controls/checkbox-medical';
import { AvaDialogService } from '../services/ava-dialog.service';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
  selector: 'ava-membership-associate-form',
  template: `
    <div *ngIf="errorMessages$ | async as errorMessages" class="ava-column ava-gap-2 ava-membership-associates">
      <ng-container *ngFor="let associateGroup of formArray.controls; let i = index">
        <div [attr.id]="'membership-associate-' + i" [formGroup]="associateGroup" class="ava-column ava-gap-2">
          <div class="ava-row ava-space-between ava-align-center">
            <div class="ava-form-label">Associate {{ totalAssociates + 1 + i }}</div>
            <button
              *ngIf="!associateGroup.controls.membershipNumber?.value"
              (click)="removeAssociates(i)"
              (keydown.enter)="removeAssociates(i)"
              class="membership-associate__btn--remove"
              type="button"
              nz-button
            >
              <span tabindex="0">
                <i [style.font-size]="'14px'" nz-icon nzTheme="outline" nzType="close"></i>
                Remove
              </span>
            </button>
          </div>
          <ava-form-input
            [errorMessages]="errorMessages.firstName"
            placeholder="First Name"
            formControlName="firstName"
          />
          <div class="ava-row ava-gap-1">
            <ava-form-input
              [errorMessages]="errorMessages.lastName"
              class="ava-flex"
              placeholder="Last Name"
              formControlName="lastName"
            />
            <ava-form-select
              placeholder="Suffix"
              [options]="nameSuffix"
              formControlName="suffix"
              class="ava-membership-associates__suffix"
            />
          </div>
          <div class="ava-row ava-gap-2 membership-associate__email">
            <ava-form-date
              [errorMessages]="errorMessages.birthday"
              placeholder="Date of Birth (mm/dd/yyyy)"
              formControlName="birthday"
              class="ava-flex"
            />
            <ava-form-input
              [errorMessages]="errorMessages.email"
              placeholder="Email"
              formControlName="email"
              class="ava-flex"
            />
          </div>
          <div class="ava-row" *ngIf="showAccidentMedicalPlan">
            <ava-form-checkbox-medical
              formControlName="accidentMedicalPlan"
              [accidentMedicalPlanPrice]="accidentMedicalPlanPrice"
              (changed)="accidentMedicalChanged.emit($event)"
            />
          </div>
        </div>
      </ng-container>
      <div
        tabindex="0"
        (click)="addAssociates()"
        (keydown.enter)="addAssociates()"
        [ngClass]="{
          'ava-membership-associates__add--disabled':
            formArray.invalid || formArray.length + totalAssociates >= maxAssociate,
        }"
        class="ava-membership-associates__add"
      >
        <i [style.font-size]="'36px'" nz-icon nzTheme="twotone" [hidden]="false" nzType="plus-circle"></i>
        <p>
          Add Additional Member(s)
          <br />
          *Must live in the same household.
        </p>
      </div>
    </div>
  `,
  styles: [
    `
      @import '../../../styles/ava-responsive';

      .ava-membership-associates {
        &__suffix {
          max-width: 100px;
          width: 100%;
        }

        .membership-associate {
          &__email {
            @include screenTabletLargeDown() {
              flex-direction: column;
            }
          }

          &__btn {
            &--remove {
              border: none;
              background: transparent;
              font-size: 16px;
              color: #25538f;
              cursor: pointer;
              font-weight: 400;
            }
          }
        }

        &__add {
          display: flex;
          gap: 1rem;
          margin: 1rem 0;
          cursor: pointer;

          &--disabled {
            opacity: 0.6;
            cursor: not-allowed;
          }

          p {
            font-size: 16px;
            line-height: 1.235;
            word-wrap: break-word;
            font-weight: 200;
          }
        }
      }
    `,
  ],
  imports: [
    FormInputComponent,
    FormSelectComponent,
    ReactiveFormsModule,
    NzIconModule,
    ButtonComponent,
    FormDateComponent,
    FormPasswordComponent,
    FormRadioComponent,
    FormTelComponent,
    LetModule,
    CommonModule,
    FormCheckBoxComponent,
    NzToolTipModule,
    AvaIconComponent,
    FormCheckBoxMedicalComponent,
    NzButtonModule,
  ],
  standalone: true,
})
export class MembershipAssociateFormComponent implements OnChanges {
  dialog = inject(AvaDialogService);
  membershipAssociateVm = inject(MembershipAssociateVm);
  controlContainer = inject(ControlContainer);
  store = inject(Store<AppStore>);
  @Input() accidentMedicalPlanPrice = 0;
  @Input() showAccidentMedicalPlan: boolean | null = false;
  @Input() totalAssociates = 0;
  @Input() maxAssociate = 9;
  @Input() formValues: FormArrayValue<FormGroup<MembershipAssociateForm>> = [];
  @Output() addAssociate = new EventEmitter();
  @Output() removeAssociate = new EventEmitter<number>();
  @Output() accidentMedicalChanged = new EventEmitter();
  errorMessages$ = this.store.select(getMembershipAssociateFormErrors);
  readonly nameSuffix = nameSuffix;

  get formArray(): FormArray<FormGroup<MembershipAssociateForm>> {
    let control = this.membershipAssociateVm.create();
    if (this.controlContainer) {
      control = this.controlContainer.control as FormArray<FormGroup<MembershipAssociateForm>>;
    }

    return control;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formValues) {
      this.updateAssociatesFormArray(this.formValues);
    }
  }

  updateAssociatesFormArray(values: FormArrayValue<FormGroup<MembershipAssociateForm>>) {
    const associates = values || [];
    if (this.formArray.length === 0 && associates.length > 0) {
      associates
        .map(values => this.membershipAssociateVm.createAssociate(values))
        .forEach((associate, index) => this.formArray.insert(index, associate));
    }
  }

  addAssociates() {
    if (this.formArray.valid && this.formArray.length + this.totalAssociates < this.maxAssociate) {
      this.addAssociate.emit();
    }
  }

  removeAssociates(index: number) {
    this.removeAssociate.emit(index);
  }
}
