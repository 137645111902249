import { ActivatedRouteSnapshot, CanActivateChildFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { LocationRefService } from '../../share/services/location-ref.service';

export const defaultRouting = (): CanActivateChildFn => {
  let isExecuted = false;

  return ({ queryParams }: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const location = inject(LocationRefService);
    const pathName = location.nativeLocation.pathname;
    const navigatePath: string[] = [];

    if (pathName.indexOf('/join') !== -1) {
      navigatePath.push('offers');
    }
    if (pathName.indexOf('/account') !== -1) {
      navigatePath.push('account');
    }
    if (pathName.indexOf('/quick-renew') !== -1) {
      navigatePath.push('renew');
    }
    if (pathName.indexOf('/gift-membership') !== -1) {
      navigatePath.push('gift-offers');
    }
    if (pathName.indexOf('/MobileAppRenewPayNow') !== -1) {
      navigatePath.push('mobile-renew');
    }

    if (navigatePath.length && !isExecuted && !router.routerState.snapshot.url) {
      // check if should redirect
      // check if executed first time
      // check if app is empty to be able redirect user
      router.navigate(navigatePath, { queryParams });
      isExecuted = true;

      return false;
    } else {
      isExecuted = true;

      return true;
    }
  };
};

export default defaultRouting;
