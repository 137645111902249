import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { PricePreviewActions } from './price-preview.actions';
import { MembershipActions } from '../membership/membership.actions';
import { getPriceQuery } from './price-preview.selectors';

@Injectable()
export class PricePreviewEffects {
  store = inject(Store);
  actions$ = inject(Actions);

  // public loadPricePreviewAfterSucceededLoadedMembership$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(MembershipActions.loadSucceeded),
  //     withLatestFrom(this.store.select(getPriceQuery)),
  //     map(([{ accountDetails }, pricePayload]) => {
  //       const payload = { ...pricePayload, zipCode: accountDetails.address.zip }
  //
  //       return PricePreviewActions.load({ payload })
  //     }),
  //   ),
  // )
}
