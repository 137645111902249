import { Inject, Injectable } from '@angular/core';
import {
  AccountMembershipView,
  CheckoutStep,
  Flow,
  HOOSIER_RX_STATE,
  HoosierState,
  JoinSection,
  JoinView,
  QuickRenewSection,
  QuickRenewView,
  SessionDocResponseObject,
} from '../hoosier.service';
import { tap } from 'rxjs/operators';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';

export enum PaymentErrorReason {
  EXCEEDS_CREDIT_LIMIT = 'EXCEEDS_CREDIT_LIMIT',
  EXPIRED_CARD = 'EXPIRED_CARD',
  GENERIC = 'GENERIC',
  INSUFFICIENT_FUND = 'INSUFFICIENT_FUND',
  INVALID_CVN = 'INVALID_CVN',
  PAYMENT_REFUSED = 'PAYMENT_REFUSED',
}

export enum PaymentErrorDescription {
  EXCEEDS_CREDIT_LIMIT = 'The credit card has reached the credit limit.',
  EXPIRED_CARD = 'Please check the expiration date on your credit card for an invalid or expired number.',
  GENERIC = 'Your payment was unsuccessful. Please try again or reach out to Member Services at 1-844-462-2246.',
  INSUFFICIENT_FUND = 'There are insufficient funds in the account.',
  INVALID_CVN = 'Invalid card verification number. Please update the number.',
  PAYMENT_REFUSED = 'The transaction was declined by bank.',
}

export interface ExecutePaymentResponseObject extends SessionDocResponseObject {
  error: ExecutePaymentError;
  // meta: SessionDocMeta
  response: ExecutePaymentResponse;
}

export interface ExecutePaymentResponse {
  submitTimeUtc: string; // "2022-09-18T03:39:23Z",
  _links: {
    self: {
      href: string; // "/pts/v2/payments/6634723628436150504953",
      method: string; // "GET"
    };
    capture: {
      method: string; // "POST",
      href: string; // "/pts/v2/payments/6634723628436150504953/captures"
    };
  };
  reconciliationId: string; // "Q7II27ZST1BI",
  status: string; // "AUTHORIZED",
  paymentAccountInformation: {
    card: {
      type: string; // "001"
    };
  };
  id: string; // "6634723628436150504953",
  issuerInformation: {
    responseRaw: string; // "0110322000000E10000200000000000000520009180339231014975137494932375A53543142493833313030303030000159007000223134573031363135303730333830323039344730363400103232415050524F56414C00065649435241200024363832313030303138343043393939393939393939393939"
  };
  orderInformation: {
    amountDetails: {
      currency: string; // "USD",
      authorizedAmount: string; // "52.00"
    };
  };
  pointOfSaleInformation: {
    terminalId: string; // "12345678"
  };
  paymentInformation: {
    tokenizedCard: {
      type: string; // "001"
    };
    card: {
      type: string; // "001"
    };
  };
  clientReferenceInformation: {
    code: string; // "kxRgE8UvGme1qnZCvG3F"
  };
  processorInformation: {
    networkTransactionId: string; // "016150703802094",
    transactionId: string; // "016150703802094",
    approvalCode: string; // "831000",
    merchantNumber: string; // "000123456789012",
    responseCode: string; // "00",
    avs: {
      codeRaw: string; // "Y",
      code: string; // "Y"
    };
  };
}

export interface InvalidRequestError {
  submitTimeUtc: string; // "2022-12-01T17:22:55Z",
  reason: string; // "DUPLICATE_REQUEST",
  message: string; // "Declined - The transaction identifier sent with this request has already been used in the last 60 days",
  id: string; // "6699153756906391204951",
  status: string; // "INVALID_REQUEST"
}

export interface ExecutePaymentError {
  response: {
    status: 201 | 400 | 502;
    text: string;
    req: {
      method: 'POST' | 'GET';
      url: string;
      data: string;
      headers: {
        signature: string;
        'user-agent': string;
        date: string;
        host: string;
        'content-type': string;
        'v-c-merchant-id': string;
        digest: string;
        accept: string;
      };
    };
    header: {
      'cache-control': string;
      'strict-transport-security': string;
      'v-c-correlation-id': string;
      pragma: string;
      'x-opnet-transaction-trace': string;
      connection: string;
      'content-type': string;
      expires: string;
      'x-response-time': string;
      'content-length': string;
    };
  };
  data: {
    processorInformation: {
      cardVerification: {
        resultCodeRaw: string; // "X",
        resultCode: string; // "X"
      };
      merchantNumber: string; // "000178056838992",
      avs: {
        code: string; // "2"
      };
      responseCode: string; // "63"
    };
    pointOfSaleInformation: {
      terminalId: string; // "08077387"
    };
    errorInformation: {
      message: string; // "Decline - General decline of the card. No other information provided by the issuing bank.",
      reason: string; // "PROCESSOR_DECLINED"
    };
    clientReferenceInformation: {
      code: string; // "JOINj1lGryuXjKpPkQL"
    };
    paymentInsightsInformation: {
      responseInsights: {
        categoryCode: string; // "02",
        category: string; // "ISSUER_CANNOT_APPROVE_AT_THIS_TIME"
      };
    };
    status: 'AUTHORIZED' | 'DECLINED' | string; // "DECLINED",
    issuerInformation: {
      responseRaw: string; // "0110322001800A8000020000000000000185001130001255258477000159543638484D414B574435384A36333038303737333837008000483134412020202020202020202020202020202020202020203030303030303030303030303030303030303030303030300018323253455256204E4F5420414C4C4F57454400033438200003343958"
    };
    id: string; // "6697671747806591504977",
    _links: {
      self: {
        method: string; // "GET",
        href: string; // "/pts/v2/payments/6697671747806591504977"
      };
    };
  };
  customerId: string;
  request: {
    processingInformation: {
      capture: true;
      actionTokenTypes: string[];
      // "paymentInstrument",
      // "customer"
      commerceIndicator: string; // "internet",
      actionList: string[];
      // "TOKEN_CREATE"
    };
    clientReferenceInformation: {
      transactionId: string; // "JOINj1lGryuXjKpPkQL",
      code: string; // "JOINj1lGryuXjKpPkQL"
    };
    orderInformation: {
      amountDetails: {
        totalAmount: string; // "185.0",
        currency: string; // "USD"
      };
      billTo: {
        lastName: string; // "User",
        address2: string; // "",
        country: string; // "US",
        buildingNumber: string; // "",
        email: string; // "testfive@aaa.com",
        firstName: string; // "Test",
        administrativeArea: string; // "IN",
        address1: string; // "10 Test Street",
        locality: string; // "CityTest",
        district: string; // "IN",
        postalCode: string; // "46222",
        phoneNumber: string; // "337-468-3783"
      };
    };
    tokenInformation: {
      transientTokenJwt: string; // "eyJraWQiOiIwMzd0MFNWek1NeGpQNThqM3BqOUhOTm9tOTVPMFQ1byIsImFsZyI6IlJTMjU2In0.eyJkYXRhIjp7ImV4cGlyYXRpb25ZZWFyIjoiMjAyNCIsIm51bWJlciI6IjQxMTExMVhYWFhYWDExMTEiLCJleHBpcmF0aW9uTW9udGgiOiIwNCIsInR5cGUiOiIwMDEifSwiaXNzIjoiRmxleC8wNCIsImV4cCI6MTY2OTc2ODA3MywidHlwZSI6Im1mLTAuMTEuMCIsImlhdCI6MTY2OTc2NzE3MywianRpIjoiMUM1WEhMU1NDSDFOM0hYOThHR1pZSkU5U0NPTUpCS0RCUzJCRVE5VUlYWkREMFlCWU8wNDYzODZBMzg5RTUzMSIsImNvbnRlbnQiOnsicGF5bWVudEluZm9ybWF0aW9uIjp7ImNhcmQiOnsiZXhwaXJhdGlvblllYXIiOnsidmFsdWUiOiIyMDI0In0sIm51bWJlciI6eyJtYXNrZWRWYWx1ZSI6IlhYWFhYWFhYWFhYWDExMTEiLCJiaW4iOiI0MTExMTEifSwic2VjdXJpdHlDb2RlIjp7fSwiZXhwaXJhdGlvbk1vbnRoIjp7InZhbHVlIjoiMDQifSwidHlwZSI6eyJ2YWx1ZSI6IjAwMSJ9fX19fQ.GRks6xHhszz3cP9AUgnJ5refXVHOkw5nZ1tma0xKQXY55YvGmjnEn9SMnHXSO8On9kujBikKptm1G3A116Q8r_EBxkyGlef8nKao-U8u62mhaK7uQS4W0otKIR1DjugHli5RjPvVnBcuFPZJWoP8pB9-hpMpwqCcxZNqlZFnIYSoGEneSx9l1qlFBxLy7AjzGtqKQk1KR5IhVx_JQSAT3RuiUL9Sv95ZHRM1jsGIK7BlCFB3R7mZfCHNAF1Eg72XgIgohEq0p06GmDG0g-dHiB_jNmozIXc0-_Klx8a3JOMkGYdomHeeKLNfwgkkrlyqCBv3V-K_P48aT_x2NBSupw"
    };
  };
  orderId: string;
  status?: string; // "INVALID_REQUEST" actually from InvalidRequestError
  reason?: string; // "DUPLICATE_REQUEST", actually from InvalidRequestError
}

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects
  ) {
    this.rxEffects.register(this.PAYMENT_ERROR$);
  }

  PAYMENT_ERROR$ = this.hoosierState.select('PAYMENT_ERROR').pipe(
    tap(PAYMENT_ERROR => {
      if (PAYMENT_ERROR) {
        if (PAYMENT_ERROR.error.status === 'INVALID_REQUEST' && PAYMENT_ERROR.error.reason === 'DUPLICATE_REQUEST') {
          //
        }

        /**
         * handle unknown error when error.data is missing
         */
        if (!PAYMENT_ERROR.error.data) {
          this.hoosierState.set('PAYMENT_ERROR_MESSAGE', () => PaymentErrorDescription.GENERIC);
        }

        /**
         * handle known and unknown error when there is error.data
         */
        if (PAYMENT_ERROR.error.data) {
          const reason: PaymentErrorReason = PAYMENT_ERROR.error.data.errorInformation.reason as PaymentErrorReason;
          if (!PaymentErrorReason[reason]) {
            this.hoosierState.set('PAYMENT_ERROR_MESSAGE', () => PaymentErrorDescription.GENERIC);
          }
          if (PaymentErrorReason[reason]) {
            this.hoosierState.set('PAYMENT_ERROR_MESSAGE', () => PaymentErrorDescription[reason]);
          }
        }

        /**
         * reset UI to payment form
         */
        const activeCheckoutStep = this.hoosierState.get('activeCheckoutStep');
        switch (this.hoosierState.get('activeFlow')) {
          case Flow.JOIN:
          case Flow.GIFT:
            this.hoosierState.set('activeSection', () => JoinSection.PAYMENT);
            this.hoosierState.set('activeView', () => JoinView.PAYMENT);
            break;
          case Flow.QUICK_RENEW:
            this.hoosierState.set('activeSection', () => QuickRenewSection.SUMMARY_AND_PAYMENT);
            this.hoosierState.set('activeView', () => QuickRenewView.SUMMARY_AND_PAYMENT);
            break;
          case Flow.ACCOUNT:
            switch (this.hoosierState.get('activeView') as AccountMembershipView) {
              case AccountMembershipView.ASSOCIATES_ADD:
              case AccountMembershipView.LEVEL:
              case AccountMembershipView.RENEW:
                if (activeCheckoutStep === CheckoutStep.EXECUTE) {
                  this.hoosierState.set('activeCheckoutStep', () => CheckoutStep.PAYMENT);
                }
                break;
            }
        }
      }
    })
  );
}
