import { createMembershipOfferSummary, getSummaryPrice } from './create-membership-offer-summary';
import { MembershipLevelOffer } from '../price-offers.model';
import { OfferFilter } from './types';

export function promocodeWarningRequrieAutorenew(
  membershipOffers: MembershipLevelOffer | null,
  membershipType: string,
  offerFilter: OfferFilter
) {
  if (membershipType && membershipOffers) {
    if (offerFilter.promoCode) {
      const currentMembershipTypeOffers = membershipOffers[membershipType];
      const firstSummary = createMembershipOfferSummary(currentMembershipTypeOffers, {
        ...offerFilter,
        autoRenew: false,
      });
      const secondSummary = createMembershipOfferSummary(currentMembershipTypeOffers, {
        ...offerFilter,
        autoRenew: true,
      });
      const showWarningMassage =
        getSummaryPrice(firstSummary) > getSummaryPrice(secondSummary) && offerFilter.autoRenew === false;

      return showWarningMassage ? 'Promo Code requires Auto Renewal' : '';
    }
  }

  return '';
}
