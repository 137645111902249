import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModule } from '@angular/common';
import { JoinPage } from '@aaa/emember/store-join';
import { FormInputComponent } from '../form/controls/input';
import { FormSelectComponent } from '../form/controls/select';
import { ButtonComponent } from '../ava-button/ava-button.component';
import { FormDateComponent } from '../form/controls/date';
import { FormPasswordComponent } from '../form/controls/password';
import { FormRadioComponent } from '../form/controls/radio';
import { FormTelComponent } from '../form/controls/tel';
import { MemberInfoFormVm } from './member-info-form.vm';
import { MemberInfoForm } from './member-info-form.model';
import { MembershipAssociateFormComponent } from '../membership-associate-form';
import { FormCheckBoxComponent } from '../form/controls/checkbox';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { getMembershipFormErrors } from '../../../store/form-messages';
import { Store } from '@ngrx/store';
import { AvaIconComponent } from '../ava-icon/ava-icon.component';
import { getMedicalPlanText } from '@aaa/emember/store-membership';
import { AccidentalMedicalCoverageComponent } from '../accidental-medical-coverage/accidental-medical-coverage';
import { AvaDialogService } from '../services/ava-dialog.service';
import { AppStore } from '@aaa/emember/store-types';
import { nameSuffix } from '../../../constants/name-suffix';
import { SmartyAddressComponent } from '../form/controls/smarty-address.component';

@Component({
  selector: 'ava-member-info-form',
  template: `
    <div [formGroup]="formGroup" class="ava-column ava-gap-2">
      <div class="ava-column ava-gap-2 ava-join-memberInfo" *ngIf="errorMessages$ | async as errorMessages">
        <ng-container formGroupName="account">
          <ava-form-input
            [errorMessages]="errorMessages.firstName"
            placeholder="First Name"
            formControlName="firstName"
          />
          <div class="ava-row ava-gap-2">
            <ava-form-input
              [errorMessages]="errorMessages.lastName"
              class="ava-flex"
              placeholder="Last Name"
              formControlName="lastName"
            />
            <ava-form-select
              [options]="nameSuffix"
              allowClear="true"
              formControlName="suffix"
              placeholder="Suffix"
              class="ava-join-memberInfo__suffix"
            />
          </div>
          <ng-container *ngIf="showAddressAutocomplete; else staticAddress">
            <ava-form-smarty-address
              [includeOnlyStates]="[formGroup.value.account.state]"
              [errorMessages]="errorMessages.address1"
              placeholder="Address 1"
              formControlName="address1"
              zipcodeFormControlName="zipcode"
              stateFormControlName="state"
              cityFormControlName="city"
            />
          </ng-container>
          <ng-template #staticAddress>
            <ava-form-input
              [errorMessages]="errorMessages.address1"
              placeholder="Address 1"
              formControlName="address1"
            />
          </ng-template>
          <ava-form-input placeholder="Address 2" formControlName="address2" />
          <ava-form-input [errorMessages]="errorMessages.city" placeholder="City" formControlName="city" />
          <div class="ava-row ava-gap-2">
            <ava-form-select
              [errorMessages]="errorMessages.state"
              [options]="memberStates"
              [showSearch]="true"
              placeholder="State"
              formControlName="state"
              class="ava-flex"
            />
            <ava-form-input
              [errorMessages]="errorMessages.zipcode"
              placeholder="Zip Code"
              formControlName="zipcode"
              class="ava-flex"
            />
          </div>
          <div *ngIf="showBirthday || showPhoneNumber" class="ava-row ava-gap-2 ava-join-memberInfo__group-controls">
            <ava-form-date
              *ngIf="showBirthday"
              [errorMessages]="errorMessages.birthday"
              (dateChange)="dateChange.emit($event)"
              placeholder="Date of Birth (mm/dd/yyyy)"
              formControlName="birthday"
              class="ava-flex"
            />
            <ava-form-tel
              *ngIf="showPhoneNumber"
              [errorMessages]="errorMessages.phone"
              placeholder="Phone"
              formControlName="phone"
              class="ava-flex"
            />
          </div>
          <div class="ava-row ava-gap-2 ava-join-memberInfo__group-controls">
            <ava-form-input
              [errorMessages]="errorMessages.email"
              placeholder="Email"
              formControlName="email"
              class="ava-flex"
            />
            <ng-container *ngIf="showPassword">
              <ava-form-password
                [errorMessages]="errorMessages.password"
                placeholder="Password"
                formControlName="password"
                class="ava-flex"
                controlId="member-info-password"
              />
            </ng-container>
          </div>
        </ng-container>
        <div class="ava-column ava-gap-2">
          <ng-container *ngIf="showCardFormat">
            <ng-container formGroupName="membership">
              <ava-form-radio
                controlId="member-info-cardFormat"
                [options]="cardTypes"
                formLabel="Choose Your AAA Card Format"
                formControlName="cardFormat"
              />
            </ng-container>
          </ng-container>
          <div *ngIf="showAccidentMedicalPlan" class="ava-row" formGroupName="account">
            <ava-form-checkbox
              [formLabel]="
                'Accident Medical ' + (accidentMedicalPlanPrice ? (accidentMedicalPlanPrice | currency) : '') + ' Plan'
              "
              formControlName="accidentMedicalPlan"
              (changed)="accidentMedicalChanged.emit($event)"
            />
            <ng-container *ngIf="medicalPlanText$ | async as medicalPlanText">
              <ava-icon
                iconType="info-circle"
                iconTheme="fill"
                tooltipPlacement="top"
                [tooltipTitle]="medicalPlanText"
                (tooltipClicked)="tooltipClicked()"
              />
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      @import '../../../styles/ava-responsive';

      .ava-join-memberInfo {
        padding-bottom: 16px;

        &__suffix {
          max-width: 100px;
          width: 100%;
        }

        @include screenTabletDown() {
          .ava-join-memberInfo__group-controls {
            flex-direction: column;
          }
        }
      }
    `,
  ],
  imports: [
    FormInputComponent,
    FormSelectComponent,
    ReactiveFormsModule,
    NzIconModule,
    ButtonComponent,
    FormDateComponent,
    FormPasswordComponent,
    FormRadioComponent,
    FormTelComponent,
    CommonModule,
    MembershipAssociateFormComponent,
    FormCheckBoxComponent,
    NzToolTipModule,
    AvaIconComponent,
    SmartyAddressComponent,
  ],
  providers: [MemberInfoFormVm],
  standalone: true,
})
export class MemberInfoFormComponent implements OnInit {
  readonly nameSuffix = nameSuffix;
  dialog = inject(AvaDialogService);
  memberInfoFormVm = inject(MemberInfoFormVm);
  controlContainer = inject(ControlContainer);
  store = inject(Store<AppStore>);
  @Input() accidentMedicalPlanPrice = 0;
  @Input() showAccidentMedicalPlan = false;
  @Input() showAddressAutocomplete = false;
  @Input() showPassword = false;
  @Input() page: JoinPage = 'join';
  @Input() showPhoneNumber = false;
  @Input() showBirthday = false;
  @Input() requiredPhone = false;
  @Input() showCardFormat = false;
  @Input() cardTypes = [];
  @Input() memberStates = [];
  @Output() accidentMedicalChanged = new EventEmitter();
  @Output() dateChange = new EventEmitter();

  errorMessages$ = this.store.select(getMembershipFormErrors);
  medicalPlanText$ = this.store.select(getMedicalPlanText);
  get formGroup(): FormGroup<MemberInfoForm> {
    if (this.controlContainer) {
      return this.controlContainer.control as FormGroup<MemberInfoForm>;
    }

    return this.memberInfoFormVm.create();
  }

  ngOnInit() {
    if (this.showPassword === false) {
      this.clearPasswordValidators();
    }

    if (this.showCardFormat === false) {
      this.clearCardFormatValidators();
    }

    if (this.requiredPhone === true) {
      this.makeRequirePhoneNumber();
    }

    if (this.page === 'gift') {
      this.clearStateAsyncValidator();
    }
  }

  clearStateAsyncValidator() {
    this.formGroup.controls.account.controls.zipcode.setAsyncValidators([]);
  }

  makeRequirePhoneNumber() {
    this.formGroup.controls.account.controls.phone.addValidators([Validators.required]);
  }

  clearPasswordValidators() {
    this.formGroup.controls.account.controls.password.setValidators([]);
  }

  clearCardFormatValidators() {
    this.formGroup.controls.membership.controls.cardFormat.setValidators([]);
  }

  tooltipClicked() {
    this.dialog.openComponent({ nzContent: AccidentalMedicalCoverageComponent, nzFooter: null });
  }
}
