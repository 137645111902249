export enum AppAnalyticsEvents {
  MobileAppUpgrade = 'MobileApp-Upgrade',
  MobileAppRenewal = 'MobileApp-Renewal',
  AccountAddAssociates = 'Account-AddAssociates',
  AccountRenewal = 'Account-Renewal',
  AccountUpgrade = 'Account-Upgrade',
  AccountUpdate = 'Account-Update',
  AccountAutoRenewal = 'Account-AutoRenewal',
  QuickRenewRenewal = 'QuickRenew-Renewal',
  GiftNew = 'Gift-New',
  JoinNew = 'Join-New',
}
