<div [formGroup]="formGroup" class="ava-column ava-gap-2" *ngIf="errorMessages$ | async as errorMessages">
  <div class="ava-column ava-gap-2 ava-gift-recipient-info" formGroupName="account">
    <ava-form-input [errorMessages]="errorMessages.firstName" placeholder="First Name" formControlName="firstName" />
    <div class="ava-row ava-gap-2">
      <ava-form-input
        [errorMessages]="errorMessages.lastName"
        class="ava-flex"
        placeholder="Last Name"
        formControlName="lastName"
      />
      <ava-form-select
        [options]="nameSuffix"
        allowClear="true"
        formControlName="suffix"
        placeholder="Suffix"
        class="ava-gift-recipient-info__suffix"
      />
    </div>
    <ng-container *ngIf="showAddressAutocomplete; else staticAddress">
      <ava-form-smarty-address
        [includeOnlyStates]="[formGroup.value.account.state]"
        [errorMessages]="errorMessages.address1"
        placeholder="Address 1"
        formControlName="address1"
        zipcodeFormControlName="zipcode"
        stateFormControlName="state"
        cityFormControlName="city"
      />
    </ng-container>
    <ng-template #staticAddress>
      <ava-form-input [errorMessages]="errorMessages.address1" placeholder="Address 1" formControlName="address1" />
    </ng-template>
    <ava-form-input placeholder="Address 2" formControlName="address2" />
    <ava-form-input [errorMessages]="errorMessages.city" placeholder="City" formControlName="city" />
    <div class="ava-row ava-gap-2">
      <ava-form-select
        [errorMessages]="errorMessages.state"
        [options]="memberStates"
        [showSearch]="true"
        placeholder="State"
        formControlName="state"
        class="ava-flex"
      />
      <ava-form-input
        [errorMessages]="errorMessages.zipcode"
        placeholder="Zip Code"
        suffixLabel="Zip"
        formControlName="zipcode"
        class="ava-flex"
      />
    </div>
    <div class="ava-row ava-gap-2 ava-gift-recipient-info__group-controls">
      <ava-form-date
        [errorMessages]="errorMessages.birthday"
        placeholder="Date of Birth (mm/dd/yyyy)"
        formControlName="birthday"
        class="ava-flex"
      />
      <ava-form-tel
        [errorMessages]="errorMessages.phone"
        placeholder="Phone"
        formControlName="phone"
        class="ava-flex"
      />
    </div>
    <div class="ava-row ava-gap-2 ava-gift-recipient-info__group-controls">
      <ava-form-input
        [errorMessages]="errorMessages.email"
        placeholder="Email Address"
        suffixLabel="Email"
        formControlName="email"
        class="ava-flex"
      />
    </div>
    <div *ngIf="showAccidentMedicalPlan" class="ava-row">
      <ava-form-checkbox
        [formLabel]="
          'Accident Medical ' + (accidentMedicalPlanPrice ? (accidentMedicalPlanPrice | currency) : '') + ' Plan'
        "
        controlId="join-primary-medical-rider"
        formControlName="accidentMedicalPlan"
        (changed)="accidentMedicalChanged.emit($event)"
      />
      <ng-container *ngIf="medicalPlanText$ | async as medicalPlanText">
        <ava-icon
          iconType="info-circle"
          iconTheme="fill"
          tooltipPlacement="top"
          [tooltipTitle]="medicalPlanText"
          (tooltipClicked)="tooltipClicked()"
        />
      </ng-container>
    </div>
  </div>
  <div *ngIf="showGiftOption" formGroupName="options" class="ava-column ava-gap">
    <p class="ava-header-bold">Gift Options</p>
    <div class="ava-column ava-gap-2">
      <div class="ava-row ava-gap-2 ava-gift-recipient-info__group-controls">
        <ava-form-input class="ava-flex" placeholder="To:" suffixLabel="To" formControlName="to" />
        <ava-form-input class="ava-flex" placeholder="From:" suffixLabel="From" formControlName="from" />
      </div>
      <ava-form-textarea
        class="ava-flex"
        placeholder="Gift Message:"
        suffixLabel="Gift Message"
        formControlName="message"
      />
    </div>

    <div class="ava-column ava-gap-2">
      <ava-form-radio formControlName="sendCardTo" formLabel="Send Gift To:" [options]="sendCardTo" />
      <ava-form-checkbox formControlName="autoRenew" formLabel="Make this a recurring yearly gift">
        <ava-icon iconTheme="fill" iconType="info-circle" tooltipPlacement="top" [tooltipTitle]="autoRenewTemplate" />
      </ava-form-checkbox>
    </div>
  </div>
</div>

<ng-template #autoRenewTemplate>
  When it's time to renew your membership, your annual dues will automatically be charged to the credit card
  approximately 20 days prior to your membership expiration date.
</ng-template>
