import { CanActivateChildFn } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { getMembershipNumber, isMembershipRequestStatus, MembershipActions } from '@aaa/emember/store-membership';
import { filter, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestStatus } from '../../../../types/request-status';

export const loadMembership: CanActivateChildFn = () => {
  const store = inject(Store);

  return store.select(getMembershipNumber).pipe(
    tap(membershipNumber => store.dispatch(MembershipActions.load({ membershipNumber }))),
    switchMap(() =>
      store
        .select(isMembershipRequestStatus)
        .pipe(filter(status => status === RequestStatus.FAILED || status === RequestStatus.SUCCESS))
    ),
    map(status => {
      if (status === RequestStatus.FAILED) {
        store.dispatch(MembershipActions.logout());

        return false;
      }

      return true;
    })
  );
};

export default loadMembership;
