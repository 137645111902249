import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { NZ_WAVE_GLOBAL_CONFIG } from 'ng-zorro-antd/core/wave';

@Component({
  standalone: true,
  selector: 'ava-button',
  template: `
    <button
      class="ava-button"
      type="button"
      nz-button
      [ngClass]="type"
      [nzBlock]="block"
      [disabled]="disabled"
      [nzType]="type"
      [nzShape]="shape"
      [nzSize]="size"
      [nzLoading]="isLoading"
      (click)="!disabled && clickedBtn($event)"
    >
      <ng-container *ngIf="leftIcon">
        <i [style.font-size]="iconSize + 'px'" nz-icon [nzTheme]="iconTheme" [hidden]="false" [nzType]="leftIcon"></i>
      </ng-container>
      <span class="ava-button-label">
        <ng-container *ngIf="label">{{ label }}</ng-container>
        <ng-content></ng-content>
      </span>
    </button>
  `,
  providers: [
    {
      provide: NZ_WAVE_GLOBAL_CONFIG,
      useValue: {
        disabled: true,
      },
    },
  ],
  imports: [NzNoAnimationModule, NzButtonModule, NzIconModule, CommonModule],
  styles: [
    `
      :host {
        .ava-button {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ant-btn > .anticon {
          line-height: 0;
        }

        .ant-btn-sm {
          padding-left: 0;
        }

        .ava-button-label {
          text-align: left;
          font-weight: 200;
          font-family: var(--ava-font-family);
        }

        // type=text
        .ava-button.ant-btn-text {
          border-color: transparent;
          background: transparent;
        }

        // type=primary
        .ava-button.ant-btn-primary {
          border-color: var(--btn-primary-color);
          background-color: var(--btn-primary-color);

          .ava-button-label {
            font-weight: 400;
          }
        }
        .ava-button.ant-btn-primary:hover {
          border-color: var(--btn-primary-color-hover);
          background-color: var(--btn-primary-color-hover);
        }

        // type=primary-outline
        .ava-button.primary-outline {
          border-color: var(--btn-primary-color);

          .ava-button-label {
            font-weight: 400;
          }

          &[disabled='true'] {
            color: #646464;
          }
        }
        .ava-button.primary-outline:hover {
          border-color: var(--btn-primary-color);
          background-color: var(--btn-primary-color);
          color: white;

          &[disabled='true'] {
            border-color: var(--btn-primary-color);
            background-color: transparent;
            color: #646464;
          }
        }

        //ant-btn-link
        // type=link
        .ava-button.ant-btn-link {
          color: var(--btn-primary-color);
          font-weight: 400;

          &:hover {
            border-color: transparent;
          }

          &:focus {
            border-color: transparent;
          }
        }

        // disabled=ture
        .ava-button[disabled='true'] {
          color: var(--btn-primary-disabled-color);
          opacity: 0.6;
        }
      }
    `,
  ],
})
export class ButtonComponent {
  @Input() label = '';
  @Input() type: 'primary' | 'primary-outline' | 'default' | 'dashed' | 'link' | 'text' = 'primary';
  @Input() shape: 'circle' | 'round' | '' = '';
  @Input() size: 'large' | 'small' | 'default' = 'default';
  @Input() isLoading: boolean | null = false;
  @Input() disabled: boolean | null = false;
  @Input() leftIcon = '';
  @Input() iconSize = 14;
  @Input() iconTheme: 'fill' | 'outline' | 'twotone' = 'outline';
  @Input() block: boolean | null = false;
  @Output() clicked = new EventEmitter();

  clickedBtn(event: Event) {
    this.clicked.emit();
  }
}
