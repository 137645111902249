import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Inject,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { RxState } from '@rx-angular/state';
import { GLOBAL_RX_STATE, GlobalState } from '../../services/state';

@Component({
  selector: 'ava-join-renew',
  template: `
    <ng-container #MembershipSystem></ng-container>
  `,
})
export class JoinRenewComponent {
  @ViewChild('MembershipSystem', { read: ViewContainerRef }) membershipSystem:
    | { createComponent: (arg0: ComponentFactory<unknown>) => any }
    | undefined;

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    if (globalState.get('windowMetaData', 'clubId') === '023') {
      this.hoosierModule();
    }
  }

  hoosierModule(): void {
    import('./hoosier/hoosier.module').then(m => m.HoosierModule);
    import('./hoosier/hoosier.component').then(component => {
      const componentRef = this.membershipSystem?.createComponent(
        this.componentFactoryResolver.resolveComponentFactory(Object.values(component)?.[0] as Type<unknown>)
      );
    });
  }
}
