import { Inject, Injectable } from '@angular/core';
import { FormArrayOptions } from '../../services/form';
import { QuillEditor, QuillEditorOptions, QuillEditorService } from '../../fields/quill-editor/service';
import { Button, ButtonOptions, ButtonService } from '../../fields/button/service';
import { Image, ImageEditorOptions, ImageService } from '../../fields/image/service';
import { Javascript, JavascriptOptions, JavascriptService } from '../../fields/javascript/service';
import { Video, VideoOptions, VideoService } from '../../fields/video/service';
import { FieldType } from '../../services/field';
import { PublishedOptions, Status, StatusService } from '../../options/status/service';
import { ColumnWidth, ColumnWidthOptions, ColumnWidthService } from '../../options/column-width/service';
import { Template, TemplateService } from '../../options/template/service';
import { BoxModel, BoxModelOptions, BoxModelService } from '../../options/box-model/service';
import { BackgroundImages, BackgroundImagesService } from '../../options/background-images/service';
import {
  PriceChartCard,
  PriceChartCardService,
} from '../../modules/join-renew/hoosier/multiblocks/price-chart-card/service';
import {
  PriceChartMemberQuantity,
  PriceChartMemberQuantityService,
} from '../../modules/join-renew/hoosier/multiblocks/price-chart-member-quantity/service';
import { GLOBAL_RX_STATE, GlobalState } from '../../services/state';
import { RxState } from '@rx-angular/state';
import { AlignmentType } from '../../options/alignment/service';
import { ScreenSize } from '../../services/style';
import { ComparisonChart } from '../../fields/comparison-chart/service';
import { BackgroundColor } from '../../options/background-color/service';
import { ThemeType } from '../../options/theme/service';
import { ColorType } from '../../services/color';

export interface Block {
  breadcrumbTitle?: string;
  containerType?: 'page';
  pageType?: string;
  pageTitle?: string;

  options: BlockOptions;
  template: Template;

  blockShared: boolean;
  blockTemplate: boolean;
  blockType: BlockOption;
  columns: Column[];
  rows: {
    [key: string]: Field;
  };
  id: string;
  instances?: {
    id: string;
    pathName: string;
  };
  pathName: string; // empty when blockTemplate is true, empty when bockShared is true
  pathNames: string[]; // empty when blockTemplate is true, one or more entries when blockShared is true
  status: Status;
}

export type BlockOption =
  | 'banner'
  | 'bannerLarge'
  | 'comparisonChart'
  | 'container'
  | 'feature'
  | 'html'
  | 'logiform'
  | 'multiblock'
  // | "template"
  | 'office'
  | 'officeAgents'
  | 'page'
  | 'password'
  | 'unrestricted'
  | 'join';

export interface BlockLabelOptions {
  label: {
    plural: string;
    singular: string;
  };
}

export interface BlockOptions {
  alignment: AlignmentType;
  alignmentEnabled: boolean;
  backgroundColor: BackgroundColor;
  backgroundImages?: BackgroundImages;
  boxModel: BoxModel;
  breakpointsEnabled: boolean;
  columnDivider: {
    enabled: boolean;
  };
  columnGap: {
    px: number;
  };
  theme: ThemeType;
  themeEnabled: boolean;
  visibility: Visibility;
  visible: {
    [key: string]: boolean;
  };
}

export interface BlockBase {
  blockShared: boolean;
  blockTemplate: boolean;
  blockType: BlockOption;
  columns: Column[];
  rows: {
    [key: string]: Field;
  };
  id: string;
  instances?: {
    id: string;
    pathName: string;
  };
  pathName: string; // empty when blockTemplate is true, empty when bockShared is true
  pathNames: string[]; // empty when blockTemplate is true, one or more entries when blockShared is true
  status: Status;
}

export interface OptionsBase {
  formArray: FormArrayOptions;
  label: {
    placeholder: string;
    plural: string;
    singular: string;
  };
}

export interface Column {
  accordion: boolean;
  label: string;
  rows: string[];
  width: ColumnWidth;
}

export type Field =
  | BlockReference
  | QuillEditor
  | Button
  | Image
  | Javascript
  | Video
  | PriceChartCard
  | PriceChartMemberQuantity
  | ComparisonChart;

export enum ScreenState {
  COUPON_CODE_VALID = 'COUPON_CODE_VALID',
  COUPON_CODE_INVALID = 'COUPON_CODE_INVALID',
  PROMO_CODE_VALID = 'PROMO_CODE_VALID',
  PROMO_CODE_INVALID = 'PROMO_CODE_INVALID',
  JOIN_SUCCESS = 'JOIN_SUCCESS',
}

export interface FieldBase {
  fieldType?: FieldType;
  verticalSpacing?: Record<string, never>;
  visibility?: Visibility;
}

export type VisibilityKey = ScreenSize;
export type Visibility = {
  [key in VisibilityKey]: boolean;
};

export interface BlockReference extends FieldBase {
  blockId: string;
}

export interface Options {
  block: BlockLabelOptions;
  multiblock: BlockLabelOptions;
  quillEditor: QuillEditorOptions;
  button: ButtonOptions;
  image: ImageEditorOptions;
  javascript: JavascriptOptions;
  video: VideoOptions;
  boxModel: BoxModelOptions;
  columnWidth: ColumnWidthOptions;
  published: PublishedOptions;
}

@Injectable({
  providedIn: 'root',
})
export class BlockService {
  blockType: BlockOption = 'multiblock';
  highlight: { [key: string]: boolean } = {};

  videosOptions = this.videoService.options;
  boxModelOptions = this.boxModelService.options;
  publishedOptions = this.statusService.options;
  columnWidthOptions = this.columnWidthService.options;
  buttonsOptions = this.buttonService.options;
  imagesOptions = this.imageService.options;

  // carousel: boolean = false

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private boxModelService: BoxModelService,
    private buttonService: ButtonService,
    private joinPriceMemberQuantityOptionsService: PriceChartMemberQuantityService,
    private priceChartCardOptionsService: PriceChartCardService,
    private columnWidthService: ColumnWidthService,
    private backgroundImagesService: BackgroundImagesService,
    private imageService: ImageService,
    private javascriptService: JavascriptService,
    private statusService: StatusService,
    private quillService: QuillEditorService,
    private videoService: VideoService,
    private templateService: TemplateService
    // private dataReadService: DataReadService,
  ) {}

  get options(): Options {
    return {
      block: this.blockOptions,
      multiblock: this.multiblockLabelOptions,
      quillEditor: this.quillEditorOptions,
      button: this.buttonsOptions,
      image: this.imagesOptions,
      javascript: this.javascriptsOptions,
      video: this.videosOptions,
      boxModel: this.boxModelOptions,
      columnWidth: this.columnWidthOptions,
      published: this.publishedOptions,
    };
  }

  get blockOptions(): BlockLabelOptions {
    return {
      label: {
        plural: 'Multiblock',
        singular: 'Multiblock',
      },
    };
  }

  get multiblockLabelOptions(): BlockLabelOptions {
    return {
      label: {
        plural: 'Rows',
        singular: 'Row',
      },
    };
  }

  get quillEditorOptions(): QuillEditorOptions {
    const options = this.quillService.options;
    if (options?.newItem) {
      options.newItem.value =
        '<h2><b style="font-size: 60px;">Title</b></h2><h3><span style="font-size: 32px;">Subtitle</span></h3>';
    }
    return options;
  }

  get javascriptsOptions(): JavascriptOptions {
    const options = this.javascriptService.options;
    options.newItem.background = {
      color: ColorType.C_20,
      opacity: 80,
    };
    return options;
  }

  get newColumn(): Column {
    return {
      accordion: false,
      label: 'Column',
      rows: [],
      width: this.columnWidthService.newItem,
    };
  }

  get newBlock(): Block {
    return {
      blockShared: false,
      blockType: this.blockType,
      blockTemplate: false,
      columns: [this.newColumn],
      id: '',
      rows: {},
      options: {
        alignment: AlignmentType.LEFT,
        alignmentEnabled: true,
        backgroundColor: {
          hex: '',
          name: '',
          rgba: 'rgba(0, 0, 0, 0)',
        },
        breakpointsEnabled: true,
        boxModel: this.boxModelService.newItem,
        columnDivider: { enabled: false },
        columnGap: { px: 0 },
        theme: ThemeType.OPT_01,
        themeEnabled: true,
        visibility: {
          [ScreenSize.DESKTOP]: true,
          [ScreenSize.TABLET]: true,
          [ScreenSize.MOBILE]: true,
          // [ScreenState.JOIN_SUCCESS]: false,
          // [ScreenState.COUPON_CODE_VALID]: false,
          // [ScreenState.COUPON_CODE_INVALID]: false,
          // [ScreenState.PROMO_CODE_VALID]: false,
          // [ScreenState.PROMO_CODE_INVALID]: false,
        },
        visible: {},
      },
      pathName: this.globalState.get('location', 'pathname'),
      pathNames: [],
      status: this.statusService.newItem,
      template: this.templateService.newItem,
    };
  }

  setHighlight(blockSessionId: string, columnsIndex: string, rowsIndex: string): void {
    const highlightIndex = blockSessionId + '-' + columnsIndex + '-' + rowsIndex;
    this.highlight[highlightIndex] = true;
  }

  /*
    unsetHighlight(blockSessionId: string, columnsIndex: string, rowsIndex: string): void {
      const highlightIndex = blockSessionId + "-" + columnsIndex + "-" + rowsIndex
      this.highlight[highlightIndex] = false
    }
  */

  resetHighlight(): void {
    this.highlight = {};
  }

  /*
    constructor(
      private bannerService: BannerService,
      private bannerLargeService: BannerLargeService,
      private multiblockService: BlockStateService,
      private comparisonChartService: ComparisonChartService,
      private featureService: FeatureService,
      private joinService: JoinService,
      private logiformService: LogiformService,
      private officeAgentsService: OfficeAgentsService,
      private officeService: OfficeService,
      private pageService: PageService,
      private passwordService: PasswordService,
    ) {
    }
  */

  /*
    get blockOptions(): BlockOption[] {
      // return Object.keys(this.blockTypes) as BlockOption[]
      // return ["banner", "title", "password", "html", "logiform", "officeAgents", "oneColText"]
      const blockOptions = []
      // blockOptions.push("banner")
      // blockOptions.push("bannerLarge")
      // blockOptions.push("callToAction")
      blockOptions.push("multiblock")
      // blockOptions.push("comparisonChart")
      // blockOptions.push("feature")
      // blockOptions.push("join")
      // blockOptions.push("logiform")
      // blockOptions.push("office")
      // blockOptions.push("officeAgents")
      // blockOptions.push("oneColText")
      // blockOptions.push("password")
      // blockOptions.push("title")

      return blockOptions
    }

    get fieldPaths(): { path: string, type: BlockType }[] {
      return [
        { path: "multiblock/blocks/banner", type: BlockType.BANNER },
        { path: "multiblock/blocks/banner-large", type: BlockType.BANNER_LARGE },
        { path: "multiblock/containers/multiblock", type: BlockType.MULTI_BLOCK },
        { path: "multiblock/blocks/comparison-chart", type: BlockType.COMPARISON_CHART },
        { path: "multiblock/container-blocks/block", type: BlockType.CONTAINER },
        { path: "multiblock/blocks/feature", type: BlockType.FEATURE },
        { path: "multiblock/blocks/html", type: BlockType.HTML },
        { path: "multiblock/blocks/join", type: BlockType.JOIN },
        { path: "multiblock/blocks/logiform", type: BlockType.LOGIFORM },
        { path: "multiblock/blocks/office", type: BlockType.OFFICE },
        { path: "multiblock/blocks/office-agents", type: BlockType.OFFICE_AGENTS },
        { path: "multiblock/container-blocks/page", type: BlockType.PAGE },
        { path: "multiblock/blocks/password", type: BlockType.PASSWORD },
        { path: "multiblock/blocks/unrestricted", type: BlockType.UNRESTRICTED },
      ]
    }

    get blockTypes(): BlockDeprecatedTypes {
      return {
        banner: {
          component: {
            path: "multiblock/blocks/banner"
          },
          label: {
            plural: this.bannerService.blockOptions.label.singular,
            singular: this.bannerService.blockOptions.label.singular
          }
        },
        bannerLarge: {
          component: {
            path: "multiblock/blocks/banner-large",
          },
          label: {
            plural: this.bannerLargeService.blockOptions.label.singular,
            singular: this.bannerLargeService.blockOptions.label.singular
          }
        },
        multiblock: {
          component: {
            path: "multiblock/containers/multiblock",
          },
          label: {
            plural: this.multiblockService.blockOptions.label.singular,
            singular: this.multiblockService.blockOptions.label.singular
          }
        },
        // template: {
        //   component: {
        //     path: "multiblock/containers/multiblock",
        //   },
        //   label: {
        //     plural: this.multiblockService.blockOptions.label.singular,
        //     singular: this.multiblockService.blockOptions.label.singular
        //   }
        // },
        comparisonChart: {
          component: {
            path: "multiblock/blocks/comparison-chart",
          },
          label: {
            plural: this.comparisonChartService.blockOptions.label.singular,
            singular: this.comparisonChartService.blockOptions.label.singular
          }
        },
        container: {
          component: {
            path: "multiblock/container-blocks/block",
          },
          label: {
            plural: "",
            singular: ""
          }
        },
        feature: {
          component: {
            path: "multiblock/blocks/feature",
          },
          label: {
            plural: this.featureService.blockOptions.label.singular,
            singular: this.featureService.blockOptions.label.singular
          }
        },
        html: {
          component: {
            path: "multiblock/blocks/html",
          },
          label: {
            plural: "",
            singular: ""
          }
        },
        join: {
          component: {
            path: "multiblock/blocks/join",
          },
          label: {
            plural: this.joinService.blockOptions.label.singular,
            singular: this.joinService.blockOptions.label.singular
          }
        },
        logiform: {
          component: {
            path: "multiblock/blocks/logiform",
          },
          label: {
            plural: this.logiformService.blockOptions.label.singular,
            singular: this.logiformService.blockOptions.label.singular
          }
        },
        office: {
          component: {
            path: "multiblock/blocks/office",
          },
          label: {
            plural: this.officeService.blockOptions.label.singular,
            singular: this.officeService.blockOptions.label.singular
          }
        },
        officeAgents: {
          component: {
            path: "multiblock/blocks/office-agents",
          },
          label: {
            plural: this.officeAgentsService.blockOptions.label.singular,
            singular: this.officeAgentsService.blockOptions.label.singular
          }
        },
        page: {
          component: {
            path: "multiblock/container-blocks/page",
          },
          label: {
            plural: "",
            singular: ""
          }
        },
        password: {
          component: {
            path: "multiblock/blocks/password",
          },
          label: {
            plural: this.passwordService.blockOptions.label.singular,
            singular: this.passwordService.blockOptions.label.singular
          }
        },
        unrestricted: {
          component: {
            path: "multiblock/blocks/unrestricted",
          },
          label: {
            plural: "",
            singular: ""
          }
        },
      }
    }

    get containerTypes(): PageTypes {
      return this.pageService.types
    }

    get pageBlockDeprecatedTypes(): PageTypes {
      return this.pageService.blockTypes
    }

    newBlock(blockOption: BlockOption, pageOption?: PageOption): Multiblock {
      if (blockOption === "container" && pageOption === "page") {
        return this.pageService.newBlock
      }
      if (blockOption === "banner") {
        return this.bannerService.newBlock
      }
      if (blockOption === "bannerLarge") {
        return this.bannerLargeService.newBlock
      }
      // if (blockOption === "multiblock") {
      //   return this.multiblockService.newBlock
      // }
      // if (blockOption === "template") {
      //   return this.multiblockService.newBlock
      // }
      if (blockOption === "comparisonChart") {
        return this.comparisonChartService.newBlock
      }
      if (blockOption === "feature") {
        return this.featureService.newBlock
      }
      if (blockOption === "join") {
        return this.joinService.newBlock
      }
      if (blockOption === "logiform") {
        return this.logiformService.newBlock
      }
      if (blockOption === "officeAgents") {
        return this.officeAgentsService.newBlock
      }
      if (blockOption === "office") {
        return this.officeService.newBlock
      }
      if (blockOption === "password") {
        return this.passwordService.newBlock
      }
    }
  */
}
