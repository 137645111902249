import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MembershipNumberForm, QuickRenewFormVm } from '../../modules/quick-renew/services/quick-renew-form.vm';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { QuickRenewActions } from './quick-renew.actions';
import { Observable, of, withLatestFrom } from 'rxjs';
import { Operation, OperationExecutePayload } from '@aaa/interface-joinRenew-joinRenewLib';
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteOperationExecuteEventPayload,
  MembershipConnectSuiteRecostValidateRenewEventPayload,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import {
  getQuickRenewAccountDetails,
  getQuickRenewExecutionData,
  QuickRenewAccountDetails,
} from './quick-renew.selectors';
import { FormGroupValue } from '../../modules/share/form.utils';
import { getPayment } from '@aaa/emember/store-payment';
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from '@aaa/interface-joinRenew-payment-paymentCybersource';
import { PaymentForm } from '@aaa/emember/share/payment-form';
import { AccountDetails, ValidateSucceededResponseObject } from '../types/types';
import { ConnectSuite } from '../connect-suite.type';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { RequestError, RequestErrorType } from '../generic-errors';
import { checkOperationErrorsConnectSuiteSystem } from '../check-operation-errors-connect-suite-system';
import { checkCybersourcePaymentValidation } from '../check-cybersource-payment-validation';
import { getClearCacheSettings } from '../utils/get-cache-settings';
import { Cybersource } from '../cybersource.type';
import { DataLayerService } from '../../modules/share/services/data-layer.service';
import { AnalyticsPurchaseEvent } from '../../../types/analytics-purchase-event';
import { PaymentCardHolderForm } from '../../../types/payment-card-holder';
import { AppAnalyticsEvents } from '../../../types/analytics-events';
import { getTransactionId } from '../utils/get-transaction-id';
import { filterByClubIds } from '../utils/filter-by-club-ids';

@Injectable({ providedIn: 'root' })
export class QuickRenewConnectSuitSystemEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Hoosier, ClubApp.SouthJersey]));
  formVm = inject(QuickRenewFormVm);
  executeService = inject(ExecuteService);
  dataLayer = inject(DataLayerService);

  setSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickRenewActions.recostValidateSucceeded),
      map(({ response }: ValidateSucceededResponseObject<ConnectSuite.MemberQuickRenewResponseObject>) => {
        const accountDetails = new ConnectSuite.AccountInfo(response.response.validationData);

        return QuickRenewActions.setAccountDetails({ accountDetails });
      })
    )
  );

  updatePaymentCardHolderInformation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuickRenewActions.recostValidateSucceeded),
        tap(({ response }: ValidateSucceededResponseObject<ConnectSuite.MemberQuickRenewResponseObject>) => {
          const membership = response.response.validationData.membership;
          this.formVm.paymentCardHolderFormGroup.patchValue({
            firstName: membership.primaryMember.attributes.firstName,
            lastName: membership.primaryMember.attributes.lastName,
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  recostValidateMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickRenewActions.recostValidate),
      switchMap(() =>
        this.recostValidate(this.formVm.membershipFormGroup.value).pipe(
          map(res => QuickRenewActions.recostValidateSucceeded(res)),
          catchError(error => of(QuickRenewActions.recostValidateFailed({ error })))
        )
      )
    )
  );

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickRenewActions.pay),
      withLatestFrom(
        this.store.select(getQuickRenewAccountDetails),
        this.store.select(getQuickRenewExecutionData),
        this.store.select(QuickRenewAccountDetails.getBalance),
        this.store.select(getPayment)
      ),
      switchMap(([, accountDetails, executionData, totalCost, payment]) =>
        this.pay(accountDetails, executionData, totalCost, payment, this.formVm.paymentCardHolderFormGroup.value).pipe(
          map(() => QuickRenewActions.paySucceeded()),
          catchError(error => of(QuickRenewActions.payFailed({ error })))
        )
      )
    )
  );

  pay(
    accountDetails: AccountDetails | null,
    executionData: string,
    totalCost: number,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
    paymentCardHolderForm: FormGroupValue<PaymentCardHolderForm>
  ) {
    const paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
        executionData: {
          flexMicroFormToken: payment.token,
          billTo: {
            address1: String(accountDetails?.address.street1),
            address2: String(accountDetails?.address.street2),
            administrativeArea: String(accountDetails?.address.state),
            buildingNumber: '',
            country: 'US',
            district: String(accountDetails?.address.state),
            email: String(accountDetails?.email || 'fallback@avagate.com'),
            firstName: paymentCardHolderForm.firstName || '',
            lastName: paymentCardHolderForm.lastName || '',
            locality: String(accountDetails?.address.city),
            phoneNumber: String(accountDetails?.phone.home || accountDetails?.phone.cell),
            postalCode: String(accountDetails?.address.zip),
          },
          amountDetails: {
            totalAmount: String(totalCost),
            currency: 'USD',
          },
          creditCardBrandedName: payment.formValues.card?.cardName || '',
        },
        method: PaymentCybersourceMethod.OPERATION_EXECUTE,
        operation: Operation.RENEW,
      },
      membershipEvent: MembershipConnectSuiteOperationExecuteEventPayload = {
        cacheSettings: getClearCacheSettings(),
        executionData: executionData,
        method: MembershipConnectSuiteMethod.OPERATION_EXECUTE,
        operation: Operation.RENEW,
      },
      payload: OperationExecutePayload = {
        operation: Operation.RENEW,
        membershipEvent,
        paymentEvent,
      };

    return this.executeService
      .execute<ConnectSuite.ExecuteMembershipResponseObject, Cybersource.ExecutePaymentResponseObject>(payload)
      .pipe(
        map(({ validateObject, paymentObject, operationObject }) => {
          const paymentError = !!paymentObject?.meta.isError;
          if (paymentError) {
            checkCybersourcePaymentValidation(paymentObject.error);
          }

          // Todo need to implement if exist any error in the validateObject

          const operationError = !!operationObject?.meta?.isError;
          if (operationError) {
            checkOperationErrorsConnectSuiteSystem(operationObject.error, operationObject);
          }

          const analyticsEventParams: AnalyticsPurchaseEvent['eventParams'] = {
            currency: 'USD',
            transaction_id: getTransactionId(paymentObject),
            value: totalCost,
            membershipLevel: accountDetails?.code?.level,
            items: [
              {
                quantity: 1,
                price: totalCost,
                item_id: 'primary',
                item_name: AppAnalyticsEvents.QuickRenewRenewal,
              },
            ],
            context: 'ava-store ' + AppAnalyticsEvents.QuickRenewRenewal,
          };
          this.dataLayer.purchaseEvent(analyticsEventParams);

          return {
            membership: validateObject,
            payment: paymentObject,
          };
        })
      );
  }

  recostValidate(
    formValues: FormGroupValue<MembershipNumberForm>
  ): Observable<ValidateSucceededResponseObject<ConnectSuite.MemberQuickRenewResponseObject>> {
    const payload: MembershipConnectSuiteRecostValidateRenewEventPayload = {
      method: MembershipConnectSuiteMethod.RECOST_VALIDATE_RENEW,
      autoRenew: null,
      memberNumber: formValues?.membershipNumber || '',
      verificationData: {
        lastName: formValues?.lastName || '',
        postalCode: formValues?.zipcode || '',
      },
    };

    return this.executeService.membershipQuery<ConnectSuite.MemberQuickRenewResponseObject>(payload).pipe(
      map(validateObject => {
        const isError =
          validateObject.meta.isError ||
          validateObject.response.validationData?.membership?.attributes?.membershipStatus !== 'P';
        const executionData = validateObject?.response?.executionData || '';

        if (isError) {
          const membershipStatus = validateObject.response?.validationData?.membership?.attributes?.membershipStatus;

          // 1. check if membership is cancelled
          if (!validateObject.meta.isError && membershipStatus === 'C') {
            throw new RequestError(RequestErrorType.MembershipCancelled, validateObject);
          } else if (!validateObject.meta.isError && membershipStatus !== 'C') {
            // 2. check if membership is not expired yet
            throw new RequestError(RequestErrorType.MembershipNotExpiredYet, validateObject);
          } else {
            const errorCode = validateObject.error?.errorCode?.toString() || validateObject?.error?.responseCode;

            switch (errorCode) {
              case '1':
              case '020': // INVALID_ID("020") // "The membership number is invalid."
                throw new RequestError(RequestErrorType.MembershipInvalidNumber, validateObject);
              case '022': // MEMBERSHIP_NOT_FOUND("022")
                throw new RequestError(RequestErrorType.MembershipNotFound, validateObject);
              case '023': // INVALID_MEMBERSHIP("023")
                throw new RequestError(RequestErrorType.MembershipInvalidNumber, validateObject);
              case '2':
                throw new RequestError(RequestErrorType.MembershipInvalidLastName, validateObject);
              case '3':
                throw new RequestError(RequestErrorType.MembershipInvalidZipCode, validateObject);
            }
          }

          throw new RequestError(RequestErrorType.Error);
        }

        return {
          executionData,
          response: validateObject,
        };
      })
    );
  }
}
