import { Injectable } from '@angular/core';
import { Machine, interpret, State, AnyEventObject } from 'xstate';
import { AzureConnectorService } from './azure-connector.service';
import { BehaviorSubject } from 'rxjs';
import {
  AppMode,
  Agent,
  BookingDetails,
  AppointmentDeleteResponse,
  AppointmentAddResponse,
} from '@aaa/interface-agentScheduler';
import { FormArray } from '@angular/forms';
import { FormService } from './form.service';

/*
interface AgentMachineInitialContext {
  formId: string
  appMode: AppMode
  startAtAgentEmail: string
  cfm: boolean
  userId: string
  azureTenantId: string
  clubId: string
  topics?: Topic[]
  selectedTopics?: Topic[]
  regions?: Region[]
  region?: Region
  meetings?: Meeting[]
  meeting?: Meeting
  agents?: Agent[]
  agent?: Agent
  timeSlot?: AgentTimeSlot
  appointmentId?: string
  promo?: string
}
*/

enum LogType {
  'IO',
  'STATE',
  'PROC',
  'NONE',
}

@Injectable({
  providedIn: 'root',
})
export class StateMachineService {
  mapsLoaded: boolean = false;

  //meta
  private logTypes: LogType[] = [
    LogType.NONE,
    //LogType.IO,
    //LogType.STATE,
    //LogType.PROC
  ];

  //public
  agentFlowSubject = new BehaviorSubject<boolean>(false);
  stateSubject = new BehaviorSubject<State<never> | null>(null);
  pageChangeSubject = new BehaviorSubject<{ event: string; page: string } | null>(null);
  context: { formId: string } | undefined;
  state: State<{ formId: string }, AnyEventObject, any, { value: any; context: { formId: string } }> | undefined;
  // workingAgentsList: Agent[]
  // agentFlow: boolean = false
  // cfm: boolean = false
  //private

  // workingAgentsList: Agent[]
  // agentFlow: boolean = false
  // cfm: boolean = false

  //private
  private agentMachineService: any;
  // private agentsRefreshed: boolean = false
  // private randomizedAgents: Agent[]

  constructor(
    public acs: AzureConnectorService,
    private formService: FormService
  ) {}

  // public async setupService(initial: AgentMachineInitialContext): Promise<void> {
  public setupService(formId: string, formValue: any): void {
    const machine = this.createScheduleMachine(formId, formValue);
    // const formValues: FormValues = this.formService.formValues[formId]
    /*
        const loadedState: StateConfig<never, never> = await this.fcs.loadState()
        let resolvedState

        if (loadedState && !initial.cfm) {
          this.log(LogType.PROC, "found saved state: " + JSON.stringify(loadedState, null, 2))
          const stale: boolean = Object.keys(initial).some(key => {
            return JSON.stringify(loadedState.context[key]) !== JSON.stringify(initial[key])
          })

          if (stale) {
            this.log(LogType.PROC, "loaded state is stale, ignoring")
          } else {
            this.log(LogType.PROC, "not stale, resuming from loaded state")
            const previousState: any = State.create(loadedState)
            resolvedState = machine.resolveState(previousState)
            this.pushFormValues(resolvedState.context)
          }
        } else {
          this.log(LogType.PROC, "did not find saved state for this user")
        }
    */

    // this.randomizedAgents = this.shuffle(this.formService.formValues[formId].agents)
    // this.updateWorkingAgents()

    this.agentMachineService = interpret(machine)
      .onTransition(async state => {
        this.log(LogType.STATE, 'StateMachine Transition');
        this.log(LogType.STATE, 'state: ' + JSON.stringify(state, null, 2));
        // const agentFlow: boolean = !!formValues.overrides.agentEmail
        // if (agentFlow !== this.agentFlow) {
        //   this.agentFlowSubject.next(agentFlow)
        // }
        // this.agentFlow = agentFlow
        this.state = state as any;
        this.stateSubject.next(state as any);
        this.context = state.context;

        // await this.fcs.saveState(state)
      })
      .start(undefined);

    this.log(LogType.PROC, 'StateMachineService is set up');
  }

  public sendEvent(
    event:
      | string
      | 'RESTART'
      | 'NEXT'
      | 'PREVIOUS'
      | 'AGENTSLIST_PREVIEW'
      | 'AGENTSLIST_PREVIEW_CLOSE'
      | 'LOAD_AGENT_LOADED'
      | 'LOAD_AGENT_ERROR'
      | 'APPOINTMENT_ADD_COMPLETE'
      | 'JUMP_TYPE'
      | 'JUMP_AGENT'
      | 'JUMP_TIME',
    data?: Agent | BookingDetails
  ): void {
    if (!this.agentMachineService) {
      console.error('Cannot sendEvent when machine is not set up. call setupMachine() first');
      return;
    }
    const eventData = { type: event, data: data };
    this.log(LogType.PROC, 'sending eventData: ' + JSON.stringify(eventData));
    this.agentMachineService.send(eventData);
  }

  private createScheduleMachine = (
    formId: string,
    formValue: {
      overrides: { cfm: boolean; agentEmail: any; appointmentId: string | null };
      selectedTopicIds: string | any[];
      appMode: AppMode;
      meta: { clubId: string; tenantId: string };
      metaUser: { userId: string };
    }
  ) =>
    // const formValue = this.formService.formValues[formId]
    Machine(
      {
        id: 'scheduler',
        initial: 'start',
        context: {
          formId: formId,
          // cfm: !!formValues.overrides.cfm
          /*
                appMode: initial.appMode, //drupal
                startAtAgentEmail: initial.startAtAgentEmail, //drupal
                cfm: initial.cfm, //url argument
                azureTenantId: initial.azureTenantId, //drupal
                clubId: initial.clubId, //drupal
                userId: initial.userId, //drupal
                topics: initial.topics, //drupal
                selectedTopics: initial.selectedTopics ? initial.selectedTopics : [], //user
                regions: initial.regions, //drupal
                region: initial.region, //user
                meetings: initial.meetings, //drupal
                meeting: initial.meeting, //user
                agents: initial.agents, //drupal
                agent: initial.agent, //user
                timeSlot: initial.timeSlot, // user
                appointmentId: initial.appointmentId, //gcp
                promo: initial.promo //url argument
        */
        },
        on: {
          RESTART: {
            target: '.start',
            actions: ['appointment_delete', 'reset_selections'],
          },
          APPOINTMENT_ADD_COMPLETE: {
            target: undefined, // for state transition side effect -> save state to gcf
          },
          JUMP_TYPE: {
            target: '.topic',
            actions: 'jump_topic',
          },
          JUMP_AGENT: {
            target: '.agentsList',
            actions: 'jump_agentsList',
          },
          JUMP_TIME: {
            target: '.agentPage',
          },
        },
        states: {
          start: {
            on: {
              '': [
                {
                  cond: () => formValue.overrides.cfm,
                  actions: ['scrollTo'],
                  target: 'confirmation',
                },
                {
                  cond: () => !!formValue.overrides.agentEmail,
                  target: 'agentPage',
                },
                {
                  target: 'topic',
                },
              ],
            },
          },
          topic: {
            entry: ['pageChange', 'pageChange_Topics'],
            initial: 'notReady',
            states: {
              notReady: {},
              ready: {},
            },
            on: {
              NEXT: [
                {
                  cond: () => !!formValue.selectedTopicIds.length && formValue.appMode === AppMode.TRAVEL,
                  target: 'region',
                },
                {
                  cond: () => !!formValue.selectedTopicIds.length && formValue.appMode === AppMode.INSURANCE,
                  target: 'insuranceOptions',
                },
              ],
              PREVIOUS: [
                {
                  cond: 'agentFlow',
                  target: 'agentPage',
                },
              ],
            },
          },
          region: {
            entry: ['pageChange', 'pageChange_Region'],
            on: {
              NEXT: [
                {
                  target: 'meeting',
                },
              ],
              PREVIOUS: [
                {
                  target: 'topic',
                },
              ],
            },
          },
          insuranceOptions: {
            entry: ['pageChange', 'pageChange_InsuranceOptions'],
            on: {
              NEXT: [
                {
                  target: 'meeting',
                },
              ],
              PREVIOUS: [
                {
                  target: 'topic',
                },
              ],
            },
          },
          meeting: {
            entry: ['pageChange', 'pageChange_Meeting'],
            on: {
              NEXT: [
                {
                  cond: () => !!formValue.overrides.agentEmail,
                  target: 'userInfo',
                },
                {
                  target: 'agentsList',
                },
              ],
              PREVIOUS: [
                {
                  cond: () => formValue.appMode === AppMode.TRAVEL,
                  target: 'region',
                },
                {
                  cond: () => formValue.appMode === AppMode.INSURANCE,
                  target: 'insuranceOptions',
                },
              ],
            },
          },
          agentsList: {
            initial: 'list',
            entry: ['pageChange', 'pageChange_AgentsList'],
            states: {
              list: {
                on: {
                  AGENTSLIST_PREVIEW: {
                    target: 'preview',
                  },
                },
              },
              preview: {
                on: {
                  AGENTSLIST_PREVIEW_CLOSE: {
                    target: 'list',
                  },
                },
              },
            },
            on: {
              NEXT: [
                {
                  target: 'agentPage',
                },
              ],
              PREVIOUS: [
                {
                  target: 'meeting',
                },
              ],
            },
          },
          agentPage: {
            entry: ['push_formValues', 'pageChange', 'agent_refresh', 'pageChange_Agent'],
            on: {
              NEXT: [
                {
                  cond: () => !!formValue.overrides.agentEmail,
                  target: 'topic',
                },
                {
                  target: 'userInfo',
                },
              ],
              PREVIOUS: {
                target: 'agentsList',
              },
            },
          },
          userInfo: {
            entry: ['pageChange', 'pageChange_UserInfo'],
            on: {
              NEXT: {
                target: 'confirmation',
                actions: ['userInfo_appointmentAdd'],
              },
              PREVIOUS: [
                {
                  cond: () => !!formValue.overrides.agentEmail,
                  target: 'meeting',
                },
                {
                  target: 'agentPage',
                },
              ],
            },
          },
          confirmation: {
            entry: ['pageChange', 'agent_refresh', 'pageChange_Confirmation'],
          },
        },
      },
      {
        actions: {
          log: (context, event) => {
            this.log(LogType.PROC, 'sms processed event: ' + JSON.stringify(event));
          },
          /*
                jump_topic: (context, event) => {
                  if (!this.agentFlow) this.updateWorkingAgents()
                },
        */
          /*
                jump_agentsList: (context, event) => {
                  if (this.agentFlow) this.updateWorkingAgents()
                },
        */
          scrollTo: async (context, event) => {
            await new Promise(r => setTimeout(r, 0));
            const element = window.document.getElementById('agentScheduler');
            element?.scrollIntoView({ behavior: 'smooth' });
          },
          pageChange: (context, event) => {
            this.pageChangeSubject.next({
              event: event['type'],
              page: '',
            });
          },
          pageChange_Topics: (context, event) => {
            if (!formValue.overrides.cfm) {
              this.pageChangeSubject.next({
                event: event['type'],
                page: 'topics',
              });
            }
          },
          pageChange_Region: (context, event) => {
            if (!formValue.overrides.cfm) {
              this.pageChangeSubject.next({
                event: event['type'],
                page: 'region',
              });
            }
          },
          pageChange_Meeting: (context, event) => {
            if (!formValue.overrides.cfm) {
              this.pageChangeSubject.next({
                event: event['type'],
                page: 'meeting',
              });
            }
          },
          pageChange_AgentsList: (context, event) => {
            if (!formValue.overrides.cfm) {
              this.pageChangeSubject.next({
                event: event['type'],
                page: 'agents_list',
              });
            }
          },
          pageChange_Agent: (context, event) => {
            if (!formValue.overrides.cfm) {
              this.pageChangeSubject.next({
                event: event['type'],
                page: 'agent',
              });
            }
          },
          pageChange_UserInfo: (context, event) => {
            if (!formValue.overrides.cfm) {
              this.pageChangeSubject.next({
                event: event['type'],
                page: 'user_info',
              });
            }
          },
          pageChange_Confirmation: (context, event) => {
            if (!formValue.overrides.cfm) {
              this.pageChangeSubject.next({
                event: event['type'],
                page: 'confirmation',
              });
            }
          },
          /*
                push_formValues: (context, event) => {
                  this.pushFormValues(context)
                  this.cfm = context.cfm
                  this.log(LogType.PROC, "push_formValues: " + JSON.stringify(this.formValues))
                },
        */
          /*
                reset_cfm: assign({
                  cfm: (context, event) => {
                    //clean up url
                    console.log("reset_cfm")
                    try {
                      let url = document.location.href
                      const urlparts = url.split("?")
                      if (urlparts.length >= 2) {
                        const urlBase = urlparts.shift()
                        const queryString = urlparts.join("?")
                        const prefix = encodeURIComponent("cfm") + "="
                        const pars = queryString.split(/[&;]/g)
                        for (let i = pars.length; i-- > 0;) {
                          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                            pars.splice(i, 1)
                          }
                        }
                        url = urlBase + "?" + pars.join("&")
                        window.history.pushState("", document.title, url)
                      }

                      console.log("pushState")
                    } catch (e) {
                      console.log("pushState error")
                      console.log(e)
                    }
                    return false
                  },
                }),
        */
          /*
                reset_selections: assign({
                  selectedTopics: (context, event) => [],
                  region: (context, event) => null,
                  meeting: (context, event) => null,
                  agent: (context, event) => null,
                  timeSlot: (context, event) => null
                }),
        */
          /*
                setup_agentFlow: assign({
                  agent: (context, event) => {
                    const agent = context.agents.find(a => a.email.toLocaleLowerCase() === context.startAtAgentEmail.toLocaleLowerCase())
                    this.updateWorkingAgents(context.startAtAgentEmail)
                    return agent
                  }
                }),
        */
          /*
                topic_select: assign({
                  selectedTopics: (context, event) => [...context.selectedTopics, event["data"]],
                }),
        */
          /*
                topic_deselectEmpty: assign({
                  selectedTopics: (context, event) => [],
                }),
        */
          /*
                topic_deselectNotEmpty: assign({
                  selectedTopics: (context, event) => context.selectedTopics.filter(t => t.id !== event["data"].id),
                }),
        */
          /*
                topic_agentsRefresh: async (context, event) => {
                  if (this.agentsRefreshed) return
                  this.agentsRefreshed = true
                  // console.log('refreshing agents');
                  await this.acs.availabilityRefresh(
                    context.clubId,
                    context.userId,
                    context.azureTenantId,
                    context.agents.map(a => a.email)
                  )
                },
        */
          /*
                topic_next: (context, event) => {
                  this.updateWorkingAgents(context.agent?.email, context.selectedTopics)
                },
        */
          /*
                region_select: assign({
                  region: (context, event) => {
                    this.updateWorkingAgents(context.agent?.email, context.selectedTopics, [event["data"]])
                    return event["data"]
                  }
                }),
        */
          /*
                region_deselect: (context, event) => {
                  this.updateWorkingAgents(context.agent?.email)
                },
        */
          /*
                meeting_select: assign({
                  meeting: (context, event) => {
                    this.updateWorkingAgents(context.agent?.email, context.selectedTopics, [context.region], [event["data"]])
                    return event["data"]
                  }
                }),
        */
          /*
                meeting_deselect: (context, event) => {
                  this.updateWorkingAgents(context.agent?.email, context.selectedTopics)
                },
        */
          /*
                agentList_select: assign({
                  agent: (context, event) => event["data"],
                }),
        */
          /*
                agentList_deselect: (context, event) => {
                  this.updateWorkingAgents(context.agent?.email, context.selectedTopics, [context.region])
                },
        */
          agent_refresh: async (context, event) => {
            console.log('refreshing single agent');
            // const email: string = this.formService.form[formId].get(["agent", "email"])?.value
            const email: string = this.formService.form[formId].controls.agent.value.email;
            const agentEmails = [];
            if (email) {
              agentEmails.push(email);
            }
            await this.acs.availabilityRefresh(
              formValue.meta.clubId,
              formValue.metaUser.userId,
              formValue.meta.tenantId,
              agentEmails
            );
          },
          /*
                agentPage_timeSlotSelect: assign({
                  timeSlot: (context, event) => {
                    this.updateWorkingAgents(
                      context.agent?.email,
                      context.selectedTopics,
                      [context.region],
                      [context.meeting]
                    )
                    return event["data"]
                  }
                }),
        */
          userInfo_appointmentAdd: async (context, event) => {
            formValue.overrides.appointmentId = null;
            const details: BookingDetails = event['data'];
            const addResponse: AppointmentAddResponse | undefined = await this.acs.appointmentAdd(
              this.formService.form[formId].controls.agent.value.email,
              formValue.meta.clubId,
              formValue.meta.tenantId,
              formValue.metaUser.userId,
              details
            );
            formValue.overrides.appointmentId = addResponse?.appointmentId || '';
            this.sendEvent('APPOINTMENT_ADD_COMPLETE');
          },
          appointment_delete: async (context, event) => {
            const deleteResponse: AppointmentDeleteResponse | undefined = await this.acs.appointmentDelete(
              formValue.meta.clubId,
              formValue.meta.tenantId,
              formValue.metaUser.userId,
              formValue.overrides.appointmentId || ''
            );
            console.log('appt delete server response: ' + deleteResponse?.status || '');
          },
        },
        guards: {
          agentFlow: (context, event) => {
            return !!formValue.overrides.agentEmail;
          },
          cfm: (context, event) => {
            return !!formValue.overrides.cfm;
          },
        },
      }
    );

  /*
    topicToggle(topic: Topic): void {
      if (this.context.selectedTopics.find((t: Topic) => t.id === topic.id)) {
        this.sendEvent("TOPIC_DESELECT_TOPIC", topic)
      } else {
        this.sendEvent("TOPIC_SELECT_TOPIC", topic)
      }
    }
  */

  /*
    topicIsSelected(topicId: string): Topic {
      // console.log('topicIsSelected()');
      return this.context.selectedTopics.find((t: Topic) => t.id === topicId)
    }
  */

  /*
    compareJSON(a: JSON, b: JSON): boolean {
      return JSON.stringify(a) === JSON.stringify(b)
    }
  */

  /*
    pushFormValues(context: any): void {
      // console.log(context)
      if (context.region) {
        this.formValues.region = context.region
        //console.log('using existing context.region: '+ JSON.stringify(context.region))
      } else if (!this.formValues.region) {
        this.formValues.region = context.regions.find(r => r.default) || context.regions[0]
      }
      //console.log(JSON.stringify(context.regions));

      if (context.meeting) {
        this.formValues.meeting = context.meeting
      } else if (!this.formValues.meeting) {
        let def = context.meetings.find(r => r.default)
        if (!def) {
          def = context.meetings[0]
        }
        this.formValues.meeting = def
      }

      if (context.selectedTopics) {
        this.formValues.selectedTopics = context.selectedTopics
      } else if (!this.formValues.selectedTopics) {
        this.formValues.selectedTopics = []
      }
      // console.log(JSON.stringify(this.formValues));
    }
  */

  /*
    updateWorkingAgents(agentFlowEmail?: string, topics?: Topic[], regions?: Region[], meetings?: Meeting[]): void {
      if (!!agentFlowEmail && this.agentFlow) {
        this.workingAgentsList = [this.randomizedAgents.find(a => a.email.toLocaleLowerCase() === agentFlowEmail.toLocaleLowerCase())]
      } else {
        topics = topics?.filter(e => !!e)
        regions = regions?.filter(e => !!e)
        meetings = meetings?.filter(e => !!e)
        this.workingAgentsList = this.randomizedAgents
          .filter(a => !topics ? true : (a.topics.some(at => topics.some(t => t.id === at.id))))
          .filter(a => !regions ? true : (a.regions.some(ar => regions.some(r => r.id === ar.id))))
          .filter(a => !meetings ? true : (a.meetings.some(am => meetings.some(m => m.id === am.id))))
      }
    }
  */

  /*
    restart(): void {
      if (this.context.appointmentId) {
        this.sendEvent("RESTART")
      }
    }
  */

  shuffle(array: Agent[]): Agent[] {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  get topics(): FormArray | undefined {
    if (this.context) {
      return this.formService.form[this.context.formId].get('topics') as FormArray;
    }
    return;
  }

  private log(type: LogType, message: string): void {
    if (this.logTypes.find(t => t === type)) {
      console.log(message);
    }
  }
}
