import { createReducer, on } from '@ngrx/store';
import firebase from 'firebase/compat/app';
import { SessionActions } from './session.actions';
import IdTokenResult = firebase.auth.IdTokenResult;
import { SessionDoc } from './session.models';
import { RequestStatus } from '../../../types/request-status';

export interface SessionState {
  initialize: RequestStatus;
  sessionId: string | null;
  idTokenResult: IdTokenResult | null;
  activeSessionDocs: boolean;
  sessionDocs: SessionDoc | null;
}

export const initialStateSession: SessionState = {
  initialize: RequestStatus.UNSENT,
  sessionId: null,
  idTokenResult: null,
  sessionDocs: null,
  activeSessionDocs: false,
};

export const sessionReducer = createReducer(
  initialStateSession,
  on(SessionActions.initialize, state => ({ ...state, initialize: RequestStatus.RUNNING })),
  on(SessionActions.initializeSucceeded, (state, { sessionId, idTokenResult }) => ({
    ...state,
    sessionId,
    idTokenResult,
    initialize: RequestStatus.SUCCESS,
  })),
  on(SessionActions.setId, (state, { sessionId }) => ({ ...state, sessionId, activeSessionDocs: !!sessionId })),
  on(SessionActions.setIdToken, (state, { idTokenResult }) => ({ ...state, idTokenResult })),
  on(SessionActions.setDocs, (state, { sessionDocs }) => ({ ...state, sessionDocs: sessionDocs ? sessionDocs : null }))
);
